import { CustomSnackbarReduxActionReturnValue, CustomSnackbarState } from ".";

export const snackbarConstants = {
    SET_SNACKBAR : "SET_SNACKBAR",
}
export const snackbarReducer = 
    (
        state : CustomSnackbarState, 
        action : CustomSnackbarReduxActionReturnValue<Partial<CustomSnackbarState>>
    ) : Partial<CustomSnackbarState>=> {
    const {type, payload} = action;
    switch(type){
        case snackbarConstants.SET_SNACKBAR : 
            return {...state, ...payload};
        default : return {...state};
    }
}