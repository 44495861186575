import { walletTypes } from "..";
import { store } from "..";
import { CustomSnackbarDispatch, successToast, warningToast } from "../../includes";
import { WalletAddresses } from "../../../../../utils/types";
import walletConnection from "./walletConnection";
import walletUtil from "./walletUtil";
function binanceListeners() {
    //@ts-ignore
    const BinanceChain = window.BinanceChain;
    if(!BinanceChain) return null;
    const dispatch = store.dispatch as CustomSnackbarDispatch,
    connectedCallback = () => successToast("Connected!!!")(dispatch);
    BinanceChain.on("connect", connectedCallback)
    BinanceChain.on('chainChanged', (chainId : any)=>{
        const {walletReducer} = store.getState(),
        { walletType } = walletReducer;
        if(walletType !== walletTypes.binance) {
            return warningToast(
                `You are not connect to binance wallet, 
                disconnect and connect using binance!`
            )(dispatch)
        }
        //window.location.reload()
    });
    BinanceChain.on('accountsChanged', async (accounts : any[])=>{
        const {walletReducer} = store.getState(),
        { address, walletType} = walletReducer,
        contractAddresses = walletReducer.contractAddresses as WalletAddresses;
        if (accounts.length === 0) {
            // MetaMask is locked or the user has not connected any accounts
            warningToast('Account disconnection, Please connect to Binance Wallet.')(dispatch);
            walletUtil.disconnectUser();
        } else if(walletType !== walletTypes.binance) {
            return warningToast(
                `You are not connect to binance wallet, 
                disconnect and connect using binance!`
            )(dispatch)
        } else if (accounts[0] !== address) {
            walletConnection(contractAddresses, walletType, null, null, true);
        }
    });
    BinanceChain.on('disconnect', (error : any) => {
        if(error) console.error(error);
        walletUtil.disconnectUser();
    });
}
function accountListeners(){
    //@ts-ignore
    const ethereum = window.ethereum;
    if(!ethereum) return null;
    const dispatch = store.dispatch,
    connectedCallback = () => successToast("Connected!!!")(dispatch);
    ethereum.on("connect", connectedCallback)
    ethereum.on('chainChanged', (chainId : string)=>{
        const {walletReducer} = store.getState(),
        { walletType } = walletReducer;
        if(walletType !== walletTypes.metamask) {
            return warningToast(
                `You are not connect to metamask wallet, 
                disconnect and connect using metamask!`
            )(dispatch)
        }
        //window.location.reload()
    });
    ethereum.on('accountsChanged', async (accounts : any[])=>{
        const {walletReducer} = store.getState(),
        { address, walletType} = walletReducer,
        contractAddresses = walletReducer.contractAddresses as WalletAddresses;
        if (accounts.length === 0) {
            // MetaMask is locked or the user has not connected any accounts
            warningToast('Account disconnection, Please connect to Binance Wallet.')(dispatch);
            walletUtil.disconnectUser();
        } else if(walletType !== walletTypes.metamask) {
            return warningToast(
                `You are not connect to metamask wallet, 
                disconnect and connect using metamask!`
            )(dispatch)
        } else if (accounts[0] !== address) {
            walletConnection(contractAddresses, walletType, null, null, true);
        }
    });
    ethereum.on('disconnect', (error : any) => {
        if(error) console.error(error);
        walletUtil.disconnectUser();
    });
}
export default accountListeners;
export {accountListeners, binanceListeners};