import { makeStyles } from "@material-ui/core";
import LaunchPadJumbotron from "./LaunchPadJumbotron/LaunchPadJumbotron"
import LaunchPadNetworks from "./LaunchPadNetworks/LaunchPadNetworks"
import LaunchPadLaunchYourProject from "./LaunchPadLaunchYourProject/LaunchPadLaunchYourProject"
import LaunchPadDefiJourney from "./LaunchPadDefiJourney/LaunchPadDefiJourney"
import LaunchPadFeaturedProject from "./LaunchPadFeaturedProjects/LaunchPadFeaturedProjects";
import LaunchPadInvestors from "./LaunchPadInvestors/LaunchPadInvestors";
import LaunchPadBackers from "./LaunchPadBackers/LaunchPadBackers";
import LaunchPadProjectsListing from './LaunchPadProjectsListing/LaunchPadProjectsListing'
import { LaunchPadStyleProps, appUtils } from '../../utils';
import { useEffect, useState } from "react";
// import "./index.css"
import LaunchPadProduct from "./LaunchpadProductTokens/LaunchpadProducts"



const styles = makeStyles((theme: LaunchPadStyleProps) => ({
    root: {
        backgroundColor: theme.colors.pageBackground,
        minHeight: "100vh",
        color: theme.colors.textDefault,
        width: "100%",
    },
    link: {
        color: theme.colors.textHighlight,

    },
}))
type LaunchPadState = {
    ongoingProjects : any,
    finishedProjects : any,
    featuredProject : any,
}
export default function LaunchPad() {
    const classes = styles(),
        [state, _setState] = useState<LaunchPadState>({
            finishedProjects : [], ongoingProjects : [],
            featuredProject : null
        }),
        {featuredProject, finishedProjects, ongoingProjects} = state,
        setState = (_state : LaunchPadState) => _setState(state=>({...state, ..._state}));
    useEffect(() => {
        (async () => {
            document.getElementsByTagName("html")[0].setAttribute("style", "background-color:#000C15");
            const [finishedProjects, ongoingProjects] =
                await Promise.all<[Promise<any>, Promise<any>]>([
                    appUtils.projectData("finished"),
                    appUtils.projectData("ongoing")

                ]);
            let featuredProject = null;
            if(ongoingProjects && ongoingProjects[0]){
                featuredProject = ongoingProjects[0]
            }
            setState({finishedProjects, ongoingProjects, featuredProject})
        })();
    }, [])

    return (
        <div className={classes.root}>
            <LaunchPadJumbotron />
            {featuredProject &&
                <LaunchPadFeaturedProject
                    projectIndex={0}
                    action={featuredProject.action}
                    metadata={featuredProject.metadata}
                    research={featuredProject.research}
                    projectID={featuredProject.projectID}
                    image={featuredProject.projectPicture}
                    title={featuredProject.projectName}
                    description={featuredProject.description}
                    allocation={featuredProject.personalAllocation}
                    total={featuredProject.totalRaised}
                    sheetName={featuredProject.sheetName}
                    chain={featuredProject.chain}
                    IDODate={featuredProject.IDODate}
                    pool={featuredProject.pool.map((item: any) => item.name) || []}
                    GMT={featuredProject.GMT}
                />
            }
            <LaunchPadProjectsListing
                ongoingProjects={ongoingProjects}
                finishedProjects={finishedProjects}
            />
            <LaunchPadNetworks />
            <LaunchPadInvestors />
            <LaunchPadBackers />
            {/* <LaunchPadProduct /> */}
            <LaunchPadLaunchYourProject />
            <LaunchPadDefiJourney />
        </div>
    )
}
