import { makeStyles } from '@material-ui/styles';
import { AppStyleProps, LaunchPadStyleProps } from '../../../utils';
import LaunchPadNetworkItem from "./LaunchPadNetworkItem/LaunchPadNetworkItem";
import { Grid } from '@material-ui/core';
import LaunchPadNetworkCardItem from "./LaunchPadNetworkCardItem/LaunchPadNetworkCardItem";

const style = makeStyles((theme: LaunchPadStyleProps) => ({
    root: {
        position: "relative",
        textAlign: "center",
        padding: "5rem",
        marginBottom: "0rem",
        [theme.breakpoints.down('sm')]: {
            textAlign: "center",
            padding: "1rem",
            height: "100%",
        },
    },
    heading: {
        fontfamily: "Clash Display",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "48px",
        color: theme.colors.textDefault,
        margin: 0,
        //[theme.breakpoints.down('xl')]: {
        //    fontSize: "48px"
        //},
        [theme.breakpoints.down('sm')]: {
            fontSize: "36px",
            marginTop: "-30px"
        }
    },
    sectionText: {
        fontFamily: 'DM Sans, sans-serif',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "18px",
        color: theme.colors.textDefault,
        maxWidth: "65%",
        alignSelf: "center",
        opacity: .8,
        margin: "20px auto 1.2rem",
        lineHeight: "28px",
        //[theme.breakpoints.down('xl')]: {
        //    fontSize: "18px"
        //},
        [theme.breakpoints.down('sm')]: {
            fontSize: "16px",
            maxWidth: "100%",
        },
    },
    networksContainer: {
        display: "flex",
        justifyContent: "center",
        [theme.breakpoints.down('sm')]: {
            justifyContent: "space-between",
        },
    },

    dataContainer: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "6rem",
        [theme.breakpoints.down(1690)]: {
            flexWrap: "wrap",
            marginTop: "3rem"
        },
        [theme.breakpoints.down("sm")]: {
            justifyContent: "center",
        },
    }
}))


export default function LaunchPadNetworks() {

    const classes = style();

    const networks = [
        { networkName: "Ethereum", logoName: "ETHEREUM_LOGO" },
        { networkName: "Binance SmartChain", logoName: "BINANCE_LOGO" }, // logo name will be changed for this later when we get the icon for BSC
        { networkName: "Polygon", logoName: "POLYGON_LOGO" },
        { networkName: "Oasis", logoName: "OASIS" },
    ];

    const data = [
        { dataContent: "Easily exchange between your assets without giving control of your funds to anyone.", dataHead: "Extra layer of security", dataPicture: "DataIcon1" },
        { dataContent: "We offer you the best transaction experience with the lowest fees available.", dataHead: "Low transaction fees", dataPicture: "DataIcon2" },
        { dataContent: "Your transactions are processed at lightning-fast speed.", dataHead: "Speed of light", dataPicture: "DataIcon3" },
    ];

    return (
        <section className={classes.root}>
            <div className={classes.networksContainer}>
                {networks.map((network, index) => <LaunchPadNetworkItem key={index} networkName={network.networkName} logoName={network.logoName} />)}
            </div>
            <h2 className={classes.heading}>
                Built across multiple platforms
            </h2>
            <p className={classes.sectionText}>
                Built on Ethereum, Binance SmartChain, Polygon & Oasis  to give you the freedom to experience more inclusivity and an extra layer of security when using our platforms.
            </p>
            <div className={classes.dataContainer}>
                <Grid container spacing={4}>
                    {data && data.map((item, index) => {
                        const { dataHead, dataContent, dataPicture } = item;
                        return <Grid key={index} item xs={12} sm={6} md={4} lg={4} xl={4} >
                            <LaunchPadNetworkCardItem key={index} dataHead={dataHead} dataContent={dataContent} dataPicture={dataPicture} />
                        </Grid>
                    })}
                </Grid>
            </div>
        </section>
    )
}