import * as React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { AppStyleProps } from '../../../utils';
import { CustomDialog } from '../../../includes';

type AppProps = {
    openDialog: boolean,
    setOpenDialog: Function,
}

const style = makeStyles((theme: AppStyleProps) => ({
    
    container: {
        height: "40rem",
        maxHeight: "80%",
        padding: "1rem",
    },

    dialogHeading: {
        marginBottom: "2rem",
        fontSize: "32px",
        color: "white"
    },

    text: {
        color: "rgba(144, 208, 255, 1)"
    },

    date: {
        color: "white",
        fontSize: "18px"
    }

}))

export default function ProjectDataTermsAndCondition({ openDialog, setOpenDialog }: AppProps) {
    
    const classes = style();

    return (
        <CustomDialog
            open={openDialog}
            close={() => setOpenDialog(false)}
        >
            <div className={classes.container}>
                <div>
                    <h2 className={classes.dialogHeading}>Terms &amp; Conditions</h2>
                    <Typography className={classes.date}>Last Updated: March 10th, 2022</Typography>

                </div>

                <div className={classes.text}>
                    <p>
                        PLEASE READ THIS AGREEMENT CAREFULLY BEFORE MAKING ANY PAYMENT FOR TOKENS/JOINING WHITELIST, AS IT AFFECTS YOUR OBLIGATIONS AND LEGAL RIGHTS, INCLUDING, BUT NOT LIMITED TO, WAIVERS OF RIGHTS AND LIMITATION OF LIABILITY. IF YOU DO NOT AGREE WITH THIS DOCUMENT, YOU SHALL NOT MAKE ANY PAYMENT FOR TOKENS
                    </p>
                    <p>
                        BY PURCHASING THE TOKENS/JOINING WHITELIST, YOU ACKNOWLEDGE THAT YOU HAVE READ THIS DOCUMENT AND THAT YOU AGREE TO BE BOUND BY IT. TOKENS ARE NOT BEING OFFERED OR DISTRIBUTED TO, AS WELL AS CANNOT BE RESOLD OR OTHERWISE ALIENATED BY THEIR HOLDERS TO, CITIZENS OF, NATURAL AND LEGAL PERSONS, HAVING THEIR HABITUAL RESIDENCE, LOCATION OR THEIR SEAT OF INCORPORATION IN THE COUNTRY OR TERRITORY WHERE TRANSACTIONS WITH DIGITAL TOKENS ARE PROHIBITED OR IN ANY MANNER RESTRICTED BY APPLICABLE LAWS OR REGULATIONS.
                    </p>
                    <p>
                        THE COMPANY (RIGELPROTOCOL LABS LTD) RESERVES THE RIGHT TO CHANGE, MODIFY, ADD OR REMOVE PROVISIONS OF THIS AGREEMENT AT ANY TIME FOR ANY REASON. WE SUGGEST THAT YOU REVIEW THIS AGREEMENT PERIODICALLY FOR CHANGES. SUCH CHANGES SHALL BE EFFECTIVE IMMEDIATELY UPON POSTING THEM ON THE WEBSITE (AS DEFINED BELOW).
                    </p>
                    <p>
                        YOU ACKNOWLEDGE THAT BY ACCESSING OUR WEBSITE AFTER WE HAVE POSTED CHANGES TO THIS AGREEMENT, YOU ARE AGREEING TO THE MODIFIED PROVISIONS. CRYPTOCURRENCY TOKENS INVESTMENT ARE HIGHLY RISKY. TOKENS MAY HAVE NO VALUE OR LESSER VALUE THAN BUYING PRICE. BUYER MAY LOSE ALL AMOUNTS PAID. THE COMPANY EXPRESSLY DISCLAIMS ANY AND ALL RESPONSIBILITY FOR ANY DIRECT OR CONSEQUENTIAL LOSS OR DAMAGE OF ANY KIND WHATSOEVER ARISING DIRECTLY OR INDIRECTLY FROM:
                    </p>
                    <ul>
                        <li>RELIANCE ON ANY INFORMATION CONTAINED IN THIS DOCUMENT,</li>
                        <li>ANY ERROR, OMISSION OR INACCURACY IN ANY SUCH INFORMATION OR</li>
                        <li> ANY ACTION RESULTING THEREFORM.</li>
                    </ul>
                    <p>RIGELPROTOCOL LABS LTD, a company incorporated and registered in British Virgin Islands, Powers a number of Dapps inclusive the RigelProtocol Launchpad Dapp.  THe RIGELPROTOCOL LAUNCHPAD a Decentalized Application HEREBY INVITES everyone who </p>
                    <ul>
                        <li>Is interested to purchase the Tokens  and </li>
                        <li> Is not a Restricted Person (as it defined below)</li>

                    </ul>
                    <p>('Buyer') to make an offer addressed to the THe RIGELPROTOCOL LAUNCHPAD PROJECTS to enter into this Agreement (as it defined below) upon the terms and subject to the conditions set out below. If you meet the criteria of being a Buyer, you are entitled to make an offer on an acquisition of any BEP20/ERC-20 compatible tokens launched on the RIGELPROTOCOL LAUNCHPAD DAPP. If you do not agree to the terms and conditions set out herein, do not make any payment into the Sale Platform to purchase the Tokens</p>
                </div>

            </div>
        </CustomDialog>
    )
}
