import { makeStyles } from '@material-ui/styles';
import { ButtonBase } from '@material-ui/core';
import { AppStyleProps, launchPadRouteNames, LaunchPadStyleProps } from '../../../utils';
import { useGaEvents } from '../../../../../helpers';


const styles = makeStyles(({colors, breakpoints} : LaunchPadStyleProps)=>({
    
    root: {
        background: `url("/background_land0.png") no-repeat center center / cover`,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        color: colors.textDefault,
        textAlign: "center",
        position: "relative",
        transformStyle: "preserve-3d",
        minHeight: "801px",
        paddingTop: "130px",
        boxSizing: "border-box",
        "&:after": {
            content: "''",
            minHeight: "801px",
            // background: "url('./assets/LandingHeaderEffect.png')",
            backgroundRepeat: "no-repeat",
            position: "absolute",
            left: 0,
            right: 0,
            backgroundSize: "100% 40rem",
            top: "10rem",
            
            transform: "translateZ(-2px)",
            [breakpoints.down('lg')]: {
                height: "801px",
            },
            [breakpoints.down('md')]: {
            },
            [breakpoints.down('sm')]: {
                backgroundSize: "cover",
                top: "-8rem",
                minHeight: "501px",
            }
        },
        [breakpoints.down('md')]: {
            paddingRight: "2.5rem",
            paddingLeft: "2.5rem",
        },
        [breakpoints.down('sm')]: {
            paddingRight: "1rem",
            paddingBottom: "150px",
            paddingLeft: "1rem",
            minHeight: "501px",
        }
    },
    jumboHeading: {
        fontSize: "56px",
        fontFamily: "Clash Display",
        fontStyle: "normal",
        fontWeight: 600,
        color: colors.headerDefault,
        marginBottom: "1rem",
        [breakpoints.down('lg')]: {
            fontSize: "56px",
        },
        [breakpoints.down('sm')]: {
            fontSize: "32px",
        }
    },
    jumboText: {
        fontSize: "18px",
        fontFamily: 'DM Sans, sans-serif',
        fontStyle: "normal",
        fontWeight: "normal",
        color: colors.textGrey,
        lineHeight: "28px",
        opacity: .8,
        marginBottom: "40px",
        maxWidth: "50%",
        [breakpoints.down('xl')]: {
            // fontSize: "20px",
        },
        [breakpoints.down('sm')]: {
            fontSize: "16px",
            maxWidth: "90%",
        }
    },
    jumboBtn: {
        backgroundImage: " linear-gradient(90deg, #2691C1 0%, #045CA6 42.71%, #055DA6 60.42%, #238CBE 100%)",
        "box-shadow": "0px 2px 4px -2px rgba(178, 193, 230, 0.12), 0px 4px 4px -2px rgba(178, 193, 230, 0.08)",
        color: colors.textDefault,
        fontFamily: 'DM Sans, sans-serif',
        fontSize: "16px",
        width: "196px",
        height: "56px",
        fonWeight: "700",
        padding: "1.5rem 4rem",
        borderRadius: "6px",
        outline: "none",
        [breakpoints.down('xl')]: {
            fontSize: "18px",
            padding: ".7rem 1.5rem",
            // borderRadius: ".5rem",
        }
    }
    
}))


export default function LaunchPadJumbotron() {

    const classes = styles();
    const GaTrackEvents = useGaEvents();

    return(
        <header className = {classes.root}>
                <h1 className = {classes.jumboHeading}>
                    RigelProtocol Launchpad
                </h1>
                <p className = {classes.jumboText}>
                    RigelProtocol Launchpad is an end to end tokenization platform combining a technology solution with key compliance and legal aspects.
                </p>
                <ButtonBase 
                    onClick={() => {window.location.href=launchPadRouteNames.buyRGP; GaTrackEvents("View Launchpad Landing Page","launchpad_button_clicked","Launchpad Button")}} 
                    className = {classes.jumboBtn}>
                        Launch DApp
                </ButtonBase>
                {/* <LaunchPadJumbotronStats /> */}
        </header>
    );
}