import {ButtonBase, makeStyles} from '@material-ui/core';
import { 
    AppStyleProps, Nullible, BuyRGPTypes, ReduxStoreState, launchPadContract 
} from '../../../../utils'
import { CustomInputField } from '../../../../../../../includes'
import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useGaEvents } from '../../../../../../../helpers';

const style=makeStyles(({breakpoints, colors, isDark} : AppStyleProps) => ({
    root : {
        width: "100%",
        boxSizing: "border-box",
    },
    title: {
        position : "absolute",
        top : 0,
        left : 20,
        zIndex : 1100,
        color : colors.text,
        fontSize: "1rem",
        lineHeight: "1.5rem",
    },
    body: {
        width: "30rem",
        marginTop: "3rem",
        color: colors.text,
        [breakpoints.down(580)]: {
            maxWidth: "100%",
        }
    },
    btn: {
        backgroundColor: colors.primary,
        border: `2px solid ${colors.borderOutline}`,
        color: colors.textSecondary,
        fontSize: "1.125rem",
        lineHeight: "1.5rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "1.5rem",
        borderRadius: ".5rem",
        padding: "0.75rem",
        width: "100%",
        cursor : "not-allowed"
    },
    active : {
        background : isDark ? colors.highlight : colors.blue,
        color: isDark ? colors.text : colors.white,
        cursor : "pointer",
    },
    minStake : {
        display : "flex",
        justifyContent : "flex-left",
    },
    note: {
        backgroundColor: isDark ? colors.primary : colors.lightBlue,
        padding: "1rem",
        border: `1px solid ${colors.borderOutline}`,
        borderRadius: "6px",
        marginBottom: "1.5rem",
        [breakpoints.down("xs")]: {
            padding: ".9rem .7rem",
        }
    },
    noteText: {
        color: isDark ? colors.highlight : colors.blue,
        fontSize: ".875rem",
        lineHeight: "1.375rem",
    },
    noteLink: {
        color: isDark ? colors.highlight : colors.blue,
    }
}))
export type StakeInputModalProps=Nullible<Partial<{
    balance : number,
    minStakeAmount : number,
    symbol : string,
    stakeRGP : (amount : number, onApproval : ()=>void) => any,
}>>
type StakeInputModalState = Partial<Nullible<{
    amount : number,
    buttonDisplay : "Enter An Amount" | "Approve & Stake RGP" | "Approving..." | "Staking...",
}>>
export default function StakeInputModal({
    balance, symbol, stakeRGP, 
    minStakeAmount
} : StakeInputModalProps) {
    const classes=style(),
        [state, _setState] = useState<StakeInputModalState>({
            amount : 0,
            buttonDisplay : "Enter An Amount",
        }),
        GaTrackEvents = useGaEvents(),
        {amount, buttonDisplay} = state,
        setState = (_state : StakeInputModalState)  => _setState(state=>({...state, ..._state})),
        walletReducer = useSelector((state : ReduxStoreState) => state.walletReducer),
        { address, contracts } = walletReducer,
        { selectedPad } = contracts || {},
        stateMonitor = useCallback(value=>{
            const amount = Number(value);
            const inputState : StakeInputModalState = { amount };
            if(amount <= 0 && buttonDisplay !== "Enter An Amount") 
                inputState.buttonDisplay = "Enter An Amount"
            else if(buttonDisplay !== "Approve & Stake RGP")
                inputState.buttonDisplay = "Approve & Stake RGP";
            setState(inputState)
        }, [buttonDisplay]),
        activateBtn = amount && amount > 0,
       

        _stakeRGP = () => {
            setState({buttonDisplay : "Approving..."});
            GaTrackEvents("View Stake Page","stake_approval_button_clicked","Stake Approval Request");
            stakeRGP && stakeRGP(
                amount as number, 
                ()=>{setState({buttonDisplay : "Staking..."}); GaTrackEvents("View Stake Page","staking_request","Staking Request");}
            )
        }
    return(
        <div className={classes.root}>
            <h3 className={classes.title}>Stake RGP</h3>
            <div className={classes.body}>
                <div className = {classes.note}>
                    <p className={classes.noteText}>
                        Staking is deposited on the RGP single farming pool.
                        <a target="_blank" href = "https://smartswap.rigelprotocol.com/#/farming-v2/staking-RGPv2" 
                        className={classes.noteLink}>Track here</a>
                    </p>
                    <p className={classes.noteText}>
                        Don't have RGP? {" "}
                        <a target="_blank" href = "https://smartswap.rigelprotocol.com/#/swap" 
                            className={classes.noteLink}>
                                buy it here
                        </a>
                    </p>
                </div>
                <div className={classes.minStake}>Min. stake amount : {minStakeAmount} RGP</div>
                <CustomInputField<Partial<{
                    [K in keyof BuyRGPTypes] : string
                }>> 
                    tokens={{ [String(symbol).toLowerCase() as string] : String(symbol).toUpperCase() }}
                    balance={balance}
                    maxBtn
                    symbol={symbol}
                    stateMonitor={stateMonitor}
                />

                <ButtonBase onClick={_stakeRGP} className={classNames(classes.btn, activateBtn ? classes.active : "")}>
                    {buttonDisplay}
                </ButtonBase>
            </div>
        </div>
    );
}