import React, { MouseEvent, MouseEventHandler, useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  ReduxStoreState, WalletState,
  NETWORKS, AllowedNetworks, WalletAddresses,
  AppStyleProps, BuyRGPTypes, abis, launchPadContract,
  tokenContractActions, walletUtil, UtilState, walletConnection, ParseFloat
} from '../../../../utils';
import { CustomDialog, CustomSnackbarDispatch, errorToast, successToast, warningToast } from '../../../../includes';
import ListItemText from '@material-ui/core/ListItemText';
import {NetworkList} from './NetworkList';
import WalletSwitchModal from './WalletSwitchModal/WalletSwitchModal';

const MetamaskIcon = "/header/metamask.png";
const BinanceIcon = "/header/binance.png";
const PolygonIcon = '/assets/polygon.png'

// const useStyles = makeStyles((theme) => ({
const useStyles = makeStyles(({ colors, breakpoints, isDark }: AppStyleProps) => ({
  root: {
    display: 'flex',
    [breakpoints.down("sm")]: {
      display: "none",
    },
  },
  midiumlogo: {
    height: 24,
    width: 24,
  },
  cursor: {
    cursor: "pointer",
  },
  flex: {
    display: "flex"
  },
  smallSpace: {
    margin: 4,
  },

  text: {
    color: colors.text,
    fontFamily: `Cera Pro`,
    fontSize: `16px`,
    fontStyle: `normal`,
    lineHeight: `24px`,
    letterSpacing: `0em`,
    textAlign: `center`,
    whiteSpace: 'nowrap',
  },
  textHighlight: {
    color: colors.highlight,
  },
  box: {
    display: "flex",
    justifyContent: `center`,
    alignItems: `center`,
    padding: `4px 6px`,
    borderRadius: `6px`,
    maxHeight: "40px",
    background: isDark ? colors.primary : colors.lightBlue,
    border: `1px solid ${colors.borderOutline}`,
  },
transparent: {
    background: colors.pageBackground,
    border: "0px",
  },
walletBalance: {
    display: "flex",
    background: colors.primary,
    marginLeft: "8px",
    borderRadius: `6px`,
    cursor: "pointer",
    border: `1px solid ${colors.borderOutline}`,
  },
  innerText: {
    fontWeight: 400,
    fontSize: "1rem",
    color: colors.text,
    lineHeight: "1.5rem",
    marginLeft: "0.5rem"
  },
}));

export type content = {
  launchPadAdd: string,
  token1Symbol: string,
  token2Symbol: string
}
type DialogTypes = "networkModal"|"others"|null
type ConnectedState = Partial<{
  showDialog : boolean,
  showNetwork : boolean,
  showSelectAddress: boolean,
  dialogType : DialogTypes
}>

const consumeComp: Array<any> = [];

export function Connected({openWallet} : Partial<{openWallet : Function}>) {
  
  const classes = useStyles(),
  dispatch = useDispatch(),
  [state, _setState] = useState<ConnectedState>({
      showDialog : false,
      dialogType : null,
      showSelectAddress: false,
      showNetwork: false,
    }),
    {showDialog, dialogType, showSelectAddress, showNetwork} = state,
    setState = (_state : ConnectedState) => _setState((state : ConnectedState)=>({...state, ..._state})),
    openDialog = (dialogType : DialogTypes) => setState({showDialog : true, dialogType}),
    closeDialog = () => setState({showDialog : false, dialogType : null}),
  snackbarDispatch = useDispatch<CustomSnackbarDispatch>(),
  {
    address, networkId, networkType, walletType, web3, contracts,
    contractAddresses, rgpBalance, walletBalance
  } = useSelector((state : ReduxStoreState) : WalletState => state.walletReducer),
  {
    storageTokenContract
  } = useSelector((state : ReduxStoreState) : UtilState => state.utilReducer),
  onNetworkSelect = useCallback(async (networkId : number) => {
    if(!address) return;
    try{
      const _id = walletUtil.getTruffleNetworkName(networkId) as keyof AllowedNetworks;
      //@ts-ignore
      // const ethereum = window?.ethereum as any;
      const provider = web3._provider;
      if(!NETWORKS[_id]) throw new Error("Network metadata not configured");
      await walletUtil.switchNetwork(provider, NETWORKS[_id].chainId, NETWORKS[_id]);
      await walletConnection(contractAddresses, walletType, 
        () => warningToast("Reconnecting...")(dispatch), 
        () => successToast("Connected!")(dispatch), 
        true
      );
    }catch(error : any){
      const message = error.message as string;
      errorToast(message)(snackbarDispatch)
    }
  }, [address]);
  const getNetwork = useCallback((network: number) => {
    let connectedNetwork: { name: string, logo: string }
    switch (network) {
      case  137:
        return connectedNetwork = { name: "Polygon", logo: PolygonIcon }
        break;
      case 80001 :
          return connectedNetwork = { name: "Polygon Test", logo: PolygonIcon }
          break;
      case 56 :
        return connectedNetwork = { name: "Binance", logo: BinanceIcon }
        break;
      case  97:
          return connectedNetwork = { name: "Binance Test", logo: BinanceIcon }
          break;
      default:
        return connectedNetwork = { name: "Binance", logo: BinanceIcon }
        break;
    }
  }, [address])

  const _symbol: string = networkType === "binance" ? " BNB" : " ETH";
  const icon: string = walletType === "binance" ? BinanceIcon : MetamaskIcon;

    const [displayWallet, setDisplayWallet] = useState(false);

  return (
            <>
              {/* <Hidden smDown> */}
              <div className={classes.root}>
                <div className={classes.box} onClick={()=>openDialog("networkModal")} style={{marginRight: 10, marginLeft: 10, cursor: "pointer"}}>
                    <img src={getNetwork(networkId).logo} className={classes.midiumlogo} alt={"Binance"}/>
                    <div className={classes.innerText}> {getNetwork(networkId)?.name} </div>
                </div>
                <div className={classes.box}>
                    <div className={classNames(classes.text, classes.textHighlight)}> {ParseFloat(rgpBalance, 4) } RGP</div>
                </div>
                <div className={classes.walletBalance}>
                    <div style={{alignSelf: "center", padding: 4, whiteSpace: "nowrap"}}> {ParseFloat(walletBalance, 4) + " " + _symbol}</div>
                    <div className={classNames(classes.box, classes.transparent)} onClick={()=>setDisplayWallet((state) => !state)}>
                        {walletUtil.shortAddress(String(address).toUpperCase())}
                        <img src={icon} className={classNames(classes.midiumlogo, classes.smallSpace)} alt={"LaunchPad metamask"}/>
                    </div>
                  </div>
                </div>

                  <WalletSwitchModal
                    displayWallet={displayWallet}
                    setDisplayWallet={setDisplayWallet}
                    openWallet={openWallet}
                  />

                <CustomDialog
                    open={showDialog}
                    close={closeDialog}
                >
                  {dialogType === "networkModal" && <NetworkList
                    selected={Boolean(networkId) ? networkId.toString() : ""}
                    onSelect={onNetworkSelect}
                  />}
                </CustomDialog>

            </>
        );

}

export default Connected
