import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import {
  AppStyleProps, walletUtil,
} from '../../../../../utils';
import { CustomDialog} from '../../../../../includes';

const useStyles = makeStyles(({colors, breakpoints, isDark} : AppStyleProps) => ({
  midiumlogo: {
    height: 24,
    width: 24,
  },
  root: {
    display: "flex",
    flexDirection: "column",
    marginTop: "3rem",
    maxWidth : 400,
  },
  innerText: {
    fontWeight: 400,
    fontSize: "1rem",
    color: colors.text,
    lineHeight: "1.5rem",
    marginLeft: "0.5rem"
  },
  heading: {
    fontWeight: 400,
    fontSize: "1.25rem",
    color: colors.text,
    lineHeight: "1.75rem",
    marginBottom: "1rem",
  },
  selectBorder:{
    width: "100%",
    display: "flex",
    alignItems: "center",
    background: "transparent",
    cursor: "pointer",
    border: `1px solid ${colors.borderOutline}`,
    '&:hover': {
        border: `1px solid ${colors.textHighlight}`,
    },
    boxSizing: "border-box",
    borderRadius: ".375rem",
    height: "4rem",
    padding: "1rem",
    marginBottom: "1rem",
  },
  inforBox: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    background: colors.primary,
    border: `1px solid ${colors.borderOutline}`,
    boxSizing: "border-box",
    borderRadius: ".375rem",
    padding: "1rem",
    marginBottom: "1.5rem",
  },
  inforText: {
    fontWeight: 400,
    fontSize: ".75rem",
    color: colors.text,
    lineHeight: "1.25rem",
    marginBottom: "1rem",
  },
  link: {
    color: colors.textHighlight
  }
}));
export function NetworkList({
      onSelect,
      selected,
    }: {
      onSelect : Function,
      selected : string
    }) {

    const classes = useStyles();

    return (
          <div className={classes.root}>
              <div className={classes.heading}>Choose a Network to connect to</div>
              {walletUtil.networkList.map((item : any, index : number) => {
                return <div onClick={() => onSelect(item.networkId)} key={index} className={classes.selectBorder}>
                    <img src={item.image} className={classNames(classes.midiumlogo)} alt={item.name}/>
                    <div className={classNames(classes.innerText)}>{item.name}</div>
                </div>
              })}
              <div className={classes.inforBox}>
                  <div className={classes.inforText}>By connecting a wallet, you agree to RigelProtocol’s <a href="#" className={classes.link}>Terms of Service</a> and acknowledge that you have read and understand the RigelProtocol <a href="#" className={classes.link}>Privacy Policy</a></div>
              </div>
          </div>
        );

}

export default NetworkList
