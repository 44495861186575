import React, { useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Divider, ButtonBase } from '@material-ui/core'
import { AppStyleProps, buyRGPRouteNames } from '../../../utils'
import InfoComponent from '../InfoComponent/InfoComponent'
import { useGaEvents } from '../../../../../../helpers';
import { useHistory } from 'react-router';
const style=makeStyles(({colors, isDark} : AppStyleProps) => ({
    root : {
        width: "100%",
        boxSizing: "border-box",
        marginTop: "1.5rem"
    },
    box: {
        border: `1px solid ${colors.outline}`,
        color: colors.text,
        borderRadius: ".4rem",
        boxSizing: "border-box"
    },
    headSection: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "0rem 1rem 1rem 1rem"
    },
    boxHeading: {
        marginBottom: "0.5rem",
        lineHeight: "1.5rem",
        fontSize: "1rem",
    },
    subHeading: {
        fontSize: "0.875rem",
        lineHeight: "1.375rem",
    },
    dividerStyles: {
        background: colors.primary,
    },
    whitelistBody: {
        padding: "1rem",
        display: "flex",
        flexDirection: "column"
    },
    whitelistJoined: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "1rem",
    },
    key: {
        //fontWeight: 400,
        fontSize: "0.875rem",
        lineHeight: "1.375rem",
    },
    value: {
        //fontWeight: 500,
        fontSize: "0.875rem",
        lineHeight: "1.375rem",
    },
    
    backBtn: {
        width: "100%",
        padding: "1rem",
        fontWeight: 700,
        fontSize: "1rem",
        borderRadius: ".4rem",
        lineHeight: "1.5rem",
        border: 0,
        boxSizing: "border-box",
        margin: ".8rem 0",
        backgroundColor: isDark ? colors.textHighlight : colors.blue,
        color: isDark ? colors.text : colors.white,
    },
    projectsBtn: {
        width: "100%",
        padding: "1rem",
        backgroundColor: "transparent",
        color: colors.textHighlight,
        fontWeight: 700,
        fontSize: "1rem",
        lineHeight: "1.5rem",
        borderRadius: ".4rem",
        border: `2px solid ${colors.textHighlight}`,
        boxSizing: "border-box",
        margin: 0,
        cursor: "pointer",
    }
}))
type StakeBoxProps=Partial<{
    hasJoinedWhitelist : boolean,
    whitelistJoined: number,
    changeStep: any,
    projectIndex : number,
    data : any
}>

export default function WhiteBox({ 
    hasJoinedWhitelist,  
    whitelistJoined = 0, 
    changeStep,
    projectIndex,
    data
} : StakeBoxProps) {

    const classes=style(),
    history = useHistory();
    const GaTrackEvents = useGaEvents()

    const handleGotoProjectClick = useCallback(() => {
        if(!hasJoinedWhitelist) return;
        history.push(
            buyRGPRouteNames().swap_+projectIndex, 
            {data}
        )
        GaTrackEvents("View Whitelist Page","whitelist_project_button_clicked","Whitelist Project Button");
    }, [projectIndex, JSON.stringify(data), hasJoinedWhitelist])

    useEffect(() => {
        if(hasJoinedWhitelist){
            GaTrackEvents("View Whitelist Page","user_whitelist_member","Whitelist member");
        }
    }, [])
        
    return (
        <div className={classes.root}>
            <div className={classes.box}>
                <div className={classes.headSection}>
                    <h3 className={classes.boxHeading}>Whitelist</h3>
                    <span className={classes.subHeading}>Whitelist for projects hosted on RigelProtocol.</span>
                </div>
                <Divider className={classes.dividerStyles}/>
                <div className={classes.whitelistBody}>
                    <div className={classes.whitelistJoined}>
                        <span className={classes.key}>Whitelist joined</span>
                        <span className={classes.value}>{hasJoinedWhitelist ? "Yes" : "No"}</span>
                    </div>
                    <InfoComponent 
                        isPosititve = {hasJoinedWhitelist}
                        positiveText = "You have joined whitelists,but you have the option of joining more by clicking on the “Projects” button to view ongoing projects to join."
                        negativeText = "You need to join a whitelist before being able to access the LaunchPad, click on the “Projects” button to view ongoing projects to join."
                    />
                    <ButtonBase 
                        className={classes.backBtn}
                        onClick={() => changeStep("stake")}
                    >
                            Back to RGP Staking
                    </ButtonBase>

                    <ButtonBase 
                        className={classes.projectsBtn}
                        onClick={handleGotoProjectClick}
                    >
                        Open LaunchPad
                    </ButtonBase>
                </div>
            </div>
        </div>
    )
}