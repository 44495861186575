import { ReduxStoreState, WalletState } from '..';
import { store } from '..';
import Web3 from 'web3';
export default function nftContract (instance : any = undefined){
    const storeState = store.getState() as Partial<ReduxStoreState>,
    { walletReducer } = storeState,
    { contracts, address, networkId} = walletReducer as WalletState;
    if(!instance)
        instance = contracts?.launchPad;

    const convertAmount = (amount: any) => {
        return Web3.utils.toWei(amount, 'ether');
    }

    const gasFee = (num: any) => {
        const value: any = Web3.utils.toWei(num, 'gwei');
        const result = (value * 20) / 100;
        return networkId === 137 || networkId === 80001 ? result : 300000;
    }


    return {
        approve : async (recieverAddress : any, value : any) : Promise<any> => {
            return await instance?.methods?.approve(
                recieverAddress, convertAmount(value)
            ).send({from : address })
        },
        allowance : async (userAddress: string, contractAddress: any) : Promise<any> => {
            const allowanceAmount = await instance?.methods?.allowance(userAddress, contractAddress).call();
            return convertAmount(allowanceAmount);
        },
        buyNFT : (tokenId: any, tokenAddress: string, price: any) : any => {
            return instance?.methods?.buy(tokenId, tokenAddress).send({from: address, gasLimit: gasFee(price)})
        },
        purchaseData : async (id: any) : Promise<any> => {
            return await instance?.methods?.nftPurchaseData(id).call();
        },
        isSold : async (id: any) : Promise<any> => {
            return await instance?.methods?.sold(id).call();
        },
        getImage : async (id: any) : Promise<any> => {
            return await instance?.methods?.uri(id).call();
        },

    }
}
export {nftContract};
