import { makeStyles } from '@material-ui/styles';
import { AppStyleProps, LaunchPadStyleProps } from '../../../utils';
import { useGaEvents } from '../../../../../helpers';

const style = makeStyles((theme: LaunchPadStyleProps) => ({
    root: {
        background : `url("/background_land1.png") no-repeat center center / cover`,
        padding: "5rem",
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down(900)]: {
            flexDirection: "column-reverse",
        },
        [theme.breakpoints.down("sm")]: {
            padding: "1rem",
        }
    },
    textSection: {
        width: "40%",
        //marginTop: "3rem",
        [theme.breakpoints.down("md")]: {
            width: "80%",
        },
        [theme.breakpoints.down("sm")]: {
            width: "90%",
        },
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        }

    },
    heading: {
        fontfamily: "Clash Display",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "48px",
        color: theme.colors.textDefault,
        width: "40vw", //rem
        marginBottom: "1rem",
        [theme.breakpoints.down('xl')]: {
            fontSize: "48px"
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "32px",
            width: "100%",
        },
    },

    sectionText: {
        fontfamily: "DM Sans",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "18px",
        color: theme.colors.subHeadingDefault,
        width: "40vw", //rem
        lineHeight: "24px",
        opacity: .8,
        marginBottom: "2.5rem",
        [theme.breakpoints.down('xl')]: {
            fontSize: "18px"
        },
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            fontSize: "16px",
        },
    },
    launchBtn: {
        backgroundImage: "linear-gradient(90deg, #2691C1 0%, #045CA6 42.71%, #055DA6 60.42%, #238CBE 100%)",
        boxShadow: " 0px 1px 5px rgba(51, 51, 153, 0.1)",
        color: theme.colors.textDefault,
        width: "246px",
        height: "56px",
        fontSize: "16px",
        padding: "2rem 6rem",
        cursor: "pointer",
        borderRadius: "6px",
        border: "0",
        [theme.breakpoints.down('xl')]: {
            fontSize: "16px",
            padding: ".8rem 2rem",
            borderRadius: "6px",
        },
    },
    ImgSection: {
        width: "40%",
        // height: "40rem",
        marginRight: "2rem",
        [theme.breakpoints.down("md")]: {
            width: "80%",
            margin: "3rem auto 0",
        }
    },
    launchImg: {
        width: "100%",
        height: "100%"
    },
    link: {
        color: "white",
        textDecoration: "none",
        cursor: "pointer",
    }
}))

export default function LaunchPadLaunchYourProject() {

    const classes = style();
    const GaTrackEvents = useGaEvents();

    return (
        <section className={classes.root}>
            <div className={classes.textSection}>
                <h2 className={classes.heading}>Let's launch your project on RigelProtocol now!</h2>
                <p className={classes.sectionText}>
                    RigelProtocol Launchpad gives your project team the tools and help required to launch your token. We provide you with our full support on your launch journey.
                </p>
                <button className={classes.launchBtn} onClick={() => {
                    window.open("https://docs.google.com/forms/d/e/1FAIpQLSfTWg2WtgsY7UwJp2Frcer9cxppWBvAyYSxveHd7bi8gKZEVg/viewform")
                    GaTrackEvents("View Launchpad Project Page","launchpad_apply_button_clicked","Launchpad Apply Button");
                }}>Apply to Launchpad</button>
            </div>
            <div className={classes.ImgSection}>
                {/* <img className={classes.launchImg} src="./assets/launchPNG.png" alt="launch" /> */}
            </div>
        </section>
    )
}