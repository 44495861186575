export type AppTheme = Partial<{
    pageBackground: string,
    outline: string,
    gray: string,
    gray2: string,
    blue: string,
    black2: string,
    lightBlue: string,
    black: string,
    yellow: string,
    yellow2: string,
    white: string,

    primary: string,
    borderOutline: string,
    success: string,
    danger: string,
    dangerBG: string,
    text: string,
    textSecondary: string,
    textHighlight: string,
    modalBG: string,
    modalOutline: string,
    maxButtonBG: string,
    disabledBG: string,
    disabledText: string,
    highlight: string,
    light: string,
    light3: string,
    lightgrey: string,
    greyOutline: string,
    greyOutlineText: string,
    container:string,
    submenu: string,
}>

const colors = {
    page: "#15202B",
    outline: "#324D68",
    gray: "",
    blue: "#4CAFFF",
    highlight : "#4CAFFF",
    primary : "#213345",
    success : "#75F083",
    danger : "#FF3358",
    modalBG : "#15202B",
    modalOutline : "#DEE5ED",
    light : "#F1F5F8",
    light1 : "#7599BD",
    light2 : "#EAF6FF",
    light3 : "#FFFFFF",
    danger1: "#FFE5EA",
    disabledBG: "#4A739B",
    greyOutline: "#DCE6EF",
    container:"#213345",
    greyOutlineText: "#DCE6EF",
}
const colors1 = {
    page: "#fff",
    outline: "#E4E4E4",
    gray: "#CCCCCC",
    gray2: "#999999",
    blue: "#319EF6",
    lightBlue: "#EBF6FE",
    black: "#000",
    black2: "#333",
    yellow: "#FEF8E7",
    yellow2: "#B89214",
    white: "#fff",
    container:'#F2F5F8',
    highlight : "#319EF6",
    primary : "#F2F5F8",
    success : "#75F083",
    danger : "#FF3358",
    lightgrey: "#D3D3D3",
    dark: "#000000",
    dark1: "#666666",
    modalBG: "#FFFFFF",
    modalOutline: "#DEE5ED",
    light: "#EBF6FE",
    light1: "#CCCCCC",
    light3: "#333333",
    danger1: "#FFE5EA",
    disabledBG: "#999999",
    greyOutline: "#666666",
    greyOutlineText: "#666666",
}
export const appTheme = {
    dark: {
        pageBackground: colors.page,
        outline: colors.outline,
        gray: colors.gray,
        blue: colors.blue,

        primary: colors.primary,
        borderOutline: colors.outline,
        success: colors.success,
        danger: colors.danger,
        dangerBG: colors.danger1,
        text: colors.light,
        textSecondary: colors.light1,
        textHighlight: colors.highlight,
        modalBG: colors.modalBG,
        modalOutline: colors.modalOutline,
        maxButtonBG: colors.light2,
        disabledBG: colors.disabledBG,
        disabledText: colors.light1,
        highlight: colors.highlight,
        greyOutline: colors.greyOutline,
        greyOutlineText: colors.greyOutlineText,
        container: colors.container,
        light: colors.light3,
    },
    light: {
        pageBackground: colors1.page,
        outline: colors1.outline,
        gray: colors1.gray,
        gray2: colors1.gray2,
        blue: colors1.blue,
        black: colors1.black,
        black2: colors1.black2,
        yellow: colors1.yellow,
        yellow2: colors1.yellow2,
        white: colors1.white,
        light: colors1.light3,
        lightBlue: colors1.lightBlue,

        primary: colors1.primary,
        borderOutline: colors1.outline,
        success: colors1.success,
        danger: colors1.danger,
        dangerBG: colors1.danger1,
        text: colors1.dark,
        textSecondary: colors1.dark1,
        textHighlight: colors1.highlight,
        maxButtonBG: colors1.light,
        disabledBG: colors.disabledBG,
        disabledText: colors.light1,
        modalBG: colors1.modalBG,
        lightgrey: colors1.lightgrey,
        container: colors1.container,
        greyOutline: colors.greyOutline,
        greyOutlineText: colors1.greyOutlineText,
    }
}