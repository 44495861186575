import React, { MouseEventHandler, useCallback, useEffect} from 'react';
import { makeStyles } from '@material-ui/styles';
import { AppStyleProps, LaunchPadStyleProps, launchPadRouteNames } from '../../../utils';
import { ButtonBase } from '@material-ui/core';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { useGaEvents } from '../../../../../helpers';
import { useHistory } from 'react-router-dom';
import { buyRGPRouteNames } from '../../../../BuyRGP/v1';



const style = makeStyles((theme: LaunchPadStyleProps) => ({
    root: {
        position: "relative",
        width: "inherit",
        // maxWidth : 280,
        margin: 10,
        background: theme.colors.pageBackground,
        borderRadius: "6px",
        fontSize: "1.2rem",
        transformStyle: "preserve-3d",
        "&:before": {
            content: "''",
            position: "absolute",
            width: "calc(100% + 0.2rem)",
            height: "calc(100% + 0.2rem)",
            backgroundImage: "linear-gradient(90deg, #2691C1 0%, #045CA6 42.71%, #055DA6 60.42%, #238CBE 100%)",
            borderRadius: "6px",
            top: "-0.1rem",
            right: "-0.1rem",
            transform: "translateZ(-1px)"
        },
        [theme.breakpoints.down("md")]: {
            // width: "inherit",
            margin: "2rem 1rem 0",
            borderRadius: "6px",
        },
        [theme.breakpoints.down("sm")]: {
            // width: "inherit",
            borderRadius: "6px",
        }

    },
    projectPictureContainer: {
        position: "relative",
        top: 0,
        left: 0,
        height: "15rem",
        width: "100%",
        // "&:after": {
        //     content: "url('./assets/BNB.png')",
        //     backgroundRepeat: "no-repeat",
        //     position: "absolute",
        //     right: "20px",
        //     top: "20px",
        // }
    },
    projectPicture: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
    },
    projectDetails: {
        padding: "1rem",
    },
    heading: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: 0
    },
    projectName: {
        fontFamily: "Clash Display",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "20px",
        lineHeight: "30px",
        margin: 0,
        whiteSpace: "nowrap",
        [theme.breakpoints.down(1690)]: {
            fontSize: "20px",
        },
        [theme.breakpoints.down(380)]: {
            fontSize: "20px",
        },
    },
    token: {
        color: theme.colors.subHeadingDefault,
        display: "block",
        fontSize: "16px",
        fontFamily: "Gilroy-Medium",
        opacity: ".7",
        marginBottom: "2rem",
        textAlign: "left",
        [theme.breakpoints.down(1690)]: {
            fontSize: "1.3rem",
        },
        [theme.breakpoints.down(380)]: {
            fontSize: "1rem",
        },
    },
    projectBody: {

    },
    item: {
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "space-between",
        color: theme.colors.textDefault,
        opacity: ".8",
        marginBottom: 10,
        fontSize: 16
    },
    key: {
        whiteSpace: "nowrap",
        color: theme.colors.textDefault
    },
    value: {
        whiteSpace: "nowrap",
        color: theme.colors.textDefault
    },
    seperator: {
        width: "auto",
        flex: 1,
        margin: "0 1rem",
        borderBottom: `3px dotted ${theme.colors.textDefault}`,
        [theme.breakpoints.down(380)]: {
            margin: "0 .5rem",
            borderBottom: `1.5px dotted ${theme.colors.textDefault}`,
        },
    },
    projectBtn: {
        fontSize: "18px",
        color: theme.colors.textDefault,
        display: "block",
        width: "100%",
        padding: "1rem",
        margin: "2.5rem 0 .5rem",
        backgroundColor: "#131353",
        border: "none",
        borderRadius: ".3rem",
        cursor:"pointer"
    },
    projectBtnGradient: {
        backgroundImage: "linear-gradient(90deg, #2691C1 0%, #045CA6 42.71%, #055DA6 60.42%, #238CBE 100%)",
    },
    flex: {
        display: "flex",
        justifyContent: "space-between",
    },
    projectBtnBlue: {
        background: "linear-gradient(90deg, #AAD9EE 0%, #9BD0FD 42.71%, #9CD0FC 60.42%, #A9D8EF 100%)",
        boxShadow: "0px 1px 5px rgba(51, 51, 153, 0.1)",
        borderRadius: "6px",
    },
    journeyBtn1: {
        backgroundImage: " linear-gradient(90deg, #2691C1 0%, #045CA6 42.71%, #055DA6 60.42%, #238CBE 100%)",
        boxShadow: "0px 1px 5px rgba(51, 51, 153, 0.1)",
        color: theme.colors.textDefault,
        fontSize: "12px",
        width: "70px",
        height: "32px",
        fonWeight: "700",
        padding: "1.5rem 2rem",
        borderRadius: "6px",
        outline: "none",
        [theme.breakpoints.down('xl')]: {
            fontSize: "12px",
            padding: ".7rem 1.5rem",
            borderRadius: ".5rem",
        }
    },
    doubleLogo: {
        position: "absolute",
        top: "15px",
        left: "84%"
    },
    icon: {
        width: "30px",
        height: "30px",
        borderRadius: "50%",
        marginLeft: "-10px"
    }
}))

type propsType = Partial<{
    projectIndex : number,
    research : any,
    metadata : any,
    action : string,
    addresses : string[],


    hasInvested : boolean,
    viewInvestment : MouseEventHandler<HTMLButtonElement>,


    projectID: string,
    projectName: string,
    projectPicture: string,
    icon: [string],
    totalRaised: string,
    personalAllocation: any,
    state: string,
    sheetName: string,
    chain: string,
    IDODate: string,
    pool: any
}>
export default function LaunchPadProject(
    data : propsType
) {
    const classes = style(),
        { 
            projectName, projectPicture, icon, metadata, 
            personalAllocation, state, pool, sheetName, 
            projectIndex, chain, IDODate, action, hasInvested, 
            viewInvestment
        } = data || {},
        GaTrackEvents = useGaEvents(),
        history = useHistory(),
        moveToWhitelist = (callback : Function) => {
            callback && callback();
            window.location.href = launchPadRouteNames.projectWhitelist_+projectIndex;
        }

    //for wallet witelisted Projects
    const joinTokenSell = useCallback(() => {
        if(!data) return;
        const { pathname } = history.location;
        if(/app\//.test(pathname))
            history.push(buyRGPRouteNames().stake_+projectIndex, {data})
        else 
            window.location.href = buyRGPRouteNames().stake_+projectIndex;
    }, [JSON.stringify(data)]);

    function numberWithCommas(x: any) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    const getMetadata = useCallback((name : string, field : "value" | "label") => {
        if(!metadata || metadata.length === 0) return "";
        const data = metadata.find((item : any)=>item.name === name) as any;
        return data?.[field];
    }, [JSON.stringify(metadata)]);

    useEffect(() => {
        if(state === "finished"){
            GaTrackEvents("View Launchpad Project Page","launchpad_project_finished","Launchpad Project Finished")
        }
    },[])
    type RouteToType = "whitelist" | "tokenSale" | "investView"
    let routeTo : RouteToType = "whitelist";
    if(hasInvested) routeTo = "investView";
    else if(action === "tokenSale") routeTo = "tokenSale";
    return (
        <div className={classes.root}>
            <div className={classes.projectPictureContainer} >
                <img src={projectPicture} className={classes.projectPicture} alt="project pic" />
                {/* <div className={classes.doubleLogo}>
                    {icon && icon.map((item : string) => <img src={item} className={classes.icon} alt="icon" />)}
                </div> */}

            </div>
            <div className={classes.projectDetails}>
                <div className={classes.flex}>
                    <div className={classes.heading}>
                        <p className={classes.projectName}>{projectName}</p>
                    </div>
                    <ButtonBase
                        className={classes.journeyBtn1} 
                        onClick={() => {
                            if(state !== "ongoing") return;
                            if(routeTo === "whitelist") moveToWhitelist(
                                () => GaTrackEvents(
                                    "View Launchpad Project Page",
                                    "project_chain_button_clicked",
                                    "Project Chain Button"
                                )
                            ); else if(routeTo === "tokenSale")
                                joinTokenSell()
                        }}
                    >
                        {chain} Chain
                    </ButtonBase>
                </div>

                <span className={classes.token}>${String(projectName).split(" ")[0]}</span>
                <div className={classes.projectBody}>
                    <div className={classes.item}>
                        <span className={classes.key}>{getMetadata("currency", "label")}</span>
                        <span className={classes.value}>{getMetadata("currency", "value")}</span>
                    </div>
                    <div className={classes.item}>
                        <span className={classes.key}>{getMetadata("personalAllocation", "label")}</span>
                        <span className={classes.value}>{getMetadata("personalAllocation", "value")}</span>
                    </div>
                    <div className={classes.item}>
                        <span className={classes.key}>{getMetadata("presale", "label")}</span>
                        <span className={classes.value}>{getMetadata("presale", "value")}</span>
                    </div>
                    {routeTo === "whitelist" && <button 
                        className={
                            classNames(
                                classes.projectBtn, 
                                state === "finished" ? 
                                    classes.projectBtnBlue : 
                                    classes.projectBtnGradient
                            )
                        }
                        onClick={() => {
                            if(state === "ongoing") moveToWhitelist(
                                () => GaTrackEvents(
                                    "View Launchpad Project Page",
                                    "project_join_whitelist_button_clicked",
                                    "Project Join Whitelist"
                                )
                            )
                        }}
                    >
                        {state === "finished" ? "Whitelist Closed" : "Join Whitelist"}
                    </button>}
                    {routeTo === "tokenSale" && <button 
                        className={
                            classNames(
                                classes.projectBtn, 
                                state === "finished" ? 
                                    classes.projectBtnBlue : 
                                    classes.projectBtnGradient
                            )
                        }
                        onClick={() => {
                            if(state === "ongoing") 
                                joinTokenSell()
                        }}
                        >
                            {state === "finished" ? "Token Sale Closed" : "Join Token Sale"}
                        </button>}

                    {routeTo === "investView" && <button 
                        className={
                            classNames(
                                classes.projectBtn, 
                                classes.projectBtnGradient
                            )
                        }
                        onClick={(e) => viewInvestment && viewInvestment(e)}
                        >
                            View Investments
                        </button>}
                    </div>
            </div>
        </div>
    );
}
export { LaunchPadProject };