import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery,ListItem } from '@material-ui/core'
import {AppStyleProps, STORAGE_VARIABLE, AppThemeValues, APP_THEME_TYPES } from '../../../utils';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThLarge } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles(({colors, breakpoints, isDark} : AppStyleProps) => ({
  root: {
    [breakpoints.down("sm")]: {
      display: "none",
    },
  },
  cursor: {
    cursor: "pointer",
  },
  mr: {
    marginRight: '8px',
  },
  textUpper:{
    color: isDark ? '#ffffffeb' : '#1a202c',
    fontSize: '1rem',
    fontFamily: 'Cera Pro',
  },
  textLower:{
    color: '#718096',
    fontSize: '1rem',
    fontFamily: 'Cera Pro',
  },
  menu: {
    "& .MuiPaper-root" : {
      backgroundColor: isDark ? '#2d3748' : '#ffffff',
    },
  },
  menuItem: {
    "&hover": {
      backgroundColor: '#646464',
    }
  },
  outlinedPrimary: {
      display: 'flex',
      justifyContent: "center",
      alignItems: "center",
      maxHeight: "48px",
      color: colors.text,
      border: `1px solid ${isDark ? "#F2F5F8" : "#213345" }`,
      borderRadius: '6px',
      padding: "12px 16px",
    },
  btn : {
      display: 'flex !important',
      justifyContent: "center !important",
      alignItems: "center !important",
      maxHeight: "48px !important",
      maxWidth: "137px !important",
      color: `${isDark ? '#F1F5F8' : '#000000'} !important`,
      border: `1px solid ${colors.borderOutline} !important`,
      borderRadius: '6px !important',
      padding: "12px 16px !important",
  }
}));

function DappsDropdown() {

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null),
  open = Boolean(anchorEl),
  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  },
  handleClose = () => {
    setAnchorEl(null);
  },
  classes = useStyles(),
  themeType = localStorage.getItem(STORAGE_VARIABLE.appTheme) as keyof AppThemeValues,

  // the second part (themeType === null) is for when the user has not explicitly select a theme
  // we are defaulting to dark in this case
  isDark = themeType === APP_THEME_TYPES.dark || themeType === null,
  LightIcon = "/header/dapp_light.png",
  DarkIcon = "/header/dapp_dark.png",

  dropDownData = [
    {header: "SmartSwap", subheading: "Swap tokens directly.", link: "https://smartswap.rigelprotocol.com"},
    {header: "GiftDApp", subheading: "Gift tokens in a fun way.", link: "https://gift.rigelprotocol.com/"},
    {header: "Smart Bid", subheading: "Bid on tokens.", link: ""},
    {header: "Leverage Exchange", subheading: "Trade using decentralized tokens.", link: ""},
    {header: "LaunchPad", subheading: "Join projects hosted on RigelProtocol.", link: "/"}
  ];


  return (
    <div className={classes.root}>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant="outlined"
        endIcon={<ExpandMoreIcon />}
        classes={{root : classes.btn}}
      >
        <img src={isDark ? DarkIcon : LightIcon} alt={"Rigel Protocol DApp Options"}/>
        <div style={{ margin: 8 }}>DApps</div>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        // classes={{ paper: classes.menu }}
        className = {classes.menu}
      >
        <div>
          {
            dropDownData.map((item): any => {
              const isSameRoute = item.link === "/",
              itemProps : Partial<{ target : string, href : string, component : string }> = {};
              if(!isSameRoute) {
                itemProps.target = "_blank"
                itemProps.component = "a";
                itemProps.href = item.link;
              }
              return <MenuItem>
                  <ListItem {...itemProps} className={classes.menuItem}>
                    <Stack>
                      <div className={classes.textUpper}>{item.header}</div>
                      <div className={classes.textLower}>{item.subheading}</div>
                    </Stack>
                  </ListItem>
                </MenuItem>
              })
          }
        </div>
      </Menu>
    </div>
  );
}

export default DappsDropdown;
