import { customNotifierConstants } from "./config";
import { CustomNotifierDispatch, CustomNotifierState } from "./types";

export const customNotifierActions = {
    payload : 
        (payload : Partial<CustomNotifierState>) => 
        (dispatch : CustomNotifierDispatch) => dispatch({
            type : customNotifierConstants.SET_CUSTOM_NOTIFIER,
            payload
        })
}