import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import classNames from 'classnames';
import Drawer from '@material-ui/core/Drawer';
import { buyRGPRouteNames, AppStyleProps } from '../../../../utils';
import Connected from "../Connection/Connected";
import { Link } from 'react-router-dom';
import ReferralModal from '../ReferralModal';


const useStyles = makeStyles(({colors, breakpoints} : AppStyleProps) => ({
    cursor: {
      cursor: "pointer",
    },
    text: {
      color: colors.text,
      fontFamily: `Cera Pro`,
      fontSize: `18px`,
      fontStyle: `normal`,
      lineHeight: `24px`,
      letterSpacing: `0em`,
      textAlign: `center`,
    },

    textDiabled: {
      color: colors.disabledBG
    },
    textMenu: {
      fontSize: "12px",
      textTransform: "uppercase"
    },

    close: {
      display: "none",
      [breakpoints.down("sm")] : {
        display : "flex",
        borderRadius: `6px`,
        justifyContent: `center`,
        alignItems: `center`,
        padding: "12px",
        width: "21.5px",
        height: "21.5px",
        color: colors.text,
        boxSizing: `border-box`,
        border: `1px solid ${colors.greyOutline}`,
      },
    },
    list: {
      width: 250,
      background: colors.pageBackground,
      height: "100%",
      color: colors.text,
      border: `1px solid ${colors.borderOutline}`,
      textDecoration: "none"
    },
    fullList: {
      width: 'auto',
    },
    midiumlogo: {
      height: 24,
      width: 24,
    },
    line: {
      background: colors.borderOutline
    },
    listIten: {
      textDecoration: "none",
      color: colors.text,
      cursor: "pointer"
    }
  }));


export default function SideNav(props: any) {
  const classes = useStyles(),
  {state, toggleDrawer, connected } = props,
  [displayRefModal, setDisplayRefModal] = React.useState(false),

  linkList: Array<any> = [{id: 1, name: "SmartSwap", link: "https://smartswap.rigelprotocol.com/"},
              {id: 2, name: "GiftDApp", link: "https://gift.rigelprotocol.com/"},
              {id: 3, name: "SmartBid", link: "https://smartswap.rigelprotocol.com/#/smartbid"},
              {id: 4, name: "AutoPeriod", link: "https://smartswap.rigelprotocol.com/#/auto-period"},
              {id: 5, name: "Farms", link: "https://smartswap.rigelprotocol.com/#/farming-v2"},
              {id: 6, name: "LaunchPad", link: "/"}],

 list = (anchor: any) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
    >
      <List style={{marginTop: 35}}>
          <div style={{display: "flex", justifyContent: "flex-start", marginLeft: "16px", marginBottom: 29.25, cursor: "pointer"}}>
              <div className={classNames(classes.close)} onClick={toggleDrawer("right", false)}><CloseIcon fontSize="small"/></div>
          </div>
          <ListItem component={"div"}>
            <div className={classNames(classes.textMenu, classes.textDiabled)}>Menu</div>
          </ListItem>
          <ListItem   component={Link} className={classes.listIten} to={buyRGPRouteNames().projects} onClick={toggleDrawer("right", false)}>
            <ListItemText primary={"Projects"} />
          </ListItem>
          <ListItem component={Link} className={classes.listIten} to={buyRGPRouteNames().nfts} onClick={toggleDrawer("right", false)}>
            <ListItemText primary={"RGP NFTs"} />
          </ListItem>
          <ListItem component={Link} className={classes.listIten} to={buyRGPRouteNames().mintNft} onClick={toggleDrawer("right", false)}>
            <ListItemText primary={"NFTs pad"} />
          </ListItem>
          <ListItem component={"div"} className={classes.listIten} onClick={() =>{ toggleDrawer("right", false); setDisplayRefModal(!displayRefModal)}}>
            <ListItemText primary={"Referral"} />
          </ListItem>
          {connected && <Connected/>}
      </List>
      <List>
          <ListItem component={"div"}>
            <div className={classNames(classes.textMenu, classes.textDiabled)}>DApps</div>
          </ListItem>

         {
          linkList.map((item) => <ListItem key={item.id} component={"a"} className={classes.listIten} href={item.link}>
            <ListItemText primary={item.name} />
          </ListItem>)
         }
      </List>
    </div>
  );

  return (
      <>
        <Drawer
            anchor={"right"}
            open={state["right"]}
            variant="persistent"
        >
            {list("right")}
        </Drawer>
        <ReferralModal 
        title="Get 10% tokens"
        text=" Enjoy 10% on token purchases when your friends purchase tokens
        on this project with your referral link. Your friends also benefit 5% on token purchases when they purchase with your referral link."
        
        hostDetail={window.location.origin+buyRGPRouteNames().projects}
        displayRefModal={displayRefModal} setDisplayRefModal={()=>setDisplayRefModal(false)}/>
      </>
  );
}
