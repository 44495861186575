import { ReduxStoreState, WalletState } from '..';
import { store } from '..';
import { ZERO_ADDRESS } from '../constants';
export default function launchPadContract (instance : any = undefined){
    const storeState = store.getState() as Partial<ReduxStoreState>,
    { walletReducer } = storeState,
    { contracts, address} = walletReducer as WalletState;
    if(!instance)
        instance = contracts?.launchPad;
    const reducerNumber = (amount : number) : number => {
        return amount / (10 ** 18);
    }
    const viewData = async () : Promise<{
        swapTokenFrom : string,
        swapTokenTo : string,
        specialPoolC : string,
        price : number, 
        expectedLockFunds : number,
        lockedFunds : number,
        maxLockForEachUser : number,
        minLockForEachUser : number,
    }> => await instance?.methods?.viewData().call(),
    enableWhitelisting = async () : Promise<boolean> => await instance?.methods?.enableWhitelisting().call()
    return {
        reducerNumber,
        checkPoolBalance : async () : Promise<boolean> => await instance?.methods?.checkPoolBalance(address).call(),
        saleActive : async () : Promise<boolean> => await instance?.methods?.saleActive().call(),
        viewData,
        specialPoolC : async () : Promise<string> => (await viewData())?.specialPoolC,
        swapTokenFrom : async () : Promise<string> => (await viewData())?.swapTokenFrom,
        swapTokenTo : async () : Promise<string> => (await viewData())?.swapTokenTo,
        price : async () : Promise<number> => reducerNumber((await viewData())?.price),
        lockedFunds : async () : Promise<number> => reducerNumber((await viewData())?.lockedFunds),
        maxLock : async () : Promise<number> => reducerNumber((await viewData())?.maxLockForEachUser),
        minLock : async () : Promise<number> => reducerNumber((await viewData())?.minLockForEachUser),
        expectedLockFunds : async () : Promise<number> => reducerNumber((await viewData()).expectedLockFunds),
        
        period : async (index : number) : Promise<{
            distTime : number,
            distPercent : number,
        }> => {
            const {distributionTime, distributionPercentage} = await instance?.methods?.period(0, index).call();
            return {
                distTime : distributionTime * 1000,
                distPercent : reducerNumber(distributionPercentage)
            }
        },
        periodLength : async () : Promise<number> => await instance?.methods?.getLengthOfPeriod().call(),
        getMinimum : async () : Promise<number> => reducerNumber(await instance?.methods?.getMinimum().call()),
        lockFund : async (tokenAmount : string, referral : string) : Promise<any> => {
            if(String(referral).toUpperCase() === String(address).toUpperCase()) 
                throw new Error("Self referral");         
            return await instance?.methods?.lockFund(tokenAmount, referral || ZERO_ADDRESS).send({from : address})
        },
        isWhitelist : async () : Promise<any> => {
            const isWhitelisted = await instance?.methods?.isWhitelist(address).call(),
            isEnabled = await enableWhitelisting();
            return !isEnabled || isWhitelisted;
        },
        userFunds : async () : Promise<number> => {
            const fund = reducerNumber((await instance?.methods?.userFunds(address).call())?.lockedFunds),
            price = reducerNumber((await viewData())?.price);
            return fund / (price || 0);
        },
        userFundsFromToken : async () : Promise<number> => {
            return reducerNumber((await instance?.methods?.userFunds(address).call())?.lockedFunds)
        },
        getReferralCount : async () : Promise<number> => {
            return instance?.methods?.getReferralCount(address).call()
        },
        referralPercent : async () : Promise<number> => {
            return instance?.methods?.referralPercent().call()
        },
        referredAndReferralRw : async (refAddress : string) : Promise<number> => {
            return instance?.methods?.referredAndReferralRw(refAddress).call()
        },
        enableWhitelisting
    }
}
export {launchPadContract};