import React from "react";
import { SupportedChainId } from './constants';

export const shortAddress = (address: string): string => {
    if (!address) return "...";
    return address.substr(0, 4) +
        "..." +
        address.substr(address.length - 4, 4)
}
export const onEnterPress = (action: (a: React.KeyboardEvent) => any) => (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
        event.preventDefault();
        event.stopPropagation();
        if (action)
            action(event);
    }
}
export const copyToClipboard = (text: string, keepTabs: boolean) => {
    let input: HTMLTextAreaElement | HTMLInputElement;
    if (keepTabs) {
        input = document.createElement("textarea");
        input.textContent = text;
    } else {
        input = document.createElement("input");
        input.setAttribute("value", text);
    }
    document.body.appendChild(input);
    input.select();
    var result: boolean | undefined = document.execCommand("copy");
    navigator.clipboard.writeText(text);
    document.body.removeChild(input);
    return result;
}
type Context = {
    setState: (object: any) => any,
    state: any | Function
}
export const customSuperSetState = <C extends Context, State>(context: C) => {
    return function (state: State, callback: any) {
        if (state instanceof Function) {
            if (!context.state) {
                const errorMsg = "The component has no state"
                throw errorMsg;
            }
            else {
                var newStateObject = state(context.state);
                context.setState(newStateObject)
            }
        } else {
            context.setState(state);
            if (callback) callback();
        }
    }
}
export const isDevelopment = () => {
    const { NODE_ENV } = process.env;
    return NODE_ENV === "development";
}
export const inputDecimalValidator = (value: string): boolean => /^(^$|[0-9]+|[0-9]+\.|[0-9]+\.[0-9]+)$/.test(value)
export const tokenAmount = (amount: number, getNumber: boolean | undefined = false) => {
    // amount = 2662.8
    const decimal = String(amount).split(".")[1];
    if (decimal) return Number(amount).toPrecision(decimal.length);
    return getNumber ? Number(amount) : Number(amount).toLocaleString();
}

export const fullnameRegexPattern = /^[a-zA-Z]+(?:[\s.]+[a-zA-Z]+)*$/
export const telegramUsernamePattern = /^.*?\B@\w{5}.*$/
export const erc20AddressPattern = /^0x[a-fA-F0-9]{40}$/

const ETHERSCAN_PREFIXES: { [chainId: number]: string } = {
  //[SupportedChainId.MAINNET]: '',
  [SupportedChainId.ROPSTEN]: 'ropsten.',
  [SupportedChainId.RINKEBY]: 'rinkeby.',
  [SupportedChainId.GOERLI]: 'goerli.',
  [SupportedChainId.KOVAN]: 'kovan.',
  [SupportedChainId.POLYGON]: 'polygon.',
  [SupportedChainId.BINANCE]: 'binance.',
  [SupportedChainId.BINANCETEST]: 'binance-test.',
  [SupportedChainId.POLYGONTEST]: 'polygon-test.',
};

export enum ExplorerDataType {
  TRANSACTION = 'transaction',
  TOKEN = 'token',
  ADDRESS = 'address',
  BLOCK = 'block',
}

/**
 * Return the explorer link for the given data and data type
 * @param chainId the ID of the chain for which to return the data
 * @param data the data to return a link for
 * @param type the type of the data
 */
export function getExplorerLink(
  chainId: number,
  data: string,
  type: ExplorerDataType
): string {
  if (chainId === SupportedChainId.POLYGON) {
    switch (type) {
      case ExplorerDataType.TRANSACTION:
        return `https://polygonscan.com/tx/${data}`;
      case ExplorerDataType.ADDRESS:
      case ExplorerDataType.TOKEN:
        return `https://polygonscan.com/address/${data}`;
      case ExplorerDataType.BLOCK:
        return `https://polygonscan.com/block/${data}`;
      default:
        return `https://polygonscan.com`;
    }
  }

  if (chainId === SupportedChainId.POLYGONTEST) {
    switch (type) {
      case ExplorerDataType.TRANSACTION:
        return `https://mumbai.polygonscan.com/tx/${data}`;
      case ExplorerDataType.ADDRESS:
      case ExplorerDataType.TOKEN:
        return `https://mumbai.polygonscan.com/address/${data}`;
      case ExplorerDataType.BLOCK:
        return `https://mumbai.polygonscan.com/block/${data}`;
      default:
        return `https://mumbai.polygonscan.com`;
    }
  }

  if (chainId === SupportedChainId.BINANCE) {
    switch (type) {
      case ExplorerDataType.TRANSACTION:
        return `https://www.bscscan.com/tx/${data}`;
      case ExplorerDataType.ADDRESS:
      case ExplorerDataType.TOKEN:
        return `https://www.bscscan.com/address/${data}`;
      case ExplorerDataType.BLOCK:
        return `https://www.bscscan.com/block/${data}`;
      default:
        return `https://www.bscscan.com`;
    }
  }

  if (chainId === SupportedChainId.BINANCETEST) {
    switch (type) {
      case ExplorerDataType.TRANSACTION:
        return `https://testnet.bscscan.com/tx/${data}`;
      case ExplorerDataType.ADDRESS:
      case ExplorerDataType.TOKEN:
        return `https://testnet.bscscan.com/address/${data}`;
      case ExplorerDataType.BLOCK:
        return `https://testnet.bscscan.com/block/${data}`;
      default:
        return `https://testnet.bscscan.com`;
    }
  }

  const prefix = `https://${ETHERSCAN_PREFIXES[chainId] ?? ''}etherscan.io`;

  switch (type) {
    case ExplorerDataType.TRANSACTION:
      return `${prefix}/tx/${data}`;

    case ExplorerDataType.TOKEN:
      return `${prefix}/token/${data}`;

    case ExplorerDataType.BLOCK:
      if (
        chainId === SupportedChainId.GOERLI ||
        chainId === SupportedChainId.ROPSTEN ||
        chainId === SupportedChainId.KOVAN ||
        chainId === SupportedChainId.RINKEBY
      ) {
        return `${prefix}/tx/${data}`;
      }
      return `${prefix}/block/${data}`;

    case ExplorerDataType.ADDRESS:
      return `${prefix}/address/${data}`;
    default:
      return `${prefix}`;
  }
}
