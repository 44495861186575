import { makeStyles } from '@material-ui/styles';
import { ArrowForward } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faLinkedin, faTelegram, faTwitter, faGithub, faDiscord, faMedium } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom'
import { AppStyleProps } from '../../../utils';

const style = makeStyles((theme: AppStyleProps) => ({
    root: {
        padding: "5rem 5rem 3.9rem 5rem",
        backgroundColor: theme.colors.pageBackground,
        color: "#fff",
        [theme.breakpoints.down('sm')]: {
            padding: "1rem",
        }
    },
    footerGrid: {
        display: "flex",
        justifyContent: "space-between",
        fontSize: "16px",
        [theme.breakpoints.down('sm')]: {
            flexWrap: "wrap",
        }
    },
    section: {
        width: "20%",
        [theme.breakpoints.down('sm')]: {
            width: "50%",
        }
    },
    sectionTitle: {
        fontFamily: " DM Sans",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#566676",
        [theme.breakpoints.down('xl')]: {
            fontSize: "16px",
        },
    },

    socials: {
        width: "100%",
    },

    socialIconsWrapper: {
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    socialIcons: {
        color: "#fff",
        transition: "all ease-in-out .3s",
        "&:hover": {
            opacity: .8,
        },
    },
    sectionItem: {
        fontfamily: "DM Sans",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#FFFFFF",
        display: "block",
        margin: "1.5rem 0",
        textDecoration: "none",
        transition: "all ease-in-out",
        "&:hover": {
            opacity: .8,
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: "90%"
        }
    },

    logoContainer: {
        display: "flex",
        alignItems: "flex-start",
        marginTop: "17px"

    },

    featuredContainer: {
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down(900)]: {
            flexDirection: "column-reverse",
        },
        [theme.breakpoints.down("sm")]: {
            padding: "2rem",
        }
    },
    leftSection: {
        width: "30%",
        [theme.breakpoints.down("md")]: {
            width: "90%",
        }
    },
    rightSection: {
        width: "70%",
        marginTop: "0rem",
        [theme.breakpoints.down("md")]: {
            width: "90%",
        },
        [theme.breakpoints.down("sm")]: {
            width: "90%",
        },
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        }

    },

    leftSectionText: {
        fontfamily: "DM Sans",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "24px",
        marginBottom: "15px",
        width: "70%",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    }
}))


export default function Footer() {
    const classes = style();

    return (
        <footer className={classes.root}>
            <div className={classes.featuredContainer}>
                <div className={classes.leftSection}>
                    <div className={classes.socials}>
                        <div className={classes.logoContainer}>
                            <img src="/header/darklogo.svg" alt="dark logo" />
                        </div>
                        <p className={classes.leftSectionText}>DApps with the best experience and low fees.</p>
                        <div className={classes.socialIconsWrapper}>
                            <a href="https://linkedin.com/company/rigelprotocol" className={classes.socialIcons}
                                target="_blank">
                                <FontAwesomeIcon icon={faLinkedin} />
                            </a>
                            <a href="https://t.me/rigelprotocol" target="_blank" className={classes.socialIcons}>
                                <FontAwesomeIcon icon={faTelegram} />
                            </a>
                            <a href="https://discord.gg/j86NH95GDD" target="_blank" className={classes.socialIcons}>
                                <FontAwesomeIcon icon={faDiscord} />
                            </a>

                            <a href="https://twitter.com/rigelprotocol" target="_blank" className={classes.socialIcons}>
                                <FontAwesomeIcon icon={faTwitter} />
                            </a>

                            <a href="https://medium.com/rigelprotocol" target="_blank" className={classes.socialIcons}>
                                <FontAwesomeIcon icon={faMedium} />
                            </a>
                            <a href="https://github.com/rigelprotocol" target="_blank" className={classes.socialIcons}>
                                <FontAwesomeIcon icon={faGithub} />
                            </a>
                        </div>
                    </div>
                    {/* </div> */}

                </div>
                <div className={classes.rightSection}>
                    <div className={classes.footerGrid}>
                        <div className={classes.section}>
                            <h3 className={classes.sectionTitle}>Products</h3>
                            <a href="https://smartswap.rigelprotocol.com/" target="_blank" className={classes.sectionItem}>SmartSwap</a>
                            <a href="https://giftdapp.rigelprotocol.com/" target="_blank" className={classes.sectionItem}>Gift DApp</a>
                            <a href="/" target="_blank" className={classes.sectionItem}>Leverage Exchange</a>
                            <a href="/" target="_blank" className={classes.sectionItem}>SmartBid</a>
                            <a href="/" target="_blank" className={classes.sectionItem}>LaunchPad</a>
                        </div>
                        <div className={classes.section}>
                            <h3 className={classes.sectionTitle}>Company</h3>
                            <a href="https://rigelprotocol.com/about.html" target="_blank" className={classes.sectionItem}>About Us</a>
                            <a href="https://rigelprotocol.com/index.html" target="_blank" className={classes.sectionItem}>RoadMap</a>
                            <a href="https://rigelprotocol.com/" target="_blank" className={classes.sectionItem}>Partners</a>
                            <a href="https://rigelprotocol.com/press.html" target="_blank" className={classes.sectionItem}>Press Resources</a>
                        </div>
                        <div className={classes.section}>
                            <h3 className={classes.sectionTitle}>Support</h3>
                            <a href="https://rigelprotocol.com/faqs.html" target="_blank" className={classes.sectionItem}>FAQs</a>
                            <a href="https://medium.com/rigelprotocol" target="_blank" className={classes.sectionItem}>Blog</a>

                        </div>
                        <div className={classes.section}>
                            <h3 className={classes.sectionTitle}>Legal</h3>
                            <a href="https://rigelprotocol.com/design-compliance" target="_blank" className={classes.sectionItem}>Design Compliance</a>
                            <a href="https://rigelprotocol.com/privacy-policy" target="_blank" className={classes.sectionItem}>Privacy Policy</a>
                            <a href="https://rigelprotocol.com/terms-and-condition" target="_blank" className={classes.sectionItem}>Terms & Conditions</a>
                        </div>

                    </div>
                </div>
            </div>
        </footer>
    )

}
export { Footer }