import { abis } from "..";
import { 
    isDevelopment, NETWORKS, networkTypes, Strict, 
    WalletTypes, walletTypes, tokenContractActions, tokenAmount
} from "..";
import { 
    AddressSet, ReduxStoreState, Contracts, ReduxActionReturnValue, 
    WalletAddresses, WalletState, UtilState, appUtils
} from "../../../../../utils";
import getWeb3 from "./getWeb3";
import walletUtil from "./walletUtil";
import { walletActions } from "../../components/structures/Web3Provider/actions";
import { Dispatch } from "react";
import accountListeners, { binanceListeners } from "./accountListeners";
import { store } from "../../../../../helpers";
import { errorToast } from '../../includes'
export default async function walletConnection(
    contractAddresses : WalletAddresses,
    networkWallet : Strict<keyof WalletTypes>,
    precall : Function | null | undefined,
    callback : Function | undefined | null,
    forceBalUpdate : boolean = false
){
    if(!contractAddresses) throw new Error("Address list is missing");
    let {web3, accounts} = await getWeb3(networkWallet);
    if(!web3) throw new Error("Web3 Provider not found! Download metamask or trust wallet!");
    let networkId = await web3.eth.net.getId();
    const address = accounts && accounts[0];
    //@ts-ignore
    const {_provider : ethereum} : any = web3,
    falseNetwork = walletUtil.SUPPORTED_NETWOR_IDS.indexOf(Number(networkId)) === -1;
    if(falseNetwork){
        if(!isDevelopment()){
            await walletUtil.switchNetwork(ethereum, NETWORKS.bsc.chainId, NETWORKS.bsc);
            await walletConnection(contractAddresses, networkWallet, precall, callback);
            return;
        }
        // networkId = 0;
    }
    //to reach here when on false network, it must be on development.
    const addresses : AddressSet = contractAddresses && contractAddresses[
        walletUtil.getTruffleNetworkName(falseNetwork && isDevelopment() ? 0 : networkId)
    ];
    if(!addresses){
        console.error("contract address not found")// handle error
        const error = new Error(`<div>Contract address not found, Check your network</div>`);
        throw {...error, code : 301};
    }
    precall && precall();
    if(accounts?.length < 1){
        throw new Error("No account found!") // handle error
    }
    let {contracts, walletBalance, rgpBalance} = (store.getState() as Partial<ReduxStoreState>).walletReducer as WalletState;
    let _contracts = contracts || {} as Contracts;

    //######## this can be removed when contract is set #######
    // if(!appUtils.isDev()) {
    //     const mainNets = walletUtil.MAINNETS;// [137, 56];
    //     if(mainNets.indexOf(networkId) !== -1){
    //         await NFTSpecialConnection({ 
    //             networkId, web3, addresses, address,
    //             networkWallet, contractAddresses, 
    //             callback : Function,
    //         });
    //         return;
    //     }
    // }
    //######## end
    
    if(forceBalUpdate || !_contracts.rgp){
        _contracts.rgp = appUtils.erc20Instance(web3, addresses.rgp);
        rgpBalance = tokenAmount(await tokenContractActions(_contracts.rgp).balanceOf(address)) as string;
        walletBalance = tokenAmount(tokenAmount(Number(await web3.eth.getBalance(address)), true) as number/ 10**18) as string;
    }
    if(forceBalUpdate || !_contracts.specialPool){
        _contracts.specialPool = appUtils.specialPoolInstance(web3, addresses.specialPool);
    }
    const networkType = walletUtil.ETHEREUM_NETWORK_IDS.indexOf(networkId) !== -1 ||
            (walletUtil.BINANCE_NETWORK_IDS.indexOf(networkId) === -1)  ?
            networkTypes.ethereum : networkTypes.binance,
    {
        storageNetworkID, storageNetworkType, storageNetworkWallet, storageTokenContract
    } = (store.getState() as Partial<ReduxStoreState>).utilReducer as UtilState;
    if(!storageNetworkID || !storageNetworkType || !storageNetworkWallet){
        return console.error("Browser storage variables not set");
    }
    localStorage.setItem(storageNetworkID.toString(), networkId.toString());
    localStorage.setItem(storageNetworkType.toString(), networkType.toString());
    localStorage.setItem(String(storageNetworkWallet).toString(), networkWallet);
    const dispatch = store.dispatch as Dispatch<ReduxActionReturnValue<Partial<WalletState>>>;
    walletActions.payload({
        networkId,
        walletType : networkWallet,
        networkType, web3, addresses, address,
        contracts : _contracts,
        contractAddresses, walletBalance, rgpBalance
    })(dispatch)
    networkWallet === walletTypes.binance ? binanceListeners() : accountListeners();
    callback && callback(networkId);
}
export { walletConnection }

async function NFTSpecialConnection({
    networkId, web3, addresses, address,
    networkWallet, contractAddresses,
    callback
}: { 
    networkId : number,
    web3 : any,
    addresses : any,
    address : string,
    networkWallet : keyof WalletTypes,
    contractAddresses : any,
    callback : Function
}){
    const { pathname } = window.location;
    if(!/\/(nfts|nft\/)/.test(pathname)) // this uses RouteMonitor component to disconnect when none NFT page in routed to on mainnet.
        return errorToast("Only NFT page works on Mainnet!")(store.dispatch)
    const contracts : Contracts = {
        rgp : new web3.eth.Contract(abis.erc20, addresses.rgp),
        specialPool : null,
        selectedPad : null,
        launchPad : {}
    }
    const rgpBalance = tokenAmount(await tokenContractActions(contracts.rgp).balanceOf(address)) as string;
    const walletBalance = tokenAmount(tokenAmount(Number(await web3.eth.getBalance(address)), true) as number/ 10**18) as string;

    const networkType = walletUtil.ETHEREUM_NETWORK_IDS.indexOf(networkId) !== -1 ||
            (walletUtil.BINANCE_NETWORK_IDS.indexOf(networkId) === -1)  ?
            networkTypes.ethereum : networkTypes.binance,
    {
        storageNetworkID, storageNetworkType, storageNetworkWallet, storageTokenContract
    } = (store.getState() as Partial<ReduxStoreState>).utilReducer as UtilState;
    if(!storageNetworkID || !storageNetworkType || !storageNetworkWallet){
        return console.error("Browser storage variables not set");
    }
    localStorage.setItem(storageNetworkID.toString(), networkId.toString());
    localStorage.setItem(storageNetworkType.toString(), networkType.toString());
    localStorage.setItem(String(storageNetworkWallet).toString(), networkWallet);
    const dispatch = store.dispatch as Dispatch<ReduxActionReturnValue<Partial<WalletState>>>;
    walletActions.payload({
        networkId,
        walletType : networkWallet,
        networkType, web3, addresses, address,
        contracts,
        contractAddresses, walletBalance, rgpBalance
    })(dispatch)
    networkWallet === walletTypes.binance ? binanceListeners() : accountListeners();
    callback && callback(networkId);
}