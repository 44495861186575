import React, { useState, useRef } from "react";
import { makeStyles, Grid } from '@material-ui/core';
import { addRows, AppStyleProps, filterRows, ReduxStoreState } from '../../../utils';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector } from "react-redux";
import ProjectDataTermsAndCondition from "../ProjectData/ProjectDataTermsAndConditions";
import { CustomSnackbarDispatch, errorToast, successToast } from "../../../includes";
import { erc20AddressPattern, fullnameRegexPattern, telegramUsernamePattern } from "../../../../BuyRGP/v1";
import { useGaEvents } from '../../../../../helpers';



const style = makeStyles((theme: AppStyleProps) => ({
    root: {
        width: "80%",
        margin: "4rem auto",
        [theme.breakpoints.down('sm')]: {
            width: "85%",
        },
    },
    box: {
        padding: "0 2rem",
        boxSizing: "border-box",
        backgroundColor: "#001D33",
        border: "1px solid #B0B0EF",
        marginTop: 30,
    },
    formHeading: {
        fontWeight: 600,
        fontSize: "2.25rem",
        lineHeight: "2.25rem",
        marginBottom: ".5rem",
    },
    formText: {
        fontWeight: 400,
        fontSize: "1rem",
        lineHeight: "1.5rem",
        marginBottom: "4rem",
    },
    formTitle: {
        fontWeight: 600,
        fontSize: "1.25rem",
        lineHeight: "1.75rem",
        marginBottom: "1.5rem",
    },
    formGroup: {
        width: "100%",
        marginBottom: "2rem",
    },
    label: {
        fontSize: "1rem",
        fontWeight: 400,
        lineHeight: "1.5rem",
        display: "block",
        marginBottom: "1rem",
        color: "#D5D5F6",
    },
    inputField: {
        width: "100%",
        outline: 0,
        color: "#D5D5F6",
        border: "1px solid #B0B0EF",
        backgroundColor: "transparent",
        padding: ".8rem",
        boxSizing: "border-box",
        fontSize: "1.1rem",
        borderRadius: ".3rem",
    },
    consentBlock: {
        fontSize: "1rem",
        fontWeight: 400,
        lineHeight: "1.5rem",
        color: "#D5D5F6",
        marginBottom: "3rem",
    },
    consentText: {

    },
    consentLink: {
        color: "#33A8FF",
        cursor: "pointer",
        textDecoration: "underline"
    },
    controlGroup: {
        margin: 0,
    },
    checkBox: {
        margin: 0,
        marginRight: ".5rem",
        border: 0,
        outline: 0,
    },
    btn: {
        backgroundColor: "#33A8FF",
        border: 0,
        outline: 0,
        padding: "1rem",
        display: "block",
        width: "100%",
        color: "#D5D5F6",
        fontSize: "1.125rem",
        lineHeight: "1.5rem",
        borderRadius: ".3rem",
        marginBottom: "2rem",
        cursor: "pointer",
    },
    projectContainer: {
        marginBottom: 70,
        width: '100%'
    },
    projectContent: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
    },
    column: {
        flexDirection: 'column',
    },
    image: {
        width: '373px',
        height: '254px',
        background: `url(/assets/whitelist/whitelist.png)`,
        borderRadius: '6px',
        marginBottom: 20,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    projectText: {
        flexWrap: 'wrap',
        marginLeft: 40,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
        }
    },
    socialMedia: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '0px',
        marginTop: 40,
    },
    text: {
        fontFamily: 'Clash Display',
        fontSize: '1.1rem',
        lineHeight: '24px',
        color: '#FFFFFF',
    },
    iconTextMargin: {
        marginLeft: 5,
        marginBottom: 15
    },
    socialIcons: {
        display: 'flex',
        color: "#fff",
        transition: "all ease-in-out .3s",
        "&:hover": {
            opacity: .8,
        },
        textDecoration: 'none'
    },
    errorText: {
        color: 'red'
    },

    errorInput: {
        border: "1px solid red",
    }

}))

type Proptypes = Partial<{
    projectName: null | string,
    whitlistSheetName: null | string
}>

const ProjectWhitelistForm: React.FC<Proptypes> = ({ whitlistSheetName, projectName }) => {
    const TandCRef = useRef<HTMLInputElement>(null)
    const classes = style(),
        dispatch = useDispatch<CustomSnackbarDispatch>(),
        [state, _setState] = useState<stateTypes>({
            isLoading: false,
            hasAcceptedTandC: false,
            TandCErrMsg: ""
        }),
        [openDialog, setOpenDialog] = useState(false),
        { isLoading, hasAcceptedTandC, TandCErrMsg } = state,
        setState = (_state: Partial<stateTypes>) => _setState(state => ({ ...state, ..._state }));

    const walletReducer = useSelector((state: ReduxStoreState) => state.walletReducer);

    type UserSubmitForm = {
        fullname: string;
        walletAddress: string;
        email: string;
        amount: number;
        telegramUsername: string;
        acceptTerms: boolean;
    };

    type stateTypes = {
        isLoading: boolean
        hasAcceptedTandC: boolean
        TandCErrMsg: string
    };

    const validationSchema = Yup.object().shape({
        fullname: Yup.string().required('Fullname is required')
            .matches(fullnameRegexPattern, "Please enter your name in the right format!"),
        telegramUsername: Yup.string()
            .required('Telegram Username is required')
            .matches(telegramUsernamePattern, "Invalid telegram username"),
        email: Yup.string()
          .required('Email is required')
          .email('Email is invalid'),
        walletAddress: Yup.string()
            .required('Wallet Address is required')
            .matches(erc20AddressPattern, "Invalid ERC20  address"),
        amount: Yup.number().required("Amount is Required"),
        // acceptTerms: Yup.boolean()
        // .default(false)
        // .oneOf([true], 'Accept Terms is required')
        
    },);

    const handleTandCChange = (e: any) => {
        // if user try to uncheck after checking before
        if(hasAcceptedTandC) setState({TandCErrMsg: "Please accept terms and  condition"})
        setState({hasAcceptedTandC: e.target.checked});
        if(e.target.checked && TandCErrMsg !== "") setState({TandCErrMsg: ""})
    }

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<UserSubmitForm>({
        resolver: yupResolver(validationSchema)
    });
    const GaTrackEvents = useGaEvents();

    const onSubmit = async (data: UserSubmitForm) => {
        if(!TandCRef.current?.checked) return setState({TandCErrMsg: "Please accept terms and  condition"})
        setState({ isLoading: true });
        const query = await filterRows(whitlistSheetName as string, "Wallet Address", data.walletAddress);
        if (query?.status !== 200) {
            setState({ isLoading: false });
            return errorToast("Something went wrong! please try again")(dispatch)
        }
        if (query?.data.length !== 0) {
            setState({ isLoading: false });
            return errorToast("This Address has already been whitelisted for this project, go back and select another project")(dispatch);
        }
        addRows(
            whitlistSheetName as string,
            {
            "Full Name":  data.fullname,
            "Email Address": data.email,
            "Wallet Address": data.walletAddress,
            "Telegram Username": data.telegramUsername,
            "Amount": data.amount
        }).then(result => {
            if(result.status === 200){
                successToast("WhiteList Registration Successful!")(dispatch);
                GaTrackEvents("View Whitelist Registration Page", "whitelist_project_registration_button_clicked","Whitelist Project Registration success");
            }
        }).catch(err => {
            console.error(err)
            GaTrackEvents("View Whitelist Registration Page", "whitelist_project_registration_failed","Whitelist Project Registration failure");
        });
        setState({isLoading: false});
      };
        
    return(
        <div className={classes.root}>
            <div className={classes.box}>
                <h2 className={classes.formHeading}>Join the whitelist</h2>
                <h3 className={classes.formTitle}>
                    Basic Information
                </h3>
                <form onSubmit={handleSubmit(onSubmit)}>

                    <div className={classes.formGroup}>
                        <label htmlFor="fullname" className={classes.label}>
                            Full Name
                        </label>
                        <input type="fullname"
                            {...register('fullname')}
                            className={classNames(classes.inputField, `${errors.fullname ? classes.errorInput : ''}`)}
                            name="fullname"
                        />
                        <p className={classes.errorText}> {errors.fullname?.message} </p>
                    </div>

                    <div className={classes.formGroup}>
                        <label htmlFor="email" className={classes.label}>
                            Email Address
                        </label>
                        <input type="email"
                            {...register('email')}
                            className={classNames(classes.inputField, `${errors.email ? classes.errorInput : ''}`)}
                            name="email"
                        />
                        <p className={classes.errorText}> {errors.email?.message} </p>
                    </div>

            <div className = {classes.formGroup}>
                <label htmlFor = "walletAddress" className = {classes.label}>
                    ERC20 / BEP20 Wallet Address
                </label>
                <input type = "text"
                    {...register('walletAddress')}
                    className = {classNames(classes.inputField,`${errors.walletAddress ? classes.errorInput: ''}`)} 
                    name = "walletAddress"
                />
                <p className = {classes.errorText}> {errors.walletAddress?.message} </p>
            </div>

                    <div className={classes.formGroup}>
                        <label htmlFor="telegramUsername" className={classes.label}>
                            Telegram Username
                        </label>
                        <input type="text"
                            {...register('telegramUsername')}
                            className={classNames(classes.inputField, `${errors.telegramUsername ? classes.errorInput : ''}`)}
                            name="telegramUsername"
                        />
                        <p className={classes.errorText}> {errors.telegramUsername?.message} </p>
                    </div>

                    <div className={classes.formGroup}>
                        <label htmlFor="amount" className={classes.label}>
                            Amount to be Invested (in USD)
                        </label>
                        <input type="text"
                            {...register('amount')}
                            className={classNames(classes.inputField, `${errors.amount ? classes.errorInput : ''}`)}
                            name="amount"
                        />
                        <p className={classes.errorText}> {errors.amount?.message} </p>
                    </div>

                    <div className={classes.consentBlock}>
                        <p className={classes.consentText}>
                            Do you accept the <a onClick={() => setOpenDialog(true)} className={classes.consentLink}>terms and condition?</a>
                        </p>
                        <div className={classes.controlGroup}>
                            <input type="checkbox"
                                className={classes.checkBox}
                                name="acceptTerms" id="acceptTerms"
                                ref = {TandCRef}
                                checked = {hasAcceptedTandC}
                                onChange = {handleTandCChange}
                            />
                            <label htmlFor="acceptTerms">Yes, I accept the terms of service.</label>
                        </div>
                        <p className={classes.errorText}> {TandCErrMsg} </p>
                    </div>

                    <div className={classes.consentBlock}>
                        <p className={classes.consentText}>
                            {/* Do you agree to receive updates from The {projectName}? */}
                            Do you agree to receive marketing/informational updates?
                        </p>
                        <div className={classes.controlGroup}>
                            <input type="checkbox" className={classes.checkBox} name="update" id="update" />
                            <label htmlFor="update">Yes, I accept to receive updates.</label>
                        </div>
                    </div>
                    <button className={classes.btn} type="submit" disabled = {isLoading}>{isLoading ? <CircularProgress />  : "Join whitelist"}</button>
                    <ProjectDataTermsAndCondition
                        setOpenDialog={setOpenDialog}
                        openDialog={openDialog}
                    />
                </form>
            </div>
        </div>
    );
}

export default ProjectWhitelistForm;