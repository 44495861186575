import { useEffect, useState } from 'react';
import { makeStyles, useTheme, useMediaQuery, ButtonBase  } from '@material-ui/core';
import { AppStyleProps } from '../../../utils';
import AppBar from "@material-ui/core/AppBar";
import DrawerComponent from './DrawerComponent/DrawerComponent';
import {DesktopView} from './DasktopView/DesktopView';


const style = makeStyles(({colors, breakpoints} : AppStyleProps) => ({
  root : {
    backgroundColor: "#000C15",
    paddingLeft: "4rem",
    paddingRight: "4rem",
    height: "5rem",
    position:"fixed",
    "@media (max-width: 900px)": {
      paddingLeft: "1rem",
      paddingRight: "1rem",
    }
  },
 
}))

export default function Header () {
  const classes = style(),
  theme = useTheme(),

  [state, setState] = useState<any>({
    mobileView: false,
});


  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState: any) => ({ ...prevState, mobileView: true }))
        : setState((prevState: any) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);


  return (
    <AppBar position="static" elevation={0} className = {classes.root}>
        
        { state.mobileView ? <DrawerComponent/>  : <DesktopView/> }
        
    </AppBar>
  )
}
export { Header };