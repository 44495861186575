import { MouseEventHandler } from 'react'
import { Dialog, IconButton, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { Close } from '@material-ui/icons';
import { AppStyleProps, Nullible } from '../../utils';
const styles = makeStyles(({colors} : AppStyleProps) => ({
    dialogPaper : {
        background : colors.pageBackground,
        border: "1px solid" + colors.borderOutline,
        padding : "1rem",
        minWidth : 300,
    },
    additionalIcon: {
        position : "fixed",
        top : 25,
        right : 60,
        zIndex : 1100,
        color : colors.text,
        borderRadius : 5,
        padding : 5,
    },
    close : {
        position : "fixed",
        top : 20,
        right : 20,
        zIndex : 1100,
        color : colors.text,
        border : `solid 1px ${colors.text}`,
        borderRadius : 5,
        padding : 5,
    },
    _close : {
        position : "absolute"
    },
    icon : {
        width : 10,
        height : 10
    }
    
}))
type CustomDialogType = Nullible<{
    children : JSX.Element | boolean,
    additionalIcon : JSX.Element
    open : boolean, 
    within : boolean,
    close : MouseEventHandler<HTMLAnchorElement>
}>
export const CustomDialog = function({
    children, additionalIcon, open, close, within=true
} : Partial<CustomDialogType>) {
    const classes = styles();
    return (
        <Dialog classes={{
            paper : classNames("animated", "zoomIn", classes.dialogPaper)
        }} open={Boolean(open)}>
            {additionalIcon && <IconButton 
                className={classNames(classes.icon, classes.additionalIcon, within ? classes._close : "")} 
                href=""
                >
                {additionalIcon}
            </IconButton>}
            {close && <IconButton 
                className={classNames(classes.close, within ? classes._close : "")} 
                onClick={close}
                href=""
                >
                <Close className={classes.icon}/>
            </IconButton>}
            {Boolean(children) ? children : null}
        </Dialog>
    )
}
export { CustomDialog as default};