import React from 'react';
import { AppRoutes } from '.';
import { launchPadRouteNames } from "./routeNames"

const LaunchPad = React.lazy(() => import("../components/LaunchPadLandingPage/LaunchPadLandingPage"))
const LaunchPadProject = React.lazy(() => import("../components/LaunchPadLandingPage/LaunchPadProject/LaunchPadProject"))
const ProjectWhitelist = React.lazy(() => import("../components/ProjectWhitelist/ProjectWhitelist"))
const ProjectResearch = React.lazy(() => import("../components/ProjectResearch/ProjectResearch"))
const ProjectHome = React.lazy(() => import("../components/Projects/Projects"))

export const lunchPadRoutes : AppRoutes[] = [
    { path : launchPadRouteNames.launchPad, exact : true, component : LaunchPad},
    { path : launchPadRouteNames.buyRGP, exact : true, component : LaunchPadProject},
    { path : launchPadRouteNames.projectWhitelist, exact : true, component : ProjectWhitelist},
    { path : launchPadRouteNames.projectResearch, exact : true, component : ProjectResearch},
]