
export function FakeData() {

    return {
        project: {
           names: [
               "Active Achievement",
               "Excel and Elevate Training",
               "A Salute to New Workers",
               "Match of Health",
               "Annual Award Show",
               "7th Annual Workshop",
               "The Domain of Work",
               "The Successful Project",
               "Fast & Creative Gang",
               "The Coding Master",
               "The Art of Codes",
               "Charged by Codes",
               "The Experienced dude",
               "Box of Crayons",
               "Center for Creative Coding",
               "Enter Coding",
               "Fast Coding",
               "The Coding Expert",
               "The Guy with Codes",
               "Code Change Group",
               "Coding League",
               "Linkage, Inc.",
               "Switch and Swift",
               "The Principal of Change",
               "Mo-Money Masterclass",
               "Impact Training",
               "Excalibur Training",
               "Mentee to Mentor",
            ],
            pool: [{name: "BNB", icon: "/assets/BNG.png"}, {name: "ETH", icon: "/assets/ETH-ICON.png"}],
            type: ["finished","ongoing"],
            joinType: ["whitelist","tokensale"],
            description: [
                "Curabitur scelerisque mattis mattis. Pellentesque suscipit, lacus eget feugiat blandit, felis est aliquam urna, in vehicula arcu ligula a felis. Praesent a nisi id arcu imperdiet bibendum. Nulla sed pretium mi. Phasellus orci elit, consectetur vitae accumsan eget, eleifend id diam. Mauris dignissim dui volutpat, malesuada lorem a, iaculis odio. Donec condimentum, sapien a scelerisque vehicula, magna augue luctus felis, sit amet porttitor odio risus quis purus. Phasellus placerat finibus mollis. Curabitur ac orci nibh. In sit amet nibh vitae tortor scelerisque blandit in ac sapien. Pellentesque eget eleifend sapien.",
                "Morbi a faucibus felis. Nam vitae odio in nisl eleifend semper. Sed tristique sapien at accumsan varius. Donec ut nisl justo. Proin pulvinar non nulla non viverra. Suspendisse eros erat, porta sed leo eleifend, tempus consectetur sem. Suspendisse imperdiet tortor sed metus lobortis aliquam. Donec eget nunc vel quam mollis varius sed a velit. Suspendisse sed sem urna. Praesent tempor nisi vel nibh ultricies, nec cursus enim porttitor. Cras porta sed velit et congue. Nam id sapien ac eros dapibus accumsan. Phasellus scelerisque, ante in fringilla elementum, tortor nisi pharetra magna, quis convallis enim ligula ut dolor. Duis blandit in dolor et euismod. Quisque quis feugiat massa. Vivamus non egestas nibh.",
                "Morbi dapibus, ex in auctor ultrices, leo nisl blandit nisi, id ullamcorper felis nibh at urna. Nam laoreet consequat lacus ac congue. Vestibulum fermentum libero eu varius consectetur. Sed ultrices arcu vitae sagittis facilisis. Praesent ultrices tempus felis, quis cursus magna venenatis at. Duis fringilla, quam vel tempus pulvinar, urna orci volutpat tortor, non consequat ante est placerat orci. Pellentesque blandit nulla quis purus vestibulum porta vitae ut quam. Nullam pretium elementum urna. Fusce eget luctus tellus. Integer posuere sit amet arcu sed congue. Curabitur eu mi eu urna vulputate tincidunt quis sed mi. Maecenas egestas efficitur augue sit amet varius. Ut malesuada varius bibendum. Aenean quis interdum ligula, eget ultrices tortor. Integer vehicula, dui a varius ultricies, tellus neque convallis tortor, ac rutrum purus odio at ligula. Mauris quis fermentum orci.",
                "Sed in orci convallis, elementum justo eget, vestibulum nisi. Maecenas risus elit, vestibulum nec nisl eu, iaculis dictum nisi. Aenean quis condimentum nisl. Praesent ac luctus nibh, in molestie risus. Vivamus libero libero, volutpat a orci nec, condimentum posuere enim. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Nulla pharetra purus eu ex fermentum porta. Mauris sed consectetur tellus. Nunc tempor, lacus sed tempor sollicitudin, leo eros imperdiet ante, sed tincidunt sapien lacus a mauris. Aliquam hendrerit ex non dui ornare egestas sit amet at purus. Nunc commodo orci interdum risus efficitur, ut vehicula nunc aliquet. Sed efficitur dui quis scelerisque malesuada.",
                "Praesent non suscipit enim. Vestibulum dictum est sit amet erat dapibus, sit amet fringilla lorem imperdiet. Fusce sed quam vitae massa tempor pellentesque. Donec lorem ex, pulvinar vitae ultrices quis, pulvinar a ante. Morbi a urna ipsum. Phasellus id malesuada mi. Etiam interdum mi sed lectus sollicitudin, quis imperdiet nibh tincidunt. Praesent rhoncus arcu sed nulla viverra, vel blandit nibh dapibus. Duis vel sem arcu.",
                "In ut semper tortor. Nam rutrum augue at odio accumsan, vehicula vehicula tellus ultrices. Maecenas vestibulum, mauris quis lobortis congue, quam est consequat enim, ut aliquet velit eros at nisl. Praesent bibendum gravida erat sed vehicula. Curabitur dignissim auctor tortor id congue. In risus nibh, dignissim eget vestibulum vel, sagittis efficitur diam. Nunc sit amet dui sit amet erat maximus maximus non eu ex. Mauris vitae sem id ex iaculis lacinia. Maecenas nibh nisl, vestibulum condimentum mattis a, interdum eu enim. Donec vitae diam est. Aenean iaculis turpis ut dui consequat semper. Aenean in condimentum magna, in ultricies diam",
                "Curabitur tristique leo a nunc bibendum, eu pretium nunc tempus. Ut ultrices dui vel nibh ornare blandit. Mauris nec massa placerat, ullamcorper ipsum et, feugiat erat. Sed ligula justo, scelerisque vulputate ex vitae, vulputate elementum dui. Etiam eget libero enim. Vestibulum semper nunc id aliquet volutpat. Curabitur nec quam orci. Quisque erat odio, placerat nec nunc sed, congue mollis mi. Nam eu felis venenatis ex ornare consectetur.",
                "Aliquam elementum sapien turpis. Vestibulum non tempus leo, et dictum massa. Proin a aliquam ligula. Ut tempor facilisis fermentum. Nam eget hendrerit tellus. Sed accumsan nisi sit amet auctor consequat. Donec pellentesque malesuada lacus. Suspendisse quis dictum lectus, at elementum turpis. Vivamus pharetra, lacus non consequat semper, mauris ex scelerisque odio, eget maximus diam arcu in felis. Ut tempus, libero sed lacinia pulvinar, orci neque tincidunt sapien, vel mollis ligula odio at magna. Nulla facilisi. Quisque congue at mi elementum consectetur.",
                "Morbi sed dignissim ex, eu pretium mi. Quisque vitae ex et ex convallis molestie. Suspendisse accumsan erat non ornare varius. Donec condimentum mauris condimentum sapien iaculis, nec pharetra ligula commodo. Aenean aliquam erat sed turpis sodales aliquam. Pellentesque quis justo erat. Ut leo nulla, suscipit vel dui et, sodales tincidunt dolor. Integer ultrices magna in auctor placerat. Aliquam tempor augue sit amet velit tristique aliquam. Duis a velit ultricies, maximus sapien eu, ornare urna. Nullam urna sapien, consequat eget felis at, pulvinar fringilla nisi. In fermentum sapien eget ultricies facilisis. Donec vel interdum enim. Praesent ac orci in ligula imperdiet molestie.",
                "Nullam tincidunt, turpis at cursus facilisis, justo mi elementum leo, nec aliquet odio mi at dui. Nulla commodo nibh nec faucibus ultricies. Suspendisse porttitor ac nibh id bibendum. In eu dignissim augue. Aenean dapibus luctus sem, vitae tempus neque sollicitudin non. Mauris ut lacus nulla. Etiam dignissim vitae turpis id dignissim.",
                "In condimentum rhoncus metus at suscipit. Aliquam id neque magna. Suspendisse id vehicula lacus. Vivamus justo erat, sodales ut volutpat ut, aliquam nec lectus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Vivamus volutpat, nunc in pretium ultrices, risus diam rutrum diam, rhoncus fermentum tortor enim nec mi. Aenean in viverra odio, eu tincidunt lacus. Aliquam eu elit in nunc varius euismod sit amet in justo. Maecenas tempus velit nec tempor dictum. Curabitur congue sem non enim ullamcorper tempor. Mauris neque quam, euismod nec auctor sed, finibus ut lacus. Donec euismod mollis urna vel mollis. Quisque vehicula lacus ut est fringilla, in tempus ante blandit. Cras efficitur placerat elit eget luctus. Nulla non laoreet nisi. Mauris vestibulum, eros et lobortis volutpat, felis leo pulvinar dolor, id mattis quam diam quis est.",
                "Donec fringilla finibus nulla, vitae semper sapien aliquet ac. Mauris molestie ipsum sed porta aliquam. Suspendisse ut ante id ex egestas vulputate. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vivamus eu massa magna. Suspendisse dictum sem vitae porta sagittis. Sed a tincidunt lacus, in venenatis purus. Duis tellus purus, mollis id sem vitae, faucibus luctus mi. Nam ligula nulla, dignissim vitae sapien et, maximus pretium ex. Aenean ex lacus, vestibulum ut tortor eget, rhoncus dictum elit. Nam viverra magna sem, non convallis enim laoreet id. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Cras sed ante lorem.",
                "Curabitur tristique blandit lobortis. Donec facilisis mauris id neque sodales euismod. Mauris dignissim massa id est consectetur porttitor. Phasellus purus tortor, sodales vel elit vitae, hendrerit hendrerit lectus. Maecenas orci mauris, imperdiet id dolor et, elementum interdum est. Mauris imperdiet iaculis tortor ac bibendum. In vulputate ultrices vehicula. Nam quis nunc blandit, fermentum libero et, lobortis nulla. Nam maximus dolor ac diam ullamcorper porttitor. Nam cursus at nunc id convallis. Duis rhoncus laoreet pharetra. Duis faucibus leo eu lacus varius imperdiet. Aenean viverra faucibus nisi, id bibendum leo luctus vel.",
                "Donec id magna luctus purus tincidunt suscipit. Nulla facilisi. Pellentesque vehicula arcu vel risus euismod porttitor quis sit amet turpis. Sed posuere urna et erat varius, a fermentum ante fermentum. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. In hac habitasse platea dictumst. Integer hendrerit volutpat elit nec congue. Ut id velit sed odio laoreet sagittis ut vitae tellus. In hac habitasse platea dictumst. Donec congue sagittis turpis, id maximus justo ultricies egestas. Sed id risus malesuada, placerat dui id, facilisis est. Aenean odio orci, facilisis vel massa accumsan, lobortis volutpat libero. Fusce posuere sollicitudin faucibus. In hac habitasse platea dictumst.",
                "Morbi ac dolor sed lorem facilisis varius. Nam ut nunc nec ipsum commodo consequat id nec felis. Morbi aliquam, lacus et dignissim dapibus, mi ante pulvinar dui, sit amet commodo ligula erat eget elit. Fusce sed tincidunt lacus. Etiam est tortor, lobortis in mauris nec, tincidunt tempor ex. Duis sed tristique elit. Fusce vestibulum massa dictum nibh cursus gravida. Suspendisse potenti. Praesent venenatis ligula mattis ex ultricies rhoncus. Fusce ac est ante. Pellentesque non diam euismod tellus ultrices volutpat. Aenean et leo tortor. Aenean consequat mauris id molestie tempus. Nunc feugiat id dui accumsan varius.",
                "Proin tristique volutpat nunc, ut ultricies nisi porta vitae. Curabitur pretium sapien sed nisi eleifend, quis feugiat libero porta. Morbi sollicitudin, neque a rutrum ornare, neque tellus sagittis mi, at fringilla arcu leo eget felis. Phasellus et odio cursus, cursus sapien in, blandit ligula. Suspendisse potenti. Nam et eros interdum tellus maximus iaculis. Integer interdum ligula in mi lacinia, id tempor mauris egestas. Vivamus commodo ultrices nisl in porta. Mauris aliquet et ex eget porta. Curabitur et efficitur erat. Fusce venenatis sagittis tincidunt. Sed vulputate sem in eros fermentum scelerisque.",
                "Pellentesque accumsan pellentesque velit. Phasellus ut vestibulum augue, ut imperdiet neque. Aenean ullamcorper aliquet mi, vel semper urna vehicula a. Nam ornare, leo non pretium sagittis, elit tortor hendrerit arcu, at ornare augue dui ac metus. Proin auctor vel urna tristique porta. Donec efficitur tempus erat ac pharetra. Mauris ac eros tellus. Suspendisse hendrerit dignissim risus et gravida. Nullam pellentesque nunc vitae purus blandit ultricies in a nisl. Aenean congue lectus ut lorem porta bibendum. Pellentesque porta sollicitudin euismod. Ut gravida turpis at quam faucibus dictum. Vestibulum eget laoreet justo.",
                "Cras vitae imperdiet mi. In malesuada condimentum vehicula. Cras nec egestas erat, sit amet vulputate metus. Ut bibendum, libero at vehicula facilisis, nibh libero venenatis metus, et consectetur eros lectus nec dui. Suspendisse consectetur, leo a tristique placerat, mauris libero iaculis risus, vel pulvinar enim libero vitae risus. Nulla sed mi ac lacus molestie lacinia. Integer scelerisque sagittis purus a dictum. Curabitur rutrum leo sit amet erat bibendum hendrerit. Nam efficitur ex ut lobortis feugiat. Donec a porttitor lectus, eget tristique nisi. Nam fermentum suscipit risus, sit amet tristique sem facilisis vel. Suspendisse ac leo fringilla, faucibus massa ac, sodales augue. Quisque et ipsum sed libero auctor tincidunt eget eget tortor. Mauris est ipsum, facilisis sed dictum et, consequat ac magna.",
                "Nunc pulvinar lacinia elit nec mattis. Cras rutrum nisl vel mi gravida faucibus. Fusce pretium, nunc ac porttitor posuere, est neque vulputate tellus, nec tincidunt metus eros vel quam. Phasellus et lectus commodo, rutrum felis vitae, pharetra nibh. Morbi sit amet ligula tortor. In feugiat placerat nunc a aliquam. Sed placerat suscipit metus sed scelerisque. Fusce vulputate ipsum sed orci posuere, ut tincidunt tellus aliquet. Nam porttitor nisl vel dolor maximus luctus. Maecenas sed ipsum libero. Cras dictum, ipsum non interdum imperdiet, lorem risus ultrices urna, nec interdum orci turpis vitae nulla. Interdum et malesuada fames ac ante ipsum primis in faucibus.",
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer viverra nec dolor non vulputate. Fusce luctus nec ante facilisis pellentesque. Morbi quis metus ac ex tristique bibendum at vel dolor. Nullam ut dui dignissim, posuere nisl eget, hendrerit felis. Sed accumsan gravida felis ac fermentum. Nulla vestibulum elit ipsum, ut egestas nunc molestie ut. Etiam nec mattis metus, at tincidunt odio. Vivamus accumsan volutpat diam, vel laoreet ipsum ornare nec.",
            ]
        },
        whiteList: {
            names: [
                "Payton Spence",
                "Neil Cuevas",
                "Marcelo Bell",
                "Aaliyah Stevenson",
                "Marcel Conrad",
                "Ashtyn Holloway",
                "Cornelius Gamble",
                "Jaylen Norton",
                "Keaton Hall",
                "Rafael Santos",
                "Shane Clay",
                "Brett Sanford",
                "Moriah Vance",
                "Nathan Marsh",
                "Sydnee Riddle",
                "Cedric Barnett",
                "Lilly Ferrell",
                "Arjun Chandler",
                "Odin Benson",
                "Hayden Stephenson",
                "Karter Yu",
                "Sanaa Martinez",
                "Peyton Mora",
                "Yael Archer",
                "Ivy Randall",
                "Parker Todd",
                "Bethany Gonzales",
                "Gerald Anthony",
                "Jovani Tapia",
                "Jaida Becker",
                "Haleigh Elliott",
                "Israel Aguilar",
            ],
            emails: [
                "noahb@gmail.com",
               "british@aol.com",
                "afeldspar@aol.com",
                "arachne@msn.com",
                "jyoliver@yahoo.ca",
                "kuparine@yahoo.com",
                "oechslin@msn.com",
                "gumpish@optonline.net",
                "gavinls@icloud.com",
                "jramio@yahoo.ca",
                "sekiya@sbcglobal.net",
                "laird@att.net",
                "hager@aol.com",
                "zyghom@yahoo.ca",
                "pkilab@live.com",
                "oechslin@aol.com",
                "sthomas@sbcglobal.net",
                "jdhildeb@msn.com",
                "mastinfo@msn.com",
                "joelw@outlook.com",
                "mglee@me.com",
                "slaff@icloud.com",
                "gavollink@live.com",
                "tsuruta@msn.com",
                "devphil@verizon.net",
                "luvirini@att.net",
                "tokuhirom@gmail.com",
                "oracle@outlook.com",
                "xtang@att.net",
                "weidai@gmail.com",
            ],
        },
    };
}
