import React, {useEffect} from 'react'
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useGaEvents, LaunchPadStyleProps } from '../../../../utils';
import LaunchPadProject from '../LaunchPadProject';

const style = makeStyles((theme: LaunchPadStyleProps) => ({
  projectsContainer: {
    display: "flex",
    justifyContent: "space-between",
    //marginTop: "6rem",
    [theme.breakpoints.down(1690)]: {
      flexWrap: "wrap",
      //marginTop: "3rem",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      maxWidth: "100%"
    },

  },
  innerGrid:{
    [theme.breakpoints.up('sm')]:{
      maxWidth: "100%"
    }
  },
  gridItem: {
    display: "flex",
    justifyContent: "center",
  }

}))

const FinishedProjects = ({ items }: any) => {
  const classes = style();
  const GaTrackEvents = useGaEvents();

  useEffect(() =>
  {
    GaTrackEvents("View Project Page","finished_project_tab", "User viewed Finished project");
  },[]);

  return (


    <div className={classes.projectsContainer}>
      <Grid container spacing={4}>
        {items && items.map((item: any, index: number) => {

          const pool = item && item.pool.map((item: any) => item.name)
          const img = item && item.pool.map((item: any) => item.icon)
          return <Grid key={index} item xs={12} sm={6} md={4} lg={4} xl={4} className={classes.innerGrid}>
            <LaunchPadProject 
              key={index}
              research={item.research}
              projectIndex={index}
              metadata={item.metadata}
              action={item.action}
              addresses={item.addresses}
              projectID={item.projectID}
              projectName={item.projectName}
              icon={img}
              projectPicture={item.projectPicture}
              totalRaised={item.totalRaised}
              personalAllocation={item.personalAllocation}
              state={item.state}
              sheetName={item.sheetName}
              chain={item.chain}
              IDODate={item.IDODate}
              pool={pool}
            />
          </Grid>
        })}
      </Grid>
    </div>
  )
}

export default FinishedProjects
export { FinishedProjects }
