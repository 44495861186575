import { makeStyles } from '@material-ui/styles';
import { AppStyleProps, LaunchPadStyleProps} from '../../../utils'
import { Grid } from '@material-ui/core';
import LaunchPadBackersItem from "./LaunchPadBackersItem/LaunchPadBackersItem";


const style = makeStyles((theme : LaunchPadStyleProps) => ({
    root: {
        padding: "5rem",
        //marginBottom: "0rem",
        [theme.breakpoints.down('sm')]: {
            textAlign: "center",
            padding: "1.5rem",
        },
    },
    heading: {
        fontfamily: "Clash Display",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "48px",
        color: theme.colors.textDefault,
        marginBottom: "1rem",
        //[theme.breakpoints.down('xl')]: {
        //    fontSize: "48px"
        //},
        [theme.breakpoints.down('sm')]: {
            fontSize: "40px"
        },
    },
    subheading: {
        fontFamily: "DM Sans",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "18px",
        lineHeight: "28px",
        marginBottom: "5rem",
        color: theme.colors.subHeadingDefault, 
    },
    dataContainer: {
        display: "flex",
        justifyContent: "space-between",
        //marginTop: "6rem",
        [theme.breakpoints.down(1690)]: {
            flexWrap: "wrap",
            //marginTop: "3rem"
        },
        [theme.breakpoints.down("sm")]: {
            justifyContent: "center",
        },
    }
}))


export default function LaunchPadBackers() {

    const classes = style();

    const data = [
        {dataRole: "(Blockchain R&D + DeFi Tokenomics)", dataContent: "A self-styled ‘Social Engineer and PR Renegade’, Ismail Malik has been recognized as one of the top-100 most influential people in blockchain by rise.global. Having raised a total of over $208M, he has expertise in the blockchain-space, and regularly speaks at major blockchain events all around the globe.", dataHead: "Ismail Malik", dataPicture: "BACKER1",dataImg:"l"},
        {dataRole: "(Entrepreneur / CEO)", dataContent: "Entrepreneur, investor, CEO, top advisor, and blockchain expert, Mr. Lalit ranks in the top 30 ICO consultants/advisors on ICO bench. He has raised a total of $90M+ and has a proven track-record in the blockchain space, thanks to his extensive experience in ICO marketing, PR and growth hacking.", dataHead: "Ialit Bansal", dataPicture: "BACKER2",dataImg:"l"},
        {dataRole: "", dataContent: "DeFi News provides fresh insights & latest updates from decentralized finance ecosystems. They manage other notable projects like Smart Liquidity that guarantee the highest outreach for our notable updates.", dataHead: "Defi News", dataPicture: "BACKER3",dataImg:"l"},
        {dataRole: "", dataContent: "CertiK is a pioneer in blockchain security, utilizing best-in-class AI technology to secure and monitor blockchain protocols and smart contracts. Rigel Protocol boasts a highly competitive Certik Security Score of 92.", dataHead: "Certik", dataPicture: "BACKER4",dataImg:"m"},
        {dataRole: "", dataContent: "Founded in 2020, BSCNews is a leading media platform covering DeFi projects on the Binance Smart Chain (BSC). With Rigel Protocol, they aim to inform, educate and share all the latest innovations happening with the global blockchain community.", dataHead: "BSC News", dataPicture: "BACKER5",dataImg:"m"},
        {dataRole: "", dataContent: "Unicrypt gives customers decentralized control over their funds in a self-custody fashion. Both, Unicrypt and Rigel Protocol share similar objectives: to bring value to the DeFi space as a whole by delivering disruptive, flexible and audited technology.", dataHead: "Unicrypt", dataPicture: "BACKER6",dataImg:"m"},
        {dataRole: "", dataContent: "BitForex provides safe asset security management solutions to all their users. With no gas fees and low slippage, they were sure to be included as partners as our ecosystem of dApps focusing on a similar goal - to provide the lowest txn. fees and the best UX.", dataHead: "Bitforex", dataPicture: "BACKER7",dataImg:"m"},
    ];

    return(
        <section className = {classes.root}>
            <h2 className = {classes.heading}>
                Backers & Advisors
            </h2>
            <p className = {classes.subheading}>Our trusted backers & advisors are shown below.</p>
            <div className = {classes.dataContainer}>
                <Grid container spacing={2}>
                    {data && data.map((item, index) => {
                        const { dataHead, dataContent, dataPicture, dataRole,dataImg} = item;
                        return <Grid key={index} item xs={12} sm={6} md={4} lg={4} xl={4} >
                            <LaunchPadBackersItem key = {index} dataHead = {dataHead} dataRole={dataRole} dataContent = {dataContent} dataPicture = {dataPicture} dataImg = {dataImg} />
                        </Grid>
                    })}
                </Grid>
            </div>
        </section>
    )

}