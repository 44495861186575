import {StakeAction, StakeState } from "./types";

export const stakeConstant = {
    SHOW_STAKE_INPUT_MODAL: "SHOW_STAKE_INPUT_MODAL",
    HIDE_STAKE_INPUT_MODAL: "HIDE_STAKE_INPUT_MODAL",
}


export const stakeReducer = (
    state={} as Partial<StakeState>,
    action : StakeAction
) : StakeState => {
    const {type, payload} = action;
    switch(type){
        case stakeConstant.SHOW_STAKE_INPUT_MODAL : 
            return {...state, ...payload};
        case stakeConstant.HIDE_STAKE_INPUT_MODAL : 
        return {...state, ...payload};
        default : return {...state};
    }
}