import { makeStyles, ButtonBase } from '@material-ui/core'
import { launchPadRouteNames, LaunchPadStyleProps } from '../../../utils'
import { Language, Description } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTelegram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { useGaEvents } from '../../../../../helpers';
import { useHistory } from 'react-router-dom';
import { MouseEventHandler, useCallback } from 'react';

const style = makeStyles((theme: LaunchPadStyleProps) => ({
    root: {
        width: "80%",
        boxSizing: "border-box",
        margin: "2rem auto",
        [theme.breakpoints.down('sm')]: {
            width: "85%",
        },

    },
    projectContainer: {

        [theme.breakpoints.up(1000)]: {
            display: "flex",
            // justifyContent: "space-between",
            alignItems: "center"
        },
    },
    projectPictureContainer: {
        height: "18rem",
        marginRight: "2rem",
        [theme.breakpoints.down(1000)]: {
            marginRight: 0,
            marginBottom: "2rem",
            width: "100%",
        },
        "& div": {
            width: "50%"
        }
    },
    projectPicture: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
    },
    projectDetails: {
        display: "flex",
        justifyContent: "space-between"
    },
    heading: {
        marginBottom: "1.5rem",
    },
    projectName: {
        fontFamily: 'Clash Display',
        fontSize: "2rem",
        marginBottom: ".5rem",
        [theme.breakpoints.down(350)]: {
            fontSize: "1.4rem"
        },
    },
    token: {
        fontSize: "1.4rem",
        fontFamily: "Clash Display",
        [theme.breakpoints.down(350)]: {
            fontSize: "1rem"
        },
    },
    projectBody: {
        fontSize: "1.1rem",
        [theme.breakpoints.down(1150)]: {
            fontSize: ".9rem"
        },
        [theme.breakpoints.down(350)]: {
            fontSize: ".7rem"
        },
    },
    item: {
        marginTop: "1rem",
        display: "flex",
        justifyContent: "space-between"
    },
    key: {

    },
    seperator: {
        width: "auto",
        flex: 1,
        margin: "0 1rem",
        borderBottom: "3px dotted #fff",
    },
    value: {

    },
    projectLinks: {
        marginTop: "2rem",
    },
    journeyBtn: {
        backgroundImage: " linear-gradient(90deg, #2691C1 0%, #045CA6 42.71%, #055DA6 60.42%, #238CBE 100%)",
        boxShadow: "0px 1px 5px rgba(51, 51, 153, 0.1)",
        color: theme.colors.textDefault,
        fontSize: "16px",
        width: "196px",
        height: "56px",
        fonWeight: "700",
        padding: "1.5rem 5rem",
        borderRadius: "6px",
        outline: "none",
        marginTop: "45px",
        [theme.breakpoints.down('xl')]: {
            fontSize: "16px",
            padding: ".7rem 1.5rem",
            borderRadius: ".5rem",
        }
    },
    link: {
        textDecoration: "none",
        color: theme.colors.text,
        whiteSpace: "nowrap",
        margin: "0 2rem",
        "&:first-of-type": {
            marginLeft: 0,
        },
        [theme.breakpoints.down('sm')]: {
            margin: 0,
            marginRight: "1rem",
        },
    },
    icon: {
        fontSize: "2rem",
        verticalAlign: "middle",
        [theme.breakpoints.down('sm')]: {
            fontSize: "1rem",
        }
    },
    projectWrapper: {
        width: "50%"
    }
}))

type propsTypes = {
    projectIndex : number, 
    projectName: string,
    metadata : any,
    projectPicture: string,
    totalRaised: string,
    personalAllocation: string,
    tokenAllocation: string,
    IDODate: string,
    projectID: string,
    research : any,
    pool: [{
        name: string,
        icon: string,
        allocation: string,
        number: string
    }],
    listingPrice: string,
    contacts: any,
    price: string,
    GMT: string,
    list : string[],
    buttonOption : {
        name : string,
        onClick : MouseEventHandler<HTMLButtonElement> | undefined
    },
    backButton : any
}


export default function ProjectData( data : propsTypes) {
    const classes = style(),
        GaTrackEvents = useGaEvents(),
        history = useHistory(),
        { 
            projectName, metadata, pool, 
            projectIndex, projectPicture, list,
            buttonOption, backButton : BackButton
        } = data || {};

    // const project = {projectName: "The Riddle Projects", projectPictureSrc: "project", totalRaised: "$250,000", personalAllocation: "$250 Max", IDODate: "10th November, 2021, 12:00 PM GMT"}
    // const {projectName, projectPictureSrc, totalRaised, personalAllocation, IDODate} = project
    function numberWithCommas(x: any) {
        if(!x) return "";
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const handleClick = () => {
        history.push(launchPadRouteNames.projectResearch_+projectIndex, { data });
    }

    const getMetadata = useCallback((name : string, field : "value" | "label") => {
        if(!metadata || metadata.length === 0) return "";
        const data = metadata.find((item : any)=>item.name === name) as any;
        return data?.[field];
    }, [JSON.stringify(metadata)]);

    const similarData : any = {};
    for (let i = 0; i < list.length; i++) {
        const name = list[i] as string;
        similarData[getMetadata(name, "label")] = getMetadata(name, "value");
    }

    return (
        <div className={classes.root}>
            {BackButton && <BackButton/>}
            <div className={classes.projectContainer}>
                <div className={classes.projectPictureContainer}>
                    <img src={projectPicture} className={classes.projectPicture} alt="project pic" />
                </div>
                <div className={classes.projectWrapper}>
                    <div className={classes.projectDetails}>
                        <div className={classes.heading}>
                            <h3 className={classes.projectName}>{projectName?.split(" ")?.[0]}</h3>
                            <span className={classes.token}>${projectName?.split(" ")?.[0]}</span>
                        </div>

                        {buttonOption && buttonOption.name ? <ButtonBase
                            className={classes.journeyBtn} onClick={buttonOption.onClick}>
                            {buttonOption.name}
                        </ButtonBase> : <ButtonBase
                            className={classes.journeyBtn} onClick={() => handleClick()}>
                            Research / Report
                        </ButtonBase>}
                    </div>
                    <div className={classes.projectBody}>
                        {Object.entries(similarData).map((data : any, index : number) => {
                            const name = data[0],
                            value = data[1];
                            return (
                                <div key={index} className={classes.item}>
                                    <span className={classes.key}>{name}</span>
                                    {/* <span className={classes.seperator}></span> */}
                                    <span className={classes.value}>{value}</span>
                                </div>
                            )
                        })

                        }
                        {/* <div className={classes.item}>
                            <span className={classes.key}>Total Raised</span>
                            <span className={classes.seperator}></span>
                            <span className={classes.value}>{totalRaised}</span>
                        </div> */}
                        {/* <div className={classes.item}>
                            <span className={classes.key}>Personal Allocation</span>
                            <span className={classes.seperator}></span>
                            <span className={classes.value}>{personalAllocation}</span>
                        </div>
                        <div className={classes.item}>
                            <span className={classes.key}>IDO Date</span>
                            <span className={classes.seperator}></span>
                            <span className={classes.value}>{IDODate}</span>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className={classes.projectLinks}>
                <a href={getMetadata("website", "value")} className={classes.link} onClick={() => GaTrackEvents("View Whitelist Registration Page","whitelist_website_social_link_clicked","Whitelist Website Link")}><Language className={classes.icon} /> Website</a>
                <a href={getMetadata("whitepaper", "value")} className={classes.link} onClick={() => GaTrackEvents("View Whitelist Registration Page","whitelist_whitePaper_social_link_clicked","Whitelist White Paper")}><Description className={classes.icon} /> Whitepaper </a>
                <a href={getMetadata("twitter", "value")} className={classes.link} onClick={() => GaTrackEvents("View Whitelist Registration Page","whitelist_twitter_social_link_clicked","Whitelist Twitter Link")}><FontAwesomeIcon className={classes.icon} icon={faTwitter} /> Twitter</a>
                <a href={getMetadata("telegram", "value")} className={classes.link} onClick={() => GaTrackEvents("View Whitelist Registration Page","whitelist_telegram_social_link_clicked","Whitelist Telegram Link")}><FontAwesomeIcon className={classes.icon} icon={faTelegram} /> Telegram</a>
            </div>
        </div>
    );
}
export { ProjectData }