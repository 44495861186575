import { makeStyles } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { AppStyleProps, appUtils, launchPadRouteNames } from '../../utils'
import ProjectData from './ProjectData/ProjectData'
import ProjectWhitelistForm from './ProjectWhitelistForm/ProjectWhitelistForm'
import { errorToast, CustomSnackbarDispatch } from "../../includes";
import { useDispatch } from "react-redux";


const style = makeStyles((theme: AppStyleProps) => ({
    root: {
        backgroundColor: "#000c15",
        color: "#fff",
    },
    header: {
        width: "100%",
        position: "fixed",
        top: 0,
        zIndex: 100,
        backgroundColor: "#000c15",
        paddingLeft: "5rem",
        borderBottom: "1px solid #002C4D",
        [theme.breakpoints.down('sm')]: {
            paddingLeft: "1rem",
        },
    },
    headerText: {
        fontSize: "1.5rem",
        [theme.breakpoints.down(350)]: {
            fontSize: "1.2rem"
        },
    }
}))
type ProjectDetailsState = Partial<{
    project : any
}>
export default function ProjectDetails({ match, history }: any) {
    const classes = style(),
        { location : { state : _s } }  = history,
        { data } = _s || {},
        { params : { id : projectIndex }} = match,
        [state, _setState] = useState<ProjectDetailsState>({
            project : data
        }),
        {project} = state,
        setState = (_state : ProjectDetailsState) => _setState(state=>({...state, ..._state})),
        dispatch = useDispatch<CustomSnackbarDispatch>();
    useEffect(() => {
        if(!projectIndex){
            errorToast("Url mismatch!")(dispatch)
            return history.replace(launchPadRouteNames.launchPad)
        }
        (async () => {
            if(!data){
                const project = await appUtils.projectDataByIndex("ongoing", projectIndex);
                setState({project})
            }
        })();
    }, [JSON.stringify(data)])
    useEffect(() => {
        document.getElementsByTagName("html")[0].setAttribute("style", "background-color:#000c15");
    }, [])



    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <h1 className={classes.headerText}>
                    WhiteList Application Form
                </h1>
            </div>
            {project && <ProjectData
                projectIndex={projectIndex}
                list={[
                    "totalTokenAllocation", "busdAllocation", "usdtAllocation",
                    "acceptedCurrencies", "price", "listPrice", "minSlot", "maxSlot"
                ]}
                {...project}
                
            />}
            {project && <ProjectWhitelistForm
                whitlistSheetName={project?.sheetName}
                projectName={project?.projectName}
            />}
        </div>
    );
}