import { Suspense, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { Footer } from ".";
import { AppRoutes } from "../utils";
//import { makeStyles } from '@material-ui/styles';
import Header from "./structures/Header/Header";
import {CustomPopover, CustomSnackbar, CustomNotifier} from "../includes"
import { makeStyles } from "@material-ui/core";
import { AppStyleProps } from '../utils';

const styles = makeStyles((theme : AppStyleProps) => ({
    loading : {
        minHeight : 400,
        display : "flex",
        alignItems : 'center',
        justifyContent : "center",
        fontWeight : "bold",
        fontSize : 24,
    }
}))

export default function Layout({routes} : {routes : AppRoutes[]}){
    
    const classes = styles();
    
    return (
        <div style={{overflowX: "hidden"}}>
            {/* we cant use react router-dom to determine path here as it is unavailable so we are doing it manually */}
            {/* ensuring the header is rendered only whe we are on landing page */}
            {window.location.href.split("/")[3] === "" && <Header/>}
            <Suspense fallback={<div className={classes.loading}>loading...</div>}>
                <Switch>
                    {routes && routes.map((route : AppRoutes, index)=>{
                        const {component : Component, path, exact} = route;
                        return <Route key={index} exact={exact} path={path} component={(routeProps : any)=>{
                            return <Component {...routeProps}/>
                        }}/>
                    })}
                </Switch>
            </Suspense>
            <Footer/>
            <CustomNotifier />
            <CustomSnackbar />
            <CustomPopover />
            
        </div>
    )
}
