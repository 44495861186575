export const prefix = "app/v1";
const buyRGPRoutePrefix = prefix;
export function buyRGPRouteNames() {
    return {
        projects : `/${prefix}`,
        liquidity: `/${prefix}/liquidity`,
        stake: `/${prefix}/stake/:id`,
        stake_: `/${prefix}/stake/`,
        swap: `/${prefix}/swap/:id`,
        swap_: `/${prefix}/swap/`,
        investment: `/${prefix}/investment`,
        purchased: `/${prefix}/purchased`,
        launchPad: "/",
        nfts: `/${prefix}/nfts`,
        nft: `/${prefix}/nft/:id/:s/:a/:n`,
        mintNft: `/${prefix}/nft/mint`,
        minePad: `/${prefix}/mine`,
        minePadInformation: `/${prefix}/mine/information`
    }
}

export default buyRGPRoutePrefix;
export { buyRGPRoutePrefix }

