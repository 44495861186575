import { makeStyles } from '@material-ui/styles';
import { AppStyleProps, LaunchPadStyleProps } from '../../../../utils'

const style = makeStyles((theme: LaunchPadStyleProps) => ({
    root: {
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down('xl')]: {
            width: "220px",
            height: "128px",
        },
        [theme.breakpoints.down('lg')]: {
            width: "220px",
            height: "128px",
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: "4rem",
            width: "20%",
            maxWidth: "22rem",

        },
    },
    networkLogo: {
        width: "80px",
        height: "80px",
        position: "absolute",
        top: "0",
        [theme.breakpoints.down('sm')]: {
            width: "50px",
            height: "50px",
        },
    },

}))

type NetwotkItemProps = {
    networkName: string,
    logoName: string
}

export default function LaunchPadNetworkItem({ networkName, logoName }: NetwotkItemProps) {
    const classes = style();

    return (
        <div className={classes.root}>
            <img className={classes.networkLogo} src={`./assets/${logoName}.svg`} alt={networkName + " logo"} />
        </div>
    );
}