import BigNumber from "bignumber.js";
import Web3 from 'web3';
import { store } from '..'
export default function tokenContractActions (instance : any){
    const { address } = store.getState().walletReducer,

    decimals = async () : Promise<number> => parseInt(await instance?.methods?.decimals().call()),
    reducerNumber = async (amount : number) : Promise<number> => {
        const _decimals = await decimals();
        return amount / (10 ** _decimals);
    },
    expandNumber = async (amount : number) : Promise<string> => {
        const _decimals : number = await decimals();
        return Number(amount * 10 ** _decimals).toLocaleString().split(",").join("");
        return Web3.utils.toBN(amount * 10 ** _decimals).toString() ///new Number(amount * 10 ** _decimals).toFixed(0);
    }

    return {
        reducerNumber,
        decimals,
        expandNumber,
        name : async () : Promise<string>=> await instance?.methods?.name().call(),
        symbol : async () : Promise<string> => await instance?.methods?.symbol().call(),
        myBalance : async () : Promise<number> => {
            return reducerNumber(await instance?.methods?.balanceOf(address).call())
        },
        balanceOf : async (address : string) : Promise<number> => reducerNumber(await instance?.methods?.balanceOf(address).call()),
        totalSupply : async () : Promise<number> => await instance?.methods?.totalSupply().call(),
        approve : async (recieverAddress : string, value : number) : Promise<any> => {
            return await instance?.methods?.approve(
                recieverAddress, String(await expandNumber(value))
            ).send({from : address})
        },
        transfer : async (recieverAddress : string, value : number) : Promise<any> => {
            return await instance?.methods?.transfer(
                recieverAddress, String(await expandNumber(value))
            ).send({from : address, gasLimit: 500000})
        },
        increaseAllowance : async (spender : string, value : number) : Promise<any> => {
            return instance?.methods?.increaseAllowance(spender, value).send({from : address})
        },
        allowance : async (userAddress: string, contractAddress: any) : Promise<any> => {
            return await instance?.methods?.allowance(userAddress, contractAddress).call();
        },
        buyNFT : async (tokenId: string, tokenAddress: string) : Promise<any> => {
            return await instance?.methods?.buy(tokenId, tokenAddress).send(address)
        },
    };
}
export {tokenContractActions};