import { CustomPopoverAction, CustomPopoverState } from "./types";

export const customPopoverConstant = {
    SET_CUSTOM_POPOVER : "SET_CUSTOM_POPOVER",
    SET_CUSTOM_POPOVER_CLOSE : "SET_CUSTOM_POPOVER_CLOSE",
}
const initialState : CustomPopoverState = {
    anchorOrigin : {
        horizontal : "left",
        vertical : "bottom"
    }
} ;
export const customPopoverReducer = (
    state : CustomPopoverState = initialState,
    action : CustomPopoverAction
) : CustomPopoverState => {
    const {type, payload} = action;
    switch(type){
        case customPopoverConstant.SET_CUSTOM_POPOVER : 
            return {...state, ...payload};
        case customPopoverConstant.SET_CUSTOM_POPOVER_CLOSE : 
            return {...initialState};
        default : return {...state};
    }
}