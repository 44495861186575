import React, { MouseEvent, MouseEventHandler, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ButtonUnstyled, {
  buttonUnstyledClasses,
  ButtonUnstyledProps,
} from '@mui/base/ButtonUnstyled';
import classNames from 'classnames';
import {
  AppStyleProps,
  walletUtil,
  ReduxStoreState, WalletState,
  NETWORKS, AllowedNetworks, WalletAddresses, UtilState,
  utilAction, STORAGE_VARIABLE, AppThemeValues, APP_THEME_TYPES, buyRGPRouteNames, appUtils
} from '../../../utils';
import { CustomDialog} from '../../../includes';
import GiftBoxImg from './../../../../../../assets/giftbox.gif';


const useStyles = makeStyles(({colors, breakpoints, isDark} : AppStyleProps) => ({
  box: {
    display: "flex",
    justifyContent: `center`,
    alignItems: `center`,
    padding: `4px 6px`,
    borderRadius: `6px`,
    maxHeight: "40px",
    background: isDark ? colors.primary : colors.lightBlue,
    border: `1px solid ${colors.borderOutline}`,
  },
  transparent: {
    background: colors.pageBackground,
    border: "0px",
  },
  cursor: {
    cursor: "pointer",
  },
  modalBody: {
    display: "flex",
    flexDirection: "column",
  },
  heading: {
    fontWeight: 700,
    fontSize: "24px",
    color: isDark ? '#F1F5F8' : '#333333',
    lineHeight: "32px",
    marginBottom: "1rem",
    textAlign: "center"
  },
  modalText: {
    fontWeight: 400,
    fontSize: "16px",
    color: isDark ? '#F1F5F8' : '#333333',
    lineHeight: "24px",
    marginBottom: "1rem",
    textAlign: "center"
  },
  refBtn: {
    width:"100%",
    borderRadius: "6px !important",
    textAlign: "center",
    alignItems:"center",
    borderColor: isDark ? '#4A739B !important' : '#DEE6ED !important',
    color: isDark ? '#4CAFFF' : '#319EF6',
    whiteSpace: "nowrap",
    '&:hover': {
      background:'transparent !important',
    },
  }
}));

function ReferralModal({displayRefModal, setDisplayRefModal,text,hostDetail,title}: any){
  const classes = useStyles();
  const themeType = localStorage.getItem(STORAGE_VARIABLE.appTheme) as keyof AppThemeValues;
  const isDark = themeType === APP_THEME_TYPES.dark;
  const walletReducer = useSelector((state : ReduxStoreState) => state.walletReducer);
  const { address, web3, networkId } = walletReducer;
  const [referralCode, setReferralCode] = useState("");
  const [referralLink, setReferralLink] = useState("");
  const hostName = window.location.origin;
  const [URLRefCode, setURLRefCode] = useState("");
  const [tooltipTitle, setTooltipTitle] = useState("Copy");
  const copyToClipBoard = async (text: any) => {
    try {
      await navigator.clipboard.writeText(text);
      setTooltipTitle("Copied");
      setTimeout(() => {setTooltipTitle("Copy")}, 3000);
    } catch (err) {
      console.error(err);
    }
  }
  
  useEffect(() => {
    const handleReferralCode = () => {
      if(!address) return;
      // const refId = appUtils.encryptRef(address);
      const refId = address;
      const refLink = `${hostDetail}?ref=${refId.toString()}`;
      setReferralCode(refId);
      setReferralLink(refLink);
    };
    handleReferralCode();
  }, [address]);
  return (
    <>
    <CustomDialog
      open={displayRefModal}
      close={() => setDisplayRefModal(false)}
    >
      <div>
        <div className={classes.modalBody}>
          <Box
            width="90%"
            margin="0 auto"
            fontSize="14px"
          >
            <Box style={{textAlign: "center", marginTop:"60px", marginBottom: "20px"}}>
              <img width="120px" height="128px" src={GiftBoxImg}/>
            </Box>
            <div className={classes.heading}>{title}</div>
            <div className={classes.modalText}>
             {text}
            </div>
            {address ?
              <Box style={{display: "flex", justifyContent:"center", marginTop: "30px", marginBottom:"15px",}}>
                <Button className={classes.refBtn} style={{border:'1px solid'}}>
                  <div style={{textTransform: "lowercase", fontSize: "16px", fontWeight: "500"}}>{walletUtil.shortenLink(hostDetail)}?ref={walletUtil.shortAddress(String(referralCode))}</div>
                  <Tooltip title={tooltipTitle} style={{backgroundColor: "gray.300", color:"black"}}>
                    <IconButton aria-label="Copy address">
                      <ContentCopyIcon style={{color: isDark ? '#4CAFFF' : '#319EF6'}} onClick={() => copyToClipBoard(`${referralLink}`)}/>
                    </IconButton>
                  </Tooltip>
                </Button>
              </Box>
            :
            <Box style={{display: "flex", justifyContent:"center", marginTop: "30px", marginBottom:"15px",}}>
              <Button className={classes.refBtn} style={{border:'1px solid'}}>
                <div style={{textTransform: "lowercase", fontSize: "16px", fontWeight: "500"}}>Connect your wallet to get a referral link</div>
              </Button>
            </Box>
            }
          </Box>
        </div>
      </div>
    </CustomDialog>
    </>
  );
}
export default ReferralModal;
