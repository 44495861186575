import * as v1Utils from './v1'
import { 
    AppStyleProps as v1AppStyleProps, 
    BuyRGPTypes as v1BuyRGPTypes, Nullible as v1Nullible,
    AppTheme as v1AppTheme
} from './v1/utils'
export {v1Utils};

function getBuyRGPUtils( appVersion : DappVersion ) {
    return {...v1Utils};
}
export type AppTheme = v1AppTheme;
export type AppStyleProps = v1AppStyleProps;
export type BuyRGPTypes = v1BuyRGPTypes;
export type Nullible<P> = v1Nullible<P>; 
export type DappVersion = "v1" | "v2"
export {
    getBuyRGPUtils
}