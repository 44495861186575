import { FakeData } from "./FakeData";

export function FakeFactory () {

    const faker = FakeData();

    const projects = {
        title: () => {
            return faker.project.names[Math.floor(Math.random()*faker.project.names.length)];
        },

        image: () => "https://source.unsplash.com/random/300x200?sig="+(Math.floor(Math.random()*50)),

        totalRaised: () => {
            let r = Math.random(); 
            return Math.floor(r * (1000 - 9999999) + 9999999)
        },
        
        personalAllocation: () => {
            let r = Math.random(); 
            return Math.floor(r * (100 - 9999) + 9999)
        },

        pool: () => {
            return faker.project.pool[Math.floor(Math.random()*faker.project.pool.length)];
        },

        type: () => {
            return faker.project.type[Math.floor(Math.random()*faker.project.type.length)];
        },

        joinType: () => {
            return faker.project.joinType[Math.floor(Math.random()*faker.project.joinType.length)];
        },

        description: () => {
            return faker.project.description[Math.floor(Math.random()*faker.project.description.length)];
        },
    },

    whiteList = {
        names: () => faker.whiteList.names[Math.floor(Math.random()*faker.whiteList.names.length)],
        email: () => faker.whiteList.emails[Math.floor(Math.random()*faker.whiteList.emails.length)],
        username: () => "@"+(Math.random() + 1).toString(36).substring(7),
        walletAddress: () => "0x"+(Math.random() + 1).toString(16).substring(2, 16).toUpperCase(),
    }

    return {
        getProjects: (neededElements: number) => {
            //Get project list
            let data = [];
            for(let i = 0; i < neededElements; i++){
                    data.push({projectName: projects.title(), projectPicture: projects.image(), pool: projects.pool(), type: projects.type(), joinType: projects.joinType(), description: projects.description(), personalAllocation: projects.personalAllocation(), totalRaised: projects.totalRaised()})
            }
            return data;
        },
        getWhitelist: (neededElements: number) => {
            let data = [];
            for(let i = 0; i < neededElements; i++){
                    data.push({fullName: whiteList.names(), email: whiteList.email(), username: whiteList.username(), walletAddress: whiteList.walletAddress()})
            }
            return data;
        },
        
    };
}
