import { makeStyles } from '@material-ui/styles';
import { AppStyleProps, LaunchPadStyleProps} from '../../../../utils'

const style = makeStyles((theme : LaunchPadStyleProps) => ({
    root: {
        position: "relative",
        margin:"30px 0",
        textAlign: "left",
        height: "216px",
        borderRadius: "6px",
        background: theme.colors.FeaturedBackgroud,
        fontSize: "1.2rem",
        paddingBottom : 20,
        [theme.breakpoints.down("md")]: {
            width: "inherit",
            margin: "2rem 1rem 0",
        },
        [theme.breakpoints.down("sm")]: {
            width: "inherit",
        }
        
    },

   

    dataPicture: {
        marginTop:"20px",
        marginLeft:"20px",
        width: "64px",
        height: "64px"
    },
    dataHead: {
        marginTop:"20px",
        marginLeft:"20px",
        fontFamily: "Clash Display",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "24px",
        lineHeight: "22px",
        collor: theme.colors.textDefault,
        margin: 0,
        whiteSpace: "nowrap",
        [theme.breakpoints.down("md")]: {
            fontSize: "18px",
            lineHeight: "20px",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "18px",
            lineHeight: "20px", 
        },
    },
    dataContent: {
        marginTop:"20px",
        marginLeft:"20px",
        marginRight:"20px",
        fontFamily: 'DM Sans, sans-serif',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "24px",
        color: theme.colors.textDefault,
        margin: 0,
        [theme.breakpoints.down("md")]: {
            fontSize: "14px",
            lineHeight: "20px",
            marginTop:"15px",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "14px",
            lineHeight: "20px",
            marginTop:"15px",
        },
    },

}))


type NetwotkCardItemProps = {
    dataHead: string,
    dataContent: string,
    dataPicture: string
}

export default function LaunchPadNetworkCardItem({dataHead, dataContent, dataPicture}: NetwotkCardItemProps) {
    const classes = style();

    return(
        <div className = {classes.root}>
                <img src = {`./assets/${dataPicture}.png`} className = {classes.dataPicture} alt = "project pic" />
                <h4 className = {classes.dataHead}>{dataHead}</h4>
                <p className = {classes.dataContent}>{dataContent}</p>
        </div>
    );
}

