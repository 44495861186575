import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import { CustomSnackbarDispatch, errorToast } from '../../../includes';
import { ReduxStoreState, walletUtil } from '../../../utils';
export default function RouteMonitor ({ history } : any) {
    const { location : { pathname }} = history;
    const dispatch = useDispatch<CustomSnackbarDispatch>();
    const { networkId, address } = useSelector((state : ReduxStoreState) => state.walletReducer)
   /* if(address && walletUtil.MAINNETS.indexOf(networkId) !== -1 &&
    !/\/(nfts|nft\/)/.test(pathname)){
        errorToast("Only NFTs works on mainnet, you will be disconnected for other pages.")(dispatch)
        walletUtil.disconnectUser()
    }
    */
    return null;
}
export { RouteMonitor }