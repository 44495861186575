import { makeStyles } from '@material-ui/core'
import StakeBox from './StakeBox/StakeBox'
import WhitelistBox from './WhitelistBox/WhiteListBox'
import {CustomStepbar, errorToast, CustomSnackbarDispatch} from '../../includes'
import { AppStyleProps, launchPadContract, rgpSpecialPool, ReduxStoreState, walletUtil, tokenAmount, tokenContractActions, buyRGPRouteNames, appUtils, routes} from '../../utils'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { walletActions } from '../structures/Web3Provider/actions'
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useGaEvents } from '../../../../../helpers';
import CustomBackBtn from '../../../../../includes/CustomBackBtn/CustomBackBtn'

const style=makeStyles(({colors, breakpoints, isDark}: AppStyleProps) => ({
    root : {
        minHeight : 100,
        display : "flex",
        flexDirection : "column",
        alignItems : "center",
        justifyContent : "center",
        [breakpoints.down("sm")]: {
          padding: "0 1rem",
        }
    },
    container: {
        width: "30rem",
        margin: "1.5rem auto 0",
    },
    openLaunchPadBtn: {
        width: "100%",
        padding: "1rem",
        fontSize: "1rem",
        lineHeight: "1.5rem",
        borderRadius: ".4rem",
        border: 0,
        boxSizing: "border-box",
        margin: "2rem 0",
    },

    proceedBtnDisabled: {
        backgroundColor: isDark ? colors.disabledBG : colors.gray2,
        color: isDark ? colors.disabledText : colors.gray,
        cursor : "not-allowed"
    },
    proceedBtnEnabled: {
        backgroundColor: colors.textHighlight,
        color: colors.text,
        cursor : "pointer",
    },
    openLaunchPadBtnEnabled: {
        backgroundColor: colors.textHighlight,
        color: colors.text,
    },
    commingSoon: {
        marginTop: "2rem",
        border: `2px solid ${colors.primary}`,
        color: colors.text,
        borderRadius: ".4rem",
        boxSizing: "border-box",
        padding: "2rem 0",
    },
    backToHome: {
        color: '#34A7CB',
        display: 'flex',
        alignItems: 'center',
        textDecoration : "none",
        width : "80%",
        marginBottom : 20,
    },
}));
type CustomStepPositions={
    stake : string,
    whitelist : string
}
type StakePosition=keyof CustomStepPositions | undefined
type StakeState=Partial<{
    position : StakePosition,
    hasStaked : boolean,
    hasWhiteList: boolean,
    contractAddresses : string[],
}>
export default function Stake({ 
    match : { params }, 
    history : { location : { state : _routeState }}
} : any) {
    const routeState : any = _routeState?.data,
    { id } = params || {};
    
    const classes=style(),
        history = useHistory(),
        steps=['RGP Staking', 'Whitelist'],
        [state, _setState]=useState<StakeState>({
            position : "stake",
            hasStaked : false,
            hasWhiteList: false,
            contractAddresses : [],
        }),
        GaTrackEvents = useGaEvents(),
        snackDispatch = useDispatch<CustomSnackbarDispatch>(),
        walletDispatch = useDispatch(),
        {
            position, hasStaked, hasWhiteList,
            contractAddresses
        }=state,
        setState=(_state : StakeState) => _setState(state=>({...state, ..._state})),
        {address, networkId, contracts, web3} = useSelector((state : ReduxStoreState) => state.walletReducer),
        { rgp, specialPool } = contracts || {},
        stepbarConversion=(position : StakePosition) : number => {
            switch(position) {
                case "stake" : return 1;
                case "whitelist" : return 2;
                default : return -1;
            }
        },
        stakeRGP = useCallback((amount : number, onApproval, callback) => {
            if(!address || amount <= 0) return ;
            (async() => {
                try{
                    await rgpSpecialPool().stake(amount, onApproval);
                    checkStake(); 
                    walletActions.payload({
                        walletBalance : tokenAmount(await walletUtil.getBalance()) as string,
                        rgpBalance : tokenAmount(await tokenContractActions(rgp).balanceOf(address)) as string
                    })(walletDispatch)
                    callback && callback();
                    // stake analytics success
                    GaTrackEvents("View Stake Page","staked_rgp_success","Stake Successful");
                }catch(error : any){
                    const message = error?.message as string;
                    errorToast(message)(snackDispatch)
                    // stake analytics failed
                    GaTrackEvents("View Stake Page","staked_rgp_failure","Stake Failure");
                }
            })();
        }, [address, JSON.stringify(walletDispatch)]),
        checkStake = useCallback(() => {
            (async () => {
                if(!address || !specialPool) return;
                let hasStaked = false;
                const stake = await rgpSpecialPool().userInfo();
                const { _amountStaked } = stake || {};
                if(_amountStaked > 0) hasStaked = true;
                setState({hasStaked})
            })();
        }, [address, specialPool]);
        //test deployment
        
    useEffect(() => {
        checkStake();
    }, [address, specialPool])
    useEffect(() => {
        (async () => {
            if(!networkId) return;
            try{
                let addresses = routeState?.addresses
                if(!routeState){
                    const _id = parseInt(id);
                    const project: any = await appUtils.projectDataByIndex("ongoing", _id);
                    if(!project){
                        return errorToast("Invalid index")(snackDispatch)
                    }
                    addresses = project.addresses;
                }
                const contractAddresses = addresses[appUtils.isDev() ? "development" : networkId] || [];
                if(!contractAddresses || contractAddresses.length == 0){
                    return errorToast("Empty address list!")(snackDispatch);
                }
                const firstCA = contractAddresses[0];
                const firstContract = await appUtils.launchPadInstance(web3, firstCA);
                const hasWhiteList = await launchPadContract(firstContract).isWhitelist();
                setState({
                    hasWhiteList, contractAddresses
                })
            }catch(error){

            }
        })()
    }, [networkId, JSON.stringify(routeState)])
    const changeStep = (toPosition: StakePosition): void => {
        setState({position: toPosition})
    },
    isOnWhitelist = position === "whitelist";
    return(
        <div className={classes.root}>
            <div className={classes.container}>
                <CustomBackBtn
                    route={buyRGPRouteNames().projects}
                    classNames={{link : classes.backToHome}}
                    display=" Back to Projects"
                />
                <CustomStepbar steps={steps} position={stepbarConversion(position)} />
                {position === "stake" && <StakeBox 
                    stakeRGP={stakeRGP} 
                    hasStakedRGP={hasStaked} 
                    changeStep={changeStep}/>
                }
                {position === "whitelist" && <WhitelistBox 
                    hasJoinedWhitelist={hasWhiteList}
                    changeStep = {changeStep}
                    projectIndex={id}
                    data={routeState}
                />}
                <button 
                    onClick={() => {
                        if(isOnWhitelist) 
                            history.replace(buyRGPRouteNames().projects);
                        else if (hasStaked) history.push(
                            buyRGPRouteNames().swap_+id, 
                            {data : routeState}
                        ) 
                    }}
                    className={classNames(
                        classes.openLaunchPadBtn, 
                        isOnWhitelist || hasWhiteList ? classes.proceedBtnEnabled : classes.proceedBtnDisabled
                    )}>
                        {isOnWhitelist ? "Projects" : "Open LaunchPad"}
                    </button>
            </div>
        </div>
    );
}