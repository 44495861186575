import { makeStyles } from '@material-ui/styles';
import { AppStyleProps} from '../../../utils'
import { ErrorOutline } from '@material-ui/icons';
import classNames from 'classnames';

const style=makeStyles(({colors, isDark} : AppStyleProps) => ({
    root: {
        position: "relative",
        borderRadius: ".5rem",
        padding: ".8rem .8rem .8rem 3rem",
        boxSizing: "border-box",
        marginBottom: "1.5rem",
    },
    infoPositive: {
        backgroundColor: colors.primary,
        color: colors.text,
    },
    infoNegative: {
        backgroundColor: isDark ? colors.dangerBG : colors.yellow,
        color: isDark ? colors.danger : colors.yellow2,
    },
    infoIcon: {
        position: "absolute",
        top: "1rem",
        left: ".9rem",
    },
    infoText: {        
        margin: 0,
        fontSize: ".9rem",
        lineHeight: "1.4rem",
    },
}))

type InfoComponentProps=Partial<{
    isPosititve: boolean,
    positiveText: string,
    negativeText: string 
}>

export default function InfoComponent({isPosititve, positiveText, negativeText}: InfoComponentProps) {

    const classes = style();

    return(
        <div className={classNames(classes.root, isPosititve ? classes.infoPositive : classes.infoNegative)}>
            <ErrorOutline className={classes.infoIcon} />
            {
            isPosititve ? 
            <p className={classes.infoText}>
                {positiveText}
            </p> :
            <p className={classes.infoText}>
                {negativeText}
            </p>
            }
        </div>
    )
}

export {InfoComponent}