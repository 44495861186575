import axios from 'axios';
import Web3 from "web3";
import {nftContract} from '../../../utils/web3';
import {
    addressPolygon,
    addressNft,
    polygonNet,
    nftInstance,
    addressPolyImage,
    addressImage,
    polyImageInstance,
    nftImage,
    addressPolyImageMain,
    addressPolyMainNet,
    addressBscImageMainNet,
    addressBscMainNet,
    polyMainImageInstance,
    bscMainImageInstance,
    polyMainInstance,
    bscMainInstance,
} from '.'
import { tokenContractActions, abis, store, ReduxStoreState, WalletState } from '../../../utils';

export const getNumberRange = (min: number, max: number) => {
    const rangeData = [];
    for(let i = min; i < max + 1; i++){
        rangeData.push(i);
    }
    return rangeData;
}

export function range(size:number, startAt:number = 0):ReadonlyArray<number> {
    return [...Array(size).keys()].map(i => i + startAt);
}

export const getIPFSData = async (cid: any, id: any ) => {

    if (cid == "" || cid == null || cid == undefined) {
        return;
    }

    const uriStrip = 'https://ipfs.io/ipfs/'+cid.replace('ipfs:/','').replace('{id}', id);

    return await axios.get(uriStrip).then(
        resp => {
            if(resp.status === 200){
                if (resp.data == "" || resp.data == null || resp.data == undefined) {
                    return;
                }
                return resp.data
            }
            if(resp.status === 404){
                return;
            }
        }
    );
}

export const getCleanIPFSImage = (uri: any) => {
    
    if (uri === "" || uri == null || uri === undefined) {
        return;
    }
    return 'https://ipfs.io/ipfs/'+uri.replace('ipfs://','');
}

export const fromWei = (num: any) => {
    const storeState = store.getState() as Partial<ReduxStoreState>,
    { walletReducer } = storeState,
    { networkId } = walletReducer as WalletState;

    if(networkId === 137){
        //console.log("Amount from contract: "+num);
        const values: any = Web3.utils.fromWei(String(num), 'gwei');
        //console.log("Amount from contract: "+values * 1000);
        return values * 1000;
    }
    return Web3.utils.fromWei(String(num), 'ether');
}

export const getUnsold = async (id: string, instance: any) => {

    const min = id.split('of')[0],
    max = id.split('of')[1],

    range = getNumberRange(Number(min), Number(max));
    let unsold: number = -1;

    for (let i = 0; i < range.length; i++) {
        const isSold = await nftContract(instance).isSold(range[i]);
        if(isSold === false){
            unsold = range[i];
            break;
        }
    }

    return unsold;
}

export const numberWithCommas = (x: any) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const getNftContract = async (nftInstance: any, id: any) => {
    const {token1, token2, token1Price, token2Price}: any = await nftContract(nftInstance).purchaseData(id);

    return {
        token1,
        token2,
        token1Price,
        token2Price,
    }

}


const getSalesImages = async (imageInstance: any, id: any) => {

    const ipfsData: any =  await nftContract(imageInstance).getImage(id),
    nftData = await getIPFSData(ipfsData, id),

    image = nftData && nftData.image ?  nftData.image: '',
    imageName = nftData && nftData.name ?  nftData.name: '';

    return {
        image,
        imageName
    }

}


export const getPurchaseData = async (web3: any, nftInstance: any, id: any, address: any) => {

    const { token1, token2, token1Price, token2Price } = await getNftContract(nftInstance, id),

    token1Balance = await tokenContractActions(new web3.eth.Contract(abis.erc20, token1)).balanceOf(address),
    token2Balance = await tokenContractActions(new web3.eth.Contract(abis.erc20, token2)).balanceOf(address),
    // Request for token symbol
    
    balance1 = Number(token1Balance).toFixed(3),
    balance2 = Number(token2Balance).toFixed(3),

    price1 = fromWei(token1Price),
    price2 = fromWei(token2Price),

    tokenName1 = await tokenContractActions(new web3.eth.Contract(abis.erc20, token1)).symbol(),
    tokenName2 = await tokenContractActions(new web3.eth.Contract(abis.erc20, token2)).symbol();

    return {
        token1,
        token2,
        token1Price: price1,
        token2Price: price2,
        token1Balance: balance1,
        token2Balance: balance2,
        tokenName1,
        tokenName2
    }

}

export const getCurrentNet = () => {
    const storeState = store.getState() as Partial<ReduxStoreState>,
    { walletReducer } = storeState,
    { networkId } = walletReducer as WalletState,

    currentAddress = networkId === 137 ? addressPolyMainNet: 
                     networkId === 80001 ? addressPolygon: 
                     networkId === 56 ? addressBscMainNet: 
                     networkId === 97 && addressNft,

    currentInstance = networkId === 137 ? polyMainInstance: 
                      networkId === 80001 ? polygonNet :
                      networkId === 56 ? bscMainInstance:
                      networkId === 97 && nftInstance,

    currentImgAddress = networkId === 137 ? addressPolyImageMain: 
                        networkId === 80001 ? addressPolyImage : 
                        networkId === 56 ? addressBscImageMainNet:
                        networkId === 97 && addressImage,
    
    currentImgInstance = networkId === 137 ? polyMainImageInstance:
                         networkId === 80001 ? polyImageInstance: 
                         networkId === 56 ? bscMainImageInstance:
                         networkId === 97 && nftImage;

    return {
        currentAddress,
        currentInstance,
        currentImgAddress,
        currentImgInstance
    }
}

export const getSales = async (web3: any, imageInstance: any|null, nftInstance: any, address: any, id: any) => {

    const { image, imageName } = await getSalesImages(imageInstance, id),

    {
        token1,
        token2,
        token1Price,
        token2Price,
        token1Balance,
        token2Balance,
        tokenName1,
        tokenName2
        
    } = await getPurchaseData(web3, nftInstance, id, address);

    return {
        image,
        imageName,
        token1,
        token2,
        token1Price,
        token2Price,
        token1Balance,
        token2Balance,
        tokenName1,
        tokenName2
    }
     
}

