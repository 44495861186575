import { BrowserRouter as Router} from 'react-router-dom';
import { history } from '../../helpers';
import { ThemeProvider, useTheme } from '@material-ui/core';
import { launchPadTheme, lunchPadRoutes} from './utils';
import Layout from './components/Layout';

//did a correction for git
function LunchPadApp() {
    const theme = useTheme();
    return <Router>
        <ThemeProvider theme={{...theme, colors : launchPadTheme}}>
            <Layout
                routes={lunchPadRoutes}
            />
        </ThemeProvider>
    </Router>
}

export default LunchPadApp;
export { LunchPadApp };
