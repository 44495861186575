import { ReduxStoreState, WalletState } from '../index';
import { store } from '../index';
import { tokenContractActions } from './index';
import { errorToast } from '../../includes';
const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';
export default function rgpSpecialPool (){
    const storeState = store.getState() as Partial<ReduxStoreState>,
    { walletReducer } = storeState,
    { contracts, address} = walletReducer as WalletState,
    {specialPool, rgp } = contracts || {};
    function expandNumber(number : number) : string {
        number *= 10**18;
        return String(Number(number).toLocaleString()).split(",").join("");
    }
    function reduceNumber(number : number){
        return number / (10**18);
    }
    async function getMinimumStakeAmount(){
        return reduceNumber(await specialPool?.methods.getMinimumStakeAmount().call());
    }
    return {
        reduceNumber,
        userInfo : async () : Promise<any> => await specialPool?.methods?.userInfo(address).call(),
        getMinimumStakeAmount : async () => await getMinimumStakeAmount(),
        stake : async (amount : number, onApproval : ()=>void) : Promise<any> => {
            const minAmount = await getMinimumStakeAmount();
            if(amount < minAmount){
                errorToast(`Amount (${amount} RGP) less than the minimum stake requirement (${minAmount} RGP)`)(store.dispatch);
                return null;
            }
            if(!rgp) {
                errorToast("RGP contract not loaded")(store.dispatch)
            }
            const _amount = expandNumber(amount);
            await tokenContractActions(rgp).approve(specialPool._address, amount);
            onApproval && onApproval();
            return await specialPool?.methods?.stake(_amount, ZERO_ADDRESS).send({from : address});
        }
    }
}
export {rgpSpecialPool};