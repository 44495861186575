import {useState} from 'react'
import {makeStyles, ButtonBase} from '@material-ui/core';
//import { ArrowDropDown } from '@material-ui/icons';
import { AppStyleProps, BuyRGPTypes, Nullible } from '../../Applications/BuyRGP/common';
import { getBuyRGPUtils } from "../../Applications/BuyRGP/common"
import { TokenIcon } from '../index';
const style = makeStyles(({colors, isDark} : AppStyleProps) => ({
    root : {
        width: "100%",
        border: `1px solid ${colors.primary}`,
        borderRadius: "6px",
        padding: "1rem",
        boxSizing: "border-box",
        margin: ".5rem 0",
    },
    input: {
        display: "flex",
        height: "2.4rem",
        marginBottom: ".5rem",
    },
    tokenInputField: {
        backgroundColor: "transparent",
        width: "70%",
        height: "100%",
        padding: 0,
        border: 0,
        outline: 0,
        fontSize: "1.5rem",
        color: isDark ? colors.text : colors.black2,
        textOverflow: "ellipsis",
        "&::placeholder": {
            color: colors.textHighlight,
            opacity: .8,
        }
    },
    dropdown: {
        width: "120px",
        height: "40px",
        backgroundColor: colors.primary,
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        border: `1px solid ${colors.borderOutline}`,
        borderRadius: ".3rem",
    },
    tokenName: {

    },
    dropdownIcon: {

    },
    balanceContainer: {

    },
    balance: {
        display: "inline-block",
        fontSize: "1rem",
        lineHeight: "1.5rem",
        marginRight: "1rem",
    },
    maxBtn: {
        border: 0,
        backgroundColor: colors.maxButtonBG,
        color: colors.textHighlight,
        borderRadius: ".25rem",
        fontSize: "1rem",
        lineHeight: "1.5rem",
        padding: "0rem .25rem",
    },
    
}))



type ICustomInputFieldProps<P> = Nullible<Partial<{
    maxBtn? : boolean,
    balance : number,
    symbol : string,
    tokens : P,
    stateMonitor : (value : string) => void
}>>
type CustomInputFieldState<P> = Nullible<Partial<{
    value : string,
    selected : keyof P,
}>>
export default function CustomInputField<T>({
    maxBtn, balance, symbol, tokens, stateMonitor
} : ICustomInputFieldProps<T>) {
    const { inputDecimalValidator, tokenAmount } = getBuyRGPUtils("v1");
    const classes = style(),
        [state, _setState] = useState<CustomInputFieldState<T>>({
            value : "",
            selected : tokens && Object.keys(tokens)?.[0] as keyof T
        }),
        {value, selected} = state,
        setState = (_state : CustomInputFieldState<T>) => _setState(state=>({...state, ..._state})),
        setValue = (value : string) => {
            if(!inputDecimalValidator(value)) return;
            setState({value});
            stateMonitor && stateMonitor(value);
        }
    return (
        <div className={classes.root}>
            <div className={classes.input}>
                <input 
                    value={value as string} 
                    onChange={({target : {value}})=> setValue(value)} 
                    type="text" 
                    placeholder="0.00" 
                    className={classes.tokenInputField}/>
                <div
                    className={classes.dropdown}
                    onClick={undefined}
                >
                    <TokenIcon tokenSymbol={selected as keyof BuyRGPTypes}/> 
                    {String(symbol).toUpperCase()}
                   { /* <ArrowDropDown className={classes.dropdownIcon}/> */}
                </div>
            </div>
            <div className={classes.balanceContainer}>
                <span className={classes.balance}>Balance: {tokenAmount(balance || 0)} {symbol || ""}</span>
                {maxBtn && <ButtonBase onClick={() => setValue(String(balance || 0))} className={classes.maxBtn}>Max</ButtonBase>}
            </div>
        </div>
    );
}
export { CustomInputField }