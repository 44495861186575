import $ from 'jquery';
import { abis } from '../abis';
import {projectDataTypes} from './types';
import CryptoJS from "crypto-js";
const sampleData = require("../data.json")
export function appRoutePrefix() {
    return {
        buyRGP: "buy",
        launchPad: "",
    }
}
const dataLinks : projectDataTypes = {
    featured : "https://api.npoint.io/319df38fae7e1f9918a4",
    ongoing : "https://api.npoint.io/b42afc79a4ecf0edcbc1",
    finished : "https://api.npoint.io/7dc649205a535a69ba1c"
}
export const projectsDataLink = (dataType : keyof projectDataTypes) => {
    return dataLinks[dataType];
}
export const researchDataLink = "https://api.npoint.io/c6de3a40ebb4cc99f199";
export const appUtils = {
    getAddressBook: () => require("../addresses.json"),
    isDev : () => process.env.NODE_ENV === "development",
    getComparedNetworkId : (networkId : any) => {
        return appUtils.isDev() ? "development" : networkId;
    },
    projectData : (dataType : keyof projectDataTypes) => new Promise(async (resolve, reject) => {
        try {
            appUtils.isDev() ? resolve(sampleData) : resolve(await $.getJSON(projectsDataLink(dataType)))
        }catch(error : any){
            console.error("error", error)
            reject(error)
        }
    }),
    projectDataByIndex : (dataType : keyof projectDataTypes, index : number) => new Promise(async (resolve, reject) => {
        try {
            appUtils.isDev() ? 
                resolve(sampleData?.[index]) : 
                resolve(await $.getJSON(projectsDataLink(dataType)+"/"+index))
        }catch(error : any){
            console.log("error", error)
            reject(error)
        }
    }),
    launchPadInstance : (web3 : any, address : string) => {
        if(!web3) throw new Error("web3 not found!")
        return new web3.eth.Contract(abis.launchPad, address);
    },
    erc20Instance : (web3 : any, address : string) => {
        if(!web3) throw new Error("web3 not found!")
        return new web3.eth.Contract(abis.erc20, address);
    },
    specialPoolInstance : (web3 : any, address : string) => {
        if(!web3) throw new Error("web3 not found!")
        return new web3.eth.Contract(abis.specialPool, address);
    },
    encryptRef : (message : string) => {
        return CryptoJS.AES.encrypt(message, "launchpad").toString();
    },
    decryptRef : (message : string) => {
        try{
            return CryptoJS.AES.decrypt(message.toString(), "launchpad").toString(CryptoJS.enc.Utf8);
        }catch(err){
            console.error(err);
            return null;
        }
    },
    queryStringToObject: (query : string) => {
        const queryObject = {} as any,
        _ar = query.split("&");
        if(_ar) for (let i = 0; i < _ar.length; i++) {
            const _sq = String(_ar[i]).split("=");
            queryObject[_sq[0]] = _sq[1];
            
        }
        return queryObject
    }
}


