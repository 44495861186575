import { makeStyles } from '@material-ui/styles';
import { launchPadRouteNames, LaunchPadStyleProps } from '../../../utils';
import { ButtonBase } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useGaEvents } from '../../../../../helpers';
import { useHistory } from 'react-router';
import { useCallback } from 'react';
import { buyRGPRouteNames } from '../../../../BuyRGP/v1';

const style = makeStyles((theme: LaunchPadStyleProps) => ({
    root: {
        color: theme.colors.textDefault,
        background: theme.colors.FeaturedBackgroud,
        textAlign: "left",
        position: "relative",
        transformStyle: "preserve-3d",
        padding: "5rem",
        boxSizing: "border-box",
        [theme.breakpoints.down('md')]: {
            padding: "2.5rem",
        },
        [theme.breakpoints.down('sm')]: {
            padding: "1rem",
        }
    },
    journeyHeading: {
        fontSize: "48px",
        fontFamily: "Clash Display",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "48px",
        color: theme.colors.text,
        textAlign: "left",
        [theme.breakpoints.down('lg')]: {
            fontSize: "56px",
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "32px",
            marginBottom: "1rem",
            paddingLeft: 0
        }
    },

    featuredContainer: {
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column-reverse",
        },
        [theme.breakpoints.down("sm")]: {
            paddingLeft: 0,
            paddingRight: 0,
        }
    },

    textSection: {
        width: "50%",
        [theme.breakpoints.down("md")]: {
            width: "80%",
        },
        [theme.breakpoints.down("sm")]: {
            width: "90%",
        },
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        }

    },

    ImgSection: {
        width: "50%",
        // height: "40rem",
        marginRight: "2rem",
        [theme.breakpoints.down("md")]: {
            width: "80%",
            margin: "3rem auto 0",
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },

    launchImg: {
        width: "100%",
        height: "100%"
    },

    item: {
        display: "flex",
        opacity: ".8",
        maxWidth: "50%",
        marginBottom: "1rem",
        fontSize: "16px",
        [theme.breakpoints.down("md")]: {
            fontSize: "16px",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "16px",
            maxWidth: "80%",
        },
    },
    itemRight: {
        display: "flex",
        flexDirection: "column"
    },
    itemLeft: {
        display: "flex",
        flexDirection: "column",
        marginLeft: "5rem"
    },
    key: {
        whiteSpace: "nowrap",
        color: "#A7B3BE",
        marginBottom: "1rem"
    },
    value: {
        whiteSpace: "nowrap",
        color: theme.colors.textDefault,
        marginBottom: "1rem"
    },

    journeyBtn: {
        backgroundImage: " linear-gradient(90deg, #2691C1 0%, #045CA6 42.71%, #055DA6 60.42%, #238CBE 100%)",
        boxShadow: "0px 1px 5px rgba(51, 51, 153, 0.1)",
        color: theme.colors.textDefault,
        fontSize: "16px",
        width: "196px",
        height: "56px",
        fonWeight: "700",
        padding: "1.5rem 5rem",
        borderRadius: "6px",
        outline: "none",
        marginTop: "1.5rem",
        [theme.breakpoints.down('xl')]: {
            fontSize: "16px",
            padding: ".7rem 1.5rem",
            borderRadius: ".5rem",
        }
    },
    projectName: {
        fontFamily: "Clash Display",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "32px",
        lineHeight: "48px",
        marginBottom: "1rem",
        color: theme.colors.textDefault,
        [theme.breakpoints.down("sm")]: {
            fontSize: "28px",
        },
    },
    token: {
        fontFamily: "DM Sans",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "24px",
        marginBottom: "1rem",
        color: theme.colors.subHeadingDefault

    },
    projectDesc: {
        fontFamily: "DM Sans",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "24px",
        color: theme.colors.textDefault,
        minWidth: "510px",
        marginBottom: "2.5rem",
        [theme.breakpoints.down('sm')]: {
            minWidth: "80%"
        }
    },
    journeyBtn1: {
        backgroundImage: " linear-gradient(90deg, #2691C1 0%, #045CA6 42.71%, #055DA6 60.42%, #238CBE 100%)",
        boxShadow: "0px 1px 5px rgba(51, 51, 153, 0.1)",
        color: theme.colors.textDefault,
        fontSize: "13px",
        width: "101px",
        height: "32px",
        fonWeight: "700",
        // padding: "1.5rem 1rem",
        borderRadius: "6px",
        outline: "none",
        [theme.breakpoints.down('xl')]: {
            fontSize: "13px",
            // padding: ".7rem 1.5rem",
            borderRadius: ".5rem",
        }
    },

}))
type MetaData = {name : string, label : string, value : string}
type AppState = Partial<{
    token : string,
    projectIndex : number,
    research : any,
    metadata : MetaData[],
    action : string,
    projectID: string,
    image: string,
    title: string,
    description: string,
    allocation: any,
    total: number,
    sheetName: string,
    chain: string,
    IDODate: string,
    GMT: string,
    pool: any
}>

export default function LaunchPadFeaturedProject(data : AppState) {

    const classes = style(),
    { 
        image, token, metadata, title, description, allocation, 
        total, sheetName, projectID, chain, action,
        IDODate, pool, GMT , projectIndex
    } = data,
    GaTrackEvents = useGaEvents(),
    history = useHistory(),
    moveToWhitelist = (callback : Function) => {
        callback && callback();
        history.push(launchPadRouteNames.projectWhitelist_+projectIndex, {data, from : "featured"}) ;
    }
    function numberWithCommas(x: any) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    const getMetadata = useCallback((name : string, field : "value" | "label") => {
        if(!metadata || metadata.length === 0) return "";
        const data = metadata.find(item=>item.name === name) as any;
        return data?.[field];
    }, [JSON.stringify(metadata)]),
    isTokenSale = action === "tokenSale";
    
    return (
        <section className={classes.root}>
            <h2 className={classes.journeyHeading}>
                {title}
            </h2>
            <div className={classes.featuredContainer}>
                <div className={classes.ImgSection}>
                    <img className={classes.launchImg} src={image} alt="launch" />
                </div>
                <div className={classes.textSection}>
                    <ButtonBase
                        className={classes.journeyBtn1} onClick={
                            () => moveToWhitelist(
                                ()=>GaTrackEvents(
                                    "View Launchpad Project Page",
                                    "project_chain_button_clicked",
                                    "Project Chain Button"
                                )
                            )
                        }>
                        {chain} Chain
                    </ButtonBase>
                    <p className={classes.projectName}>{title}</p>
                    <p className={classes.token}>{token}</p>
                    <p className={classes.projectDesc}>{description}</p>
                    <div className={classes.item}>
                        <div className={classes.itemRight}>
                            <span className={classes.key}>{getMetadata("currency", "label")}</span>
                            <span className={classes.key}>{getMetadata("personalAllocation", "label")}</span>
                            <span className={classes.key}>{getMetadata("presale", "label")}</span>
                        </div>
                        <div className={classes.itemLeft}>
                            <span className={classes.value}>{getMetadata("currency", "value")}</span>
                            <span className={classes.value}>{getMetadata("personalAllocation", "value")}</span>
                            <span className={classes.value}>{getMetadata("presale", "value")}</span>
                        </div>
                    </div>


                    <ButtonBase
                        className={classes.journeyBtn} onClick={
                            () => {
                                if(isTokenSale){
                                    window.location.href = buyRGPRouteNames().stake_+0;
                                }else {
                                    moveToWhitelist(
                                        () => GaTrackEvents(
                                            "View Launchpad Project Page",
                                            "project_join_whitelist_button_clicked",
                                            "Project Join Whitelist"
                                        )
                                    )
                                }
                            }
                        }>
                        {isTokenSale ? "Join Token Sale" : "Join Whitelist"}
                    </ButtonBase>
                </div>
            </div>

        </section>
    )

}