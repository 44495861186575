import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery,ListItem } from '@material-ui/core'
import { ReduxStoreState, buyRGPRouteNames, walletUtil, AppStyleProps, WalletState, history, STORAGE_VARIABLE, utilAction, AppThemeValues, APP_THEME_TYPES } from '../../../utils';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThLarge } from '@fortawesome/free-solid-svg-icons';
import Divider from '@mui/material/Divider';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import classNames from 'classnames';

const useStyles = makeStyles(({colors, breakpoints, isDark} : AppStyleProps) => ({
  cursor: {
    cursor: "pointer",
  },
  mr: {
    marginRight: '8px',
  },
  textUpper:{
    color: isDark ? '#ffffffeb' : '#1a202c',
    fontSize: '16px',
    fontFamily: 'Cera Pro',
  },
  menu: {
    "& .MuiPaper-root" : {
      backgroundColor: isDark ? '#2d3748' : '#ffffff',
      borderWidth: `1px`,
      borderRadius: '2px',
      width: 200,
      padding: '0px',
    },
  },
  paper: {
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    boxSizing: `border-box`,
    [breakpoints.down("sm")]: {
      display: "none",
    },
  },
  btn : {
    marginLeft: "6px !important",
    border : `1px solid ${colors.borderOutline} !important`,
    borderRadius: '6px !important',
    width: '45px !important',
  },
  icon : {
    color: isDark ? '#ffffeb' : '#1a202c',
  }
}));

function SocialMedia() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const classes = useStyles()
  const themeType = localStorage.getItem(STORAGE_VARIABLE.appTheme) as keyof AppThemeValues;
  // the second part (themeType === null) is for when the user has not explicitly select a theme
  // we are defaulting to dark in this case
  const isDark = themeType === APP_THEME_TYPES.dark || themeType === null;
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  return (
    <>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        classes={{ root : classes.btn }}
      >
        <MoreHorizIcon className={classes.icon} />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        // classes={{ paper: classes.menu }}
        className = {classes.menu}
      >
        <MenuItem>
          <ListItem style={{textAlign: 'left'}} component="a" target="_blank" href="https://www.linkedin.com/company/rigelprotocol" rel="noreferrer">
            <Stack>
              <div className={classes.textUpper}>Linkedin</div>
            </Stack>
          </ListItem>
        </MenuItem>
        <MenuItem>
          <ListItem component="a" target="_blank" href="https://medium.com/rigelprotocol" rel="noreferrer">
            <Stack>
              <div className={classes.textUpper}>Medium</div>
            </Stack>
          </ListItem>
        </MenuItem>
        <Divider />
        <MenuItem>
          <ListItem component="a" target="_blank" href="https://www.t.me/rigelprotocol" rel="noreferrer">
            <Stack>
              <div className={classes.textUpper}>Telegram</div>
            </Stack>
          </ListItem>
        </MenuItem>
        <MenuItem>
          <ListItem component="a" target="_blank" href="https://twitter.com/rigelprotocol" rel="noreferrer">
            <Stack>
              <div className={classes.textUpper}>Twitter</div>
            </Stack>
          </ListItem>
        </MenuItem>
        <Divider />
        <MenuItem>
          <ListItem component="a" target="_blank" href="https://github.com/rigelprotocol" rel="noreferrer">
            <Stack>
              <div className={classes.textUpper}>Github</div>
            </Stack>
          </ListItem>
        </MenuItem>
        <MenuItem>
          <ListItem component="a" target="_blank" href="https://discord.gg/j86NH95GDD" rel="noreferrer">
            <Stack>
              <div className={classes.textUpper}>Discord</div>
            </Stack>
          </ListItem>
        </MenuItem>
      </Menu>
    </>
  );
}

export default SocialMedia;
