import React, { MouseEvent, useCallback, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  useMediaQuery,
  AppBar,
  Toolbar
} from '@material-ui/core';
import WbSunnyOutlinedIcon from '@material-ui/icons/WbSunnyOutlined';
import Brightness2 from '@material-ui/icons/Brightness2'
import Connected from "./Connection/Connected";
import DisConnected from "./Connection/DisConnected";
import MenuIcon from '@material-ui/icons/Menu';
import { faLinkedin, faTelegram, faTwitter, faGithub, faMedium } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from "react-router-dom";
import classNames from 'classnames';
import { useSelector, useDispatch } from "react-redux";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import {
  ReduxStoreState, buyRGPRouteNames, WalletTypes, walletUtil,
  AppStyleProps, STORAGE_VARIABLE, utilAction,
  AppThemeValues, APP_THEME_TYPES, walletConnection,
  appUtils
} from '../../../utils';
import Menu from "./dropdown/Menu";
import BottomNav from "./MobileView/BottomNav";
import SideNav from "./MobileView/SideNav";
import LightLogo from './../../../../../../assets/logo/logo-light.svg';
import DarkLogo from './../../../../../../assets/logo/logo-dark.svg';
import DappsDropdown from './DappsDropdown';
import SocialMedia from './SocialMedia';
import { CustomDialog, errorToast, successToast, warningToast } from "../../../includes";
import { WalletList } from './WalletList';
import ReferralModal from './ReferralModal';

const MetamaskIcon = "/header/metamask.png";
const BinanceIcon = "/header/binance.png";

const useStyles = makeStyles(({ colors, breakpoints, isDark }: AppStyleProps) => ({
  nav: {
    justifyContent: "space-around",
    padding: "8px, 0px",
    borderBottom: `1px solid ${colors.borderOutline}`,
    height: "80px",
  },
  navGroup: {
    display: "flex",
    whiteSpace: 'nowrap',
  },
  paper: {
    textDecoration: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px",
    marginLeft: "8px",
    borderRadius: "6px",
    boxSizing: "border-box",
    border: `1px solid ${colors.borderOutline}`,
    [breakpoints.down("sm")]: {
      display: "none",
    },
  },
  navText: {
    marginLeft: ".6rem",
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: "1.5rem",
    textDecoration: "none",
    color: colors.text,
    '&:hover': {
      color: colors.textHighlight,
    },
    [breakpoints.down("sm")]: {
      display: "none",
    },
  },
  cursor: {
    cursor: "pointer",
    color: colors.text
  },
  text: {
    color: colors.text,
    fontSize: "1rem",
    fontStyle: "normal",
    lineHeight: "24px",
    letterSpacing: "0em",
    textAlign: "center",
    whiteSpace: "nowrap",
  },
  smallSpace: {
    margin: 4,
  },
  midiumlogo: {
    height: 24,
    width: 24,
  },
  imgMargin: {
    marginRight: "0.6rem",
  },
  flex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor:"pointer"
  }
}));

export function Header() {
  const classes = useStyles(),
    dispatch = useDispatch(),
    { address: connected, address, walletType, networkId } = useSelector((state: ReduxStoreState) => state.walletReducer),
    [state, setState] = React.useState<any>({
      right: false, el: null, elSocial: null, dialogType : null,
      openDialog : false,
    }),
    [displayWallet, setDisplayWallet] = React.useState(false),
    [displayRefModal, setDisplayRefModal] = React.useState(false),
    [onLoadWallet, setUnloadWallet] = React.useState(false),
    [isMainnet, setIsMainet] = React.useState(false),
    openDialog = (dialogType : string) => setState({openDialog : true, dialogType}),
    closeDialog = () => setState({openDialog : false, dialogType : null}),
    theme = useTheme(),
    isMobile = useMediaQuery(theme.breakpoints.down("sm")),
    { contractAddresses } = useSelector((state : ReduxStoreState) => state.walletReducer),

    handleCloseMenu = () => {
      setState({ el: null });
    },

    handleSocial = (event: React.MouseEvent<HTMLDivElement>) => {
      setState({ elSocial: event.currentTarget });
    },

    handleCloseSocial = () => {
      setState({ elSocial: null });
    },

    icon: string = walletType === "binance" ? BinanceIcon : MetamaskIcon,

    toggleDrawer = (anchor: any, open: any) => (event: any) => {
      if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
      setState({ ...state, [anchor]: open });
    },

    bottomConnectedComp = () => {
      return (
        <div className={classes.flex} onClick={()=>setDisplayWallet(!displayWallet)}>
          <p className={classes.text}>{walletUtil.shortAddress(String(address).toUpperCase())}</p>
          <img src={icon} className={classNames(classes.midiumlogo, classes.smallSpace)} alt={"LaunchPad metamask"} />
        </div>
      )
    },

    switchTheme = () => {
      // defaulting to dark here in case it is null
      const currentTheme = localStorage.getItem(STORAGE_VARIABLE.appTheme) || APP_THEME_TYPES.dark;
      localStorage.setItem(STORAGE_VARIABLE.appTheme, currentTheme === APP_THEME_TYPES.dark ? APP_THEME_TYPES.light : APP_THEME_TYPES.dark);
      utilAction.quickRefresh(dispatch);
    },
    onWalletSelect = useCallback((wallet : keyof WalletTypes) => {

      (async() => {

          try{
            setUnloadWallet(true)
            let addresses = contractAddresses;
            if(!addresses)
              addresses = appUtils.getAddressBook()
            await walletConnection(
              addresses, 
              wallet, 
              () => warningToast("Reconnecting...")(dispatch), 
              () => successToast("Connected!")(dispatch),
              true
            );
            closeDialog();
            setUnloadWallet(false)
          }catch(error : any){
              const message = error.message as string;
              setUnloadWallet(false)
              errorToast(message)(dispatch)
          }
      })()
    }, [JSON.stringify(contractAddresses)])

  const themeType = localStorage.getItem(STORAGE_VARIABLE.appTheme) as keyof AppThemeValues;
  // the second part (themeType === null) is for when the user has not explicitly select a theme
  // we are defaulting to dark in this case
  const isDark = themeType === APP_THEME_TYPES.dark || themeType === null;

  return (
    <>
      <AppBar position="relative" color="transparent" elevation={0} className={classes.nav}>
        <Toolbar>
          <div className={classNames(classes.cursor, classes.imgMargin)}>
            <img src={isDark ? DarkLogo : LightLogo} alt="Rigel potocol LaunchPad logo" />
          </div>

          <DappsDropdown />

          <div className={classes.navGroup}>
            <Link to={buyRGPRouteNames().projects} className={classes.navText} >
              Projects
            </Link>
            <Link to={buyRGPRouteNames().nfts} className={classes.navText}>
              RigelNfts
            </Link>
            <Link to={buyRGPRouteNames().mintNft} className={classes.navText}>
              NFTPad
            </Link>
            <Link to={buyRGPRouteNames().minePad} className={classes.navText}>
              MinePad
            </Link>
            {/* <Link to={buyRGPRouteNames().mintNft} className={classes.navText}>
              Mint
            </Link> */}
            {/* <div style={{cursor: "pointer"}} onClick={()=> setDisplayRefModal(!displayRefModal)} className={classes.navText}>
              Referral
            </div> */}
            {/* <Link onClick={(e) => {
                window.open("https://smartswap.rigelprotocol.com/#/swap", "_blank");
                e.preventDefault();
            }}  to="#" className={classes.navText}>
                Buy RGP
            </Link> */}
          </div>

          {/* End Left aligned contents */}

          {/* Left aligned contents containing wallet name, wallet amount, ether with metamask logo, menu utton, more option and night mode */}
          {isMobile ?
            <div className={classNames(classes.cursor)} onClick={toggleDrawer("right", true)} style={{ marginLeft: 'auto', border: isDark ? "1px solid #213345" : "1px solid #dee6ed", borderRadius: "6px", padding: "4px" }}>
              <MenuIcon />
            </div> :
            <div className={classes.navGroup} style={{ marginLeft: "auto" }}>
              {connected ? (
                <Connected openWallet={openDialog}/>
              ) : (
                <>
                  <DisConnected showWalletDialog={openDialog}/>{" "}
                </>
              )}
              <SocialMedia />
              <Menu
                  anchorEl={state.elSocial}
                  handleClose={handleCloseSocial}
                  network={[{ id: 1, icon: <FontAwesomeIcon icon={faTelegram} />, name: "Telegram", link: "https://t.me/rigelprotocol" }, { id: 2, icon: <FontAwesomeIcon icon={faTwitter} />, name: "Twitter", link: "https://twitter.com/rigelprotocol" }, { id: 3, icon: <FontAwesomeIcon icon={faLinkedin} />, name: "LinkedIn", link: "https://linkedin.com/company/rigelprotocol" }, { id: 4, icon: <FontAwesomeIcon icon={faGithub} />, name: "Github", link: "https://github.com/rigelprotocol" }, { id: 5, icon: <FontAwesomeIcon icon={faMedium} />, name: "Medium", link: "https://medium.com/rigelprotocol" }]} />
              <div
                className={classNames(classes.paper, classes.cursor)}
              >
                {isDark ? <WbSunnyOutlinedIcon onClick={switchTheme} /> : <Brightness2 onClick={switchTheme} />}
              </div>
            </div>
          }

          {/* End Right aligned contents */}
        </Toolbar>
      </AppBar>
      <BottomNav
        connected={connected}
        bottomConnectedComp={bottomConnectedComp}
        el={state.elSocial}
        close={handleCloseMenu}
        open={handleSocial}
        isDark={isDark}
        switchTheme={switchTheme}
        showWalletDialog={openDialog}
        displayWallet={displayWallet}
        setDisplayWallet={setDisplayWallet}
        OnSelect={onWalletSelect}
        closeDialog={closeDialog}
        openDialog={state.openDialog}
        items={[
          {
            id: 1, icon: <FontAwesomeIcon icon={faTelegram} />,
            name: "Telegram", link: "https://t.me/rigelprotocol"
          },
          {
            id: 2, icon: <FontAwesomeIcon icon={faTwitter} />,
            name: "Twitter", link: "https://twitter.com/rigelprotocol"
          },
          {
            id: 3, icon: <FontAwesomeIcon icon={faLinkedin} />,
            name: "LinkedIn", link: "https://linkedin.com/company/rigelprotocol"
          },
          {
            id: 4, icon: <FontAwesomeIcon icon={faGithub} />,
            name: "Github", link: "https://github.com/rigelprotocol"
          },
          {
            id: 5, icon: <FontAwesomeIcon icon={faMedium} />,
            name: "Medium", link: "https://medium.com/rigelprotocol"
          }]}
      />
      <SideNav state={state} toggleDrawer={toggleDrawer} connected={connected} />
      <CustomDialog
        open={state.openDialog}
        close={closeDialog}
      >
        <WalletList
          onSelect={onWalletSelect}
          onLoad={onLoadWallet}
        />
      </CustomDialog>
      <ReferralModal 
      title="Get 10% tokens"
        text=" Enjoy 10% on token purchases when your friends purchase tokens
        on this project with your referral link. Your friends also benefit 5% on token purchases when they purchase with your referral link."
        hostDetail={window.location.origin+buyRGPRouteNames().projects}
        displayRefModal={displayRefModal} 
        setDisplayRefModal={()=>setDisplayRefModal(false)}
        />
    </>
  );
}

export default Header;
