import { makeStyles } from '@material-ui/core';
import React from 'react';
import classNames from 'classnames';
const style = makeStyles(() => ({
    root : {
        display : "flex",
        position : "relative",
        minWidth : 40,
        height : 40,
        alignItems : "center",
    },
    img : {
        position: "absolute",
        width: 24,
        height: 24,
        borderRadius : "100%",
        border : "solid 1px white"
    },
    img1 : {
        zIndex : 1,
    },
    img2 : {
        zIndex : 2,
        left : 17,
    }
}))
type CustomDoubleIconsProps = {
    image1 : string,
    image2 : string
}
const CustomDoubleIcons = function({
    image1, image2
} : CustomDoubleIconsProps) {
    const classes = style();
    return <div className={classes.root}>
        <img className={classNames(classes.img, classes.img1)} src={image1} title='launchpad image 1'/>
        <img className={classNames(classes.img, classes.img2)} src={image2} title='launchpad image 1'/>
    </div>
}
export default CustomDoubleIcons;
export { CustomDoubleIcons }
