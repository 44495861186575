import { makeStyles } from '@material-ui/styles';
import { AppStyleProps, LaunchPadStyleProps} from '../../../../utils'

const style = makeStyles((theme : LaunchPadStyleProps) => ({
    root: {
        position: "relative",
        width: "inherit",
        textAlign: "left",
        marginBottom: "40px",
        borderRadius: "6px",
        background:theme.colors.pageBackground,
        fontSize: "1.2rem",
        [theme.breakpoints.down("md")]: {
            width: "inherit",
            margin: "4rem 1rem 0",
        },
        [theme.breakpoints.down("sm")]: {
            width: "inherit",
            maxWidth: "25rem",
            margin: "0rem 0rem 0",
        }
        
    },

    dataPicture: {
        marginTop:"20px",
        width: "64px",
        height: "64px"
    },
    dataHead: {
        marginTop:"20px",
        marginRight:"10px",
        fontFamily: "Clash Display",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "20px",
        lineHeight: "30px",
        margin: 0,
        whiteSpace: "nowrap",
        [theme.breakpoints.down(1690)]: {
            fontSize: "20px",
        },
        [theme.breakpoints.down(380)]: {
            fontSize: "20px",
        },
    },
    dataContent: {
        marginTop:"20px",
        marginRight:"10px",
        fontFamily: 'DM Sans, sans-serif',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "24px",
        color: theme.colors.subHeadingDefault,
        margin: 0,
        Width: "60%",
        [theme.breakpoints.down(1690)]: {
            fontSize: "16px",
        },
        [theme.breakpoints.down(380)]: {
            fontSize: "16px",
        },
    },

}))


type NetwotkCardItemProps = {
    dataHead: string,
    dataContent: string,
    dataPicture: string
}

export default function LaunchPadInvestorItem({dataHead, dataContent, dataPicture}: NetwotkCardItemProps) {
    const classes = style();

    return(
        <div className = {classes.root}>
                <img src = {`./assets/${dataPicture}.png`} className = {classes.dataPicture} alt = "project pic" />
                <h4 className = {classes.dataHead}>{dataHead}</h4>
                <p className = {classes.dataContent}>{dataContent}</p>
        </div>
    );
}

