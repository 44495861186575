import { customPopoverConstant } from "./config";
import { CustomPopoverDispatch, CustomPopoverState } from "./types";

export const customPopoverActions ={
    close : (dispatch : CustomPopoverDispatch) => dispatch({
        type : customPopoverConstant.SET_CUSTOM_POPOVER_CLOSE,
        payload : {},
    }),
    payload : (payload : CustomPopoverState) => (dispatch : CustomPopoverDispatch) => dispatch({
        type : customPopoverConstant.SET_CUSTOM_POPOVER,
        payload
    })
}