import walletConnection from "../../../utils/web3/walletConnection";
import { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { CustomSnackbarDispatch, errorToast, successToast, warningToast } from "../../../includes";
import { walletUtil, WalletTypes, ReduxStoreState, appUtils } from "../../../utils";

export type Web3ProviderProps = {
    initialWallet : keyof WalletTypes,
}
export default function Web3Provider({
    children, initialWallet
} : {children : JSX.Element} & Web3ProviderProps) {
    const { storageNetworkID, storageTokenContract } = useSelector((state : ReduxStoreState)=>state.utilReducer),
    {address} = useSelector((state : ReduxStoreState) => state.walletReducer),
    dispatch = useDispatch() as CustomSnackbarDispatch;
    useEffect(() => {
        if(!storageNetworkID) return;
        (async () => {
            try{
                const addresses = appUtils.getAddressBook();
                await walletConnection(
                    addresses, 
                    initialWallet, 
                    () => warningToast("Connecting...")(dispatch), 
                    () => successToast("Connected!")(dispatch)
                )
            }catch({message}){
                console.error(message)
                errorToast("Connectoin Failed!")(dispatch)
            }
        })();
        // eslint-disable-next-line
    }, [storageNetworkID, storageTokenContract, JSON.stringify(dispatch)]); //address, 
    //implement global network action in layout
    // if(!address) return <div>Loading...</div>
    return children
}
