import { KeyboardBackspace } from '@material-ui/icons';
import React from 'react'
import { Link } from 'react-router-dom';

export default function CustomBackBtn ({
    display,
    classNames,
    route,
    button,
    onClick
} : Partial<{
    display : string,
    route : string,
    button : boolean,
    onClick : any,
    classNames : {
        link : string
    }
}>) {
    const props = {
        className : classNames?.link,
        to : "#",
        onClick : undefined
    }
    if(button) {
        props.onClick = onClick
    }else props.to = String(route);
    return (
        <Link  {...props}>
            <KeyboardBackspace /> {display}
        </Link>
    )
}