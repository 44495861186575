import { Suspense } from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import { CustomSnackbar, CustomNotifier, CustomPopover } from "../includes";
import { AppRoutes, AppStyleProps } from "../utils";
import Header from "./structures/Header/Header";
import { makeStyles } from '@material-ui/core/styles';
import { RouteMonitor } from "./structures";

const useStyles = makeStyles(({colors, breakpoints} : AppStyleProps) => ({
    mobile: {
        [breakpoints.down("sm")]: {
            marginBottom: 70
        },
    },
    loading : {
        minHeight : 400,
        display : "flex",
        alignItems : 'center',
        justifyContent : "center",
        fontWeight : "bold",
        fontSize : 24,
    }
}));

export default function Layout({routes} : {routes : AppRoutes[]}){
    const classes = useStyles();
    return (
        <>
          <Router>
            <Header/>
            <Suspense fallback={<div className={classes.loading}>loading...</div>}>
                <Switch>
                    {routes && routes.map((route : AppRoutes, index)=>{
                        const {component : Component, path, exact} = route;
                        return <Route key={index} exact={exact} path={path} component={(routeProps : any)=>{
                            return <div className={classes.mobile}><Component {...routeProps}/></div>
                        }}/>
                    })}
                </Switch>
            </Suspense>
            <Route path="/" component={RouteMonitor}/>
           </Router>
            <CustomSnackbar/>
            <CustomNotifier/>
            <CustomPopover/>
        </>
    )
}
