import ReactGA from "react-ga4";

export const useGaEvents = () => {

    const events = (category: string | "", action: string | "", label: string | "") => {
     ReactGA.event({
        category,
        action,
        label,
    });
  }

  return events;
}