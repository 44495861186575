import { GoogleSheetApi } from '.';

// emeka:
// 41df7277-512d-4b5c-be0c-420b10b07974
// Mike: 
// 95260717-48be-4dd2-9d1f-a3038879c62d

const url = '/a00152bf-94ee-43b4-b741-810c0d65cc9c/tabs/';
const getSheetUrl = (sheetName: string): string => {
    return url + sheetName;
} 
const request = GoogleSheetApi();


export const getAllRows = (sheetName: string, raw = false) => {
    if(raw) return request.getRows(getSheetUrl(sheetName)+'?_raw=1').then(response => response);
    return request.getRows(url).then(response => response);
}

export const addRows = (sheetName: string,data: any) => {
    return request.addRows(getSheetUrl(sheetName), data).then(response => response);
}

export const updateRows = (sheetName: string, data: object, column: number) => {
    return request.updateRow(getSheetUrl(sheetName)+'/'+column, data).then(response => response);
}

export const deleteRow = (sheetName: string, column: number) => {
    return request.removeRow(getSheetUrl(sheetName)+'/'+column).then(response => response);
}

export const filterRows = (sheetName: string, column: string, search: string) => {
    if(column && search !== ''){
        return request.getRows(getSheetUrl(sheetName)+'/'+column+'/*'+search+'*').then(response => response);
    }
}

export const paginateRows = (sheetName: string, limit: number, offset: number) => {
    
    return request.getRows(getSheetUrl(sheetName)+'?_limit='+limit+'&_offset='+offset).then(response => response);
}