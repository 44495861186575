import { useDispatch, useSelector } from 'react-redux'
import { List, ListItem, makeStyles, Popover } from '@material-ui/core'
import { AppStyleProps, ReduxStoreState } from '../../utils'
import { customPopoverActions } from './actions'
import { CustomPopoverState, CustomPopoverDispatch} from './types'
import classNames from 'classnames';
const style = makeStyles((theme : AppStyleProps) => ({
    root : {},
    popover : {
        backgroundColor : theme.colors.primary,
        width : "auto"
    },
    button : {},
}))
export default function CustomPopover() {
    const dispatch = useDispatch<CustomPopoverDispatch>(),
    customPopoverReducer = useSelector((state : ReduxStoreState) : CustomPopoverState =>state.customPopoverReducer),
    {
        anchorEl : _anchorEl, popoverId, 
        anchorOrigin, customComponent : CustomComponent, 
        customListItem : CustomListItem, 
        simpleListItem : SimpleListItem,
        simpleItemClick,
        onCloseCallback, props, position, options,
        classes : _classes = { }
    } = customPopoverReducer || {},
    classes = style();
    let anchorEl = _anchorEl as HTMLElement | null;
    while(anchorEl && anchorEl?.id !== popoverId)
        anchorEl = anchorEl?.parentElement as HTMLElement;
    return <Popover
            open={Boolean(anchorEl)}
            onClose={() => {
                customPopoverActions.close(dispatch);
                onCloseCallback && onCloseCallback();
            }}
            anchorEl={anchorEl && anchorEl}
            anchorOrigin={{
                vertical : 35,
                horizontal : "center",
                ...anchorOrigin
            }}
            anchorPosition={position}
            classes={{
                paper : classNames(classes.popover, _classes.paper),
            }}
            {...props}
        >
            {CustomComponent ? <CustomComponent/>: <List>
                {options && options.map((item : any, index : number) => {
                    return CustomListItem ? <CustomListItem key={index} item={item}/> : 
                    <ListItem className={_classes.listItem} onClick={() => simpleItemClick && simpleItemClick(item)} button key={index}>
                        {SimpleListItem && <SimpleListItem item={item}/>}
                    </ListItem>
                })}
            </List>}
    </Popover>
    
}
export {CustomPopover};