import { ReduxActionReturnValue, WalletState } from "../../../../../../utils"

export const walletConstant = {
    SET_WALLET : "SET_WALLET"
}
export const walletReducer = (state = {} as Partial<WalletState>, action : ReduxActionReturnValue<Partial<WalletState>>) : Partial<WalletState> => {
    const {type, payload} = action;
    switch(type){
        case walletConstant.SET_WALLET : 
            return {...state, ...payload};
        default : return {...state};
    }
} 