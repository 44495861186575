/**
 * using host type to differentiate db domain
 */
// eslint-disable-next-line
const liveLink = process.env.REACT_APP_HOSTTYPE === "test" ?
"" : "";

export const appValues_production = {
    
}
export const appValues_development = {

}