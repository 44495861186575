import { UtilAction, UtilState } from "../../../../utils"

export type UtilConstants = {
    SET_UTIL : string
}

export type Utilstrings = {
    refreshCount: string,
    refAddress : string
}
export const utilConstant : UtilConstants = {
    SET_UTIL : "SET_UILT",
}

export const utilStrings: Utilstrings = {
    refreshCount: "refreshCount",
    refAddress : "refAddress"
}
export const utilReducer = (
    state = {
        [utilStrings.refreshCount]: 0,
    } as Partial<UtilState>, 
    action : UtilAction
) : Partial<UtilState> => {
    const {type, payload} = action;
    switch(type){
        case utilConstant.SET_UTIL : 
            return {...state, ...payload};
        default : return {...state};
    }
}
export const UTIL_REDUCER_NAME = "utilReducer";
export {utilReducer as default};