import Web3 from "web3";
import { ReduxStoreState, WalletState,store,abis } from '..';
import { fromWei } from "../../components/NFTs/utils";


export const convertAmount = (amount: any) => {
    return Web3.utils.toWei(amount, 'ether');
}
export default function minePadContract (instance: any = undefined) {
    const storeState = store.getState() as Partial<ReduxStoreState>,
    { walletReducer } = storeState,
    { contracts, address, networkId,web3} = walletReducer as WalletState;
    if(!instance)
        instance = contracts?.launchPad;

        console.log({address})
    
        return {
            approve : async (recieverAddress : any, value : any) : Promise<any> => {
                return await instance?.methods?.approve(
                    recieverAddress, convertAmount(value)
                ).send({from : address })
            },
            min : async () :Promise<any> => {
                let min = await instance?.methods?._min().call()
                return  fromWei(min)
            },
            totalDeposited : async () :Promise<any> => {
                let totalDeposited = await instance?.methods?.totalDeposited().call()
                return  fromWei(totalDeposited)
            },
            max : async () :Promise<any> => {
                let max = await instance?.methods?._max().call()
                console.log({max})
                return  fromWei(max)
            },
            roi : async () :Promise<any> => {

                let roi = await instance?.methods?._roi().call()
                return  roi
            },
            dailyROI : async (val:string) :Promise<any> => {
                let dailyROI = await instance?.methods?.DailyRoi(val).call()
                return  fromWei(dailyROI)
            },
            investments : async () :Promise<any> => {
                let investments = await instance?.methods?.investments(address).call()
                return investments
            },
            approvalCheck : async (userAddress: string, contractAddress: any) : Promise<any> => {
                const allowanceAmount = await instance?.methods?.allowance(userAddress, contractAddress).call();
                console.log({allowanceAmount})
                return convertAmount(allowanceAmount);
            },
            currentAmountDistributed : async () : Promise<any> => {
                const currentAmountDistributed = await instance?.methods?.currentAmountDistributed().call();
                return fromWei(currentAmountDistributed);
            },
            approveContract: (recieverAddress:any,value:any) : any =>{
                return instance?.methods?.approve(
                    recieverAddress, value
                ).send({from : address })
            },
            deposit :  (value:any,ref:string) : any => {
                return  instance?.methods?.deposit(
                    ref,
                    convertAmount(value)
                ).send({from : address })
            },
            getConfimation:async(data:any) => {
                const { confirmations, status, logs } = await data.wait(1);
                return { confirmations, status, logs };
            },
            getTotalToken:async() => {
                const totalToken = await instance?.methods?.getBalance().call()
                return fromWei(totalToken)
            },
            getTotalRewards:async(userAddress?:string) => {
                try{
                    const totalRewards = await instance?.methods?.totalRewards(userAddress ?? address).call()
                console.log({totalRewards})
                return totalRewards  
                }catch(e){
                    console.log(e)
                }
              
            },
            approvedWithdrawal:async() => {
                try{
                    const approvedWithdrawal = await instance?.methods?.approvedWithdrawal(address).call()
                console.log({approvedWithdrawal})
                return approvedWithdrawal  
                }catch(e){
                    console.log(e)
                }
              
            },
            withdrawalFee: async () => {
                const fee = await instance?.methods?._withdraw_fee().call()
                return fee
            },
            withdrawal: () => {
                return instance?.methods?.withdrawal().send({from : address })
            },
            getVestingPeriod: async () => {
                const period = await instance?.methods?.getVestingPeriod().call()
                return period
            },
            getToken:async() => {
                const token = await instance?.methods?.tokenAllocationAdress().call()
                const tokenContract = new web3.eth.Contract(abis.erc20,token)
                const tokenName = await tokenContract?.methods?.name().call();                
                const tokenSymbol = await tokenContract?.methods?.symbol().call();                
                const tokenDecimal = await tokenContract?.methods?.decimals().call();                
                const totalSupply = await tokenContract?.methods?.totalSupply().call();                
                return {tokenName,tokenSymbol, tokenDecimal,totalSupply,tokenAddress:token}
            },
            vesting: async () => {
                const vesting = await instance?.methods?.vesting(address).call()
                return vesting
            },
            claimDailyReward: () => {
                return instance?.methods?.claimDailyRewards().send({from : address })
            },
            claimAllocationReward: (nonce:number) => {
                // console.log({nonce})
                return instance?.methods?.claimAllocationReward(nonce).send({from : address })
            },
            getPrice:async ()=>{
                const listingPrice = await instance?.methods?.allocate().call()
                return listingPrice /100
            },
            withdrawReferralReward: () => {
                return  instance?.methods?.Ref_Withdraw().send({from : address })
            },
            refTotalWithdrawal: async () => {
                try{
                     const refTotalWithdrawal = await instance?.methods?.refTotalWithdraw(address).call()
                return refTotalWithdrawal
                }catch(e){
                    console.log({e})
                }
               
            },
            totalWithdrawal: async () => {
                try{
                  const totalWithdrawal = await instance?.methods?.totalWithdraw(address).call()
                return fromWei(totalWithdrawal.amount)  
                }catch(e){
                    console.log(e)
                }
                
            },
            referral: async () => {
                try{
                   const referral = await instance?.methods?.refferal(address).call()
                return referral 
                }catch(e){
                    console.log(e)
                }
                
            },
            claimTime: async () => {
                try{
                  const claimTime = await instance?.methods?.claimTime(address).call()
                return claimTime  
                }catch(e){
                    console.log(e)
                }
                
            },
            withdrawWeekly: async () => {
                try{
                    const weekly =await instance?.methods?.weekly(address).call()
                return weekly 
                }catch(e){
                    console.log(e)
                }
               
            },
            allocationFee: async () => {
                const allocationFee = await instance?.methods?._fee().call()
                return allocationFee
            },
            userReward: async () => {
                try{
                    const userReward = await instance?.methods?.userReward(address).call()
                return fromWei(userReward)
                }catch(e){
                    console.log(e)
                }
                
            }
        }

}

export {minePadContract}