import { Dispatch } from "redux";
import {utilConstant, utilStrings} from './config';
import { store } from ".";

export const utilAction = {
    payload : <T>(payload : T) => 
        (dispatch : Dispatch ) : {type : string, payload : T} => dispatch({
        type : utilConstant.SET_UTIL,
        payload
    }),
    quickRefresh: (dispatch: Dispatch)  => utilAction.payload({
        [utilStrings.refreshCount]: store.getState().utilReducer.refreshCount as number + 1
    })(dispatch)
}