import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles';
import Stack from '@mui/material/Stack';
import { Link, Typography } from '@material-ui/core';
import { LaunchPadStyleProps, launchPadRouteNames } from '../../../utils';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { OngoingProjects, FinishedProjects } from '../LaunchPadProject';

const style = makeStyles((theme: LaunchPadStyleProps) => ({
    root: {
        padding: "5rem",
        //marginBottom: "4rem",
        [theme.breakpoints.down('sm')]: {
            textAlign: "center",
            padding: "1rem",
        },
    },
    heading: {
        fontSize: "48px",
        fontFamily: "Clash Display",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "48px",
        color: theme.colors.textDefault,
        marginBottom: "1rem",
        //[theme.breakpoints.down('xl')]: {
        //    fontSize: "48px"
        //},
        [theme.breakpoints.down('sm')]: {
            fontSize: "40px"
        },
    },
    backToHome: {
        color: theme.colors.textDefault,
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'centre',        
    }
 
}))
type LaunchPadProjectsListingProps = Partial<{
    finishedProjects : any, 
    ongoingProjects : any
}>
const LaunchPadProjectsListing = ( 
    {finishedProjects = [], ongoingProjects = []} : LaunchPadProjectsListingProps 
) => {
    const classes = style();
    return (
        <section className={classes.root}>

            {ongoingProjects && ongoingProjects.length > 0 && <>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                >
                    <h2 className={classes.heading}>
                        Ongoing Projects
                    </h2>
                    <Link href={launchPadRouteNames.buyRGP} underline="none" className={classes.backToHome}>
                    see All   <ArrowRightAltIcon sx={{ pr: 1.5 }} />
                    </Link>
                </Stack>
                <OngoingProjects items={ongoingProjects} />
            </>}
            {finishedProjects && finishedProjects.length > 0 && <>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                >
                    <h2 className={classes.heading}>
                        Finished Projects
                    </h2>
                    <Link href={launchPadRouteNames.buyRGP} underline="none" className={classes.backToHome}>
                        see All  <ArrowRightAltIcon sx={{ pr: 1.5 }} />
                    </Link>

                </Stack>
                <FinishedProjects items={finishedProjects} />
            </>}
        </section>
    )
}

export default LaunchPadProjectsListing