import { CustomSnackbarDispatch, CustomSnackbarState } from ".";
import { snackbarConstants } from "./config";

export const snackbarActions = {
    close : (dispatch : CustomSnackbarDispatch) => {
        snackbarActions.payload({
            message : "",
            snackbarProps : {
                open : false,
            },
        })(dispatch)
    },
    successNotice : 
        (message : string, option = {} as Omit<CustomSnackbarState, "message">) => 
        (dispatch : CustomSnackbarDispatch) => {
        const {
            snackbarProps,
            alertProps, //bring this out and omit severity
            ..._option
        } = option;
        snackbarActions.payload({
            message, alertProps : {
                severity : "success",
            },
            snackbarProps : {
                open : true,
                autoHideDuration : 10000,
                ...snackbarProps
            },
            ..._option
        })(dispatch)
    },
    warningNotice : 
        (message : string, option = {} as Omit<CustomSnackbarState, "message">) => 
        (dispatch : CustomSnackbarDispatch) => {
        snackbarActions.payload({
            message,
            alertProps : {
                ...option.alertProps,
                severity : "warning",
            },
            snackbarProps : {
                open : true,
                autoHideDuration : 10000,
                ...option.snackbarProps
            },
        })(dispatch)
    },
    errorNotice : 
        (message : string, option = {} as Omit<CustomSnackbarState, "message">) => 
        (dispatch : CustomSnackbarDispatch) => {
        snackbarActions.payload({
            message,
            alertProps : {
                ...option.alertProps,
                severity : "error"
            },
            snackbarProps : {
                open : true,
                autoHideDuration : 10000
            },
        })(dispatch)
    },
    payload : (payload : Partial<CustomSnackbarState>) => (dispatch : CustomSnackbarDispatch) => dispatch({
        type : snackbarConstants.SET_SNACKBAR,
        payload
    })
}
export const successToast = snackbarActions.successNotice;
export const errorToast = snackbarActions.errorNotice;
export const warningToast = snackbarActions.warningNotice;