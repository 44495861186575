import { AllowedNetworks } from '../index';
import { store, WalletAddresses, BuyRGPTypes } from '..';
import { walletActions } from "../../components/structures/Web3Provider/actions";

function getNetNames(networkId : number){
    switch(networkId) {
        case 1 : return "Ethereum Mainnet";
        case 3 : return "Ropsten Test Network";
        case 4 : return "Rinkeby Test Network";
        case 5 : return "Goerli Test Network";
        case 42 : return "Kovan Test Network";
        case 56 : return "Binance Smart Chain Network";
        case 97 : return "Binance Smart Chain Test Network";
        case 137 : return "Polygon Chain";
        case 80001 : return "Polygon Mumbai";
        default : return "Unknown"
    }
}
const walletUtil = {
    disconnectUser : async () => {
        const {utilReducer} = store.getState(),
        {
            storageNetworkID, storageNetworkType, storageNetworkWallet,
            storageTokenContract
        } = utilReducer;
        localStorage.removeItem(String(storageNetworkID));
        localStorage.removeItem(String(storageNetworkType));
        localStorage.removeItem(String(storageNetworkWallet));
        localStorage.removeItem(String(storageTokenContract));
        walletActions.payload({
            networkId: undefined,
            walletType : undefined,
            networkType: undefined, web3: undefined, address: undefined,
            contracts : undefined,
        })(store.dispatch)
    },
    SUPPORTED_NETWOR_IDS : [
        56, 97, 80001, 137
    ],
    MAINNETS : [
        56, 137
    ],
    ETHEREUM_NETWORK_IDS : [
        1,3,4,
    ],
    BINANCE_NETWORK_IDS : [
        97,56
    ],
    POLYGON_NETWORK_IDS : [
        80001, 137
    ],
    MAIN_NETS : [
        1,56
    ],
    isSupportedNetwork : (networkId : number) => {
        return walletUtil.SUPPORTED_NETWOR_IDS.indexOf(networkId) !== -1 || (process.env.NODE_ENV === "development" /* && String(networkId).length >=13 */)
    },
    TRUFFLE_NETWORK_NAMES : {
        MAIN : "main",
        ROPSTEN : "ropsten",
        RINKEBY : "rinkeby",
        GOERLI : "goerli",
        KOVAN : "kovan",
        BSC : "bsc",
        BSC_TESTNET : "bsc_test",
        POLYGON_MUMBAI : "polygon_mumbai",
        POLYGON : "polygon",
        DEVELOPMENT : "development",
    },
    getNetNames,
    networkList : [{
        networkId : 56,
        name : getNetNames(56),
        image : "/header/bnb.png"
    }, {
        networkId : 97,
        name : getNetNames(97),
        image : "/header/bnb.png"
    }, {
        networkId : 137,
        name : getNetNames(137),
        image : "/assets/polygon.png"
    }, {
        networkId : 80001,
        name : getNetNames(80001),
        image : "/assets/polygon.png"
    }],
    getNetworkIdByName : (name : keyof AllowedNetworks, defaultNet : number) : number => {
        switch(name){
            case "bsc" : return 56;
            case "bsc_test" : return 97;
            default : return defaultNet;
        }
    },
    getNetwork : (network : keyof AllowedNetworks) => {
        switch(network) {
            case "bsc_test" : return "BSC Test";
            case "bsc" : return "BSC";
            case "polygon" : return "POLYGON";
            case "polygon_mumbai" : return "POLYGON_MUMBAI";
            default : return "Unknown"
        }
    },
    getNetShortNames : (networkId : number) => {
        switch(networkId) {
            case 1 : return "Ethereum";
            case 3 : return "Ropsten";
            case 4 : return "Rinkeby";
            case 5 : return "Goerli";
            case 42 : return "Kovan";
            case 56 : return "BSC";
            case 97 : return "BSC Test";
            case 137 : return "POLYGON";
            case 80001 : return "POLYGON MUMBAI"
            default : return "Unknown"
        }
    },
    isLocalNetwork : (networkId : number) => {
        return String(networkId).length > 6
    },
    getTruffleNetworkName : (networkId : number ) : keyof AllowedNetworks | "development" => {
        switch(networkId) {
            case 56 : return 'bsc';
            case 97 : return 'bsc_test';
            case 137 : return "polygon";
            case 80001 : return "polygon_mumbai";
            case 0 : return 'development';
            default : return 'bsc_test';
        }
    },
    WALLETS : {
        METAMASK : "metamask",
        TRUST_WALLET  : "trust",
        GO_WALLET : "goWallet",
        ALPHA_WALLET : "alphaWallet",
        STATUS : "status",
        COINBASE : "coinbase",
        CIPHER : "cipher",
        MIST : "mist",
        PARITY : "parity",
        INFURA : "infura",
        LOCAL_HOST : "wallet",
    },
    getWalletNames : (wallet : string) => walletUtil.walletNameMapping()[wallet],
    walletNameMapping : () => ({
        [walletUtil.WALLETS.METAMASK] : "Metamask",
        [walletUtil.WALLETS.TRUST_WALLET] : "Trust Wallet",
        [walletUtil.WALLETS.GO_WALLET] : "Go Wallet",
        [walletUtil.WALLETS.ALPHA_WALLET] : "Alpha Wallet",
        [walletUtil.WALLETS.STATUS] : "Status Wallet",
        [walletUtil.WALLETS.COINBASE] : "Coinbase Wallet",
        [walletUtil.WALLETS.CIPHER] : "Cipher Wallet",
        [walletUtil.WALLETS.MIST] : "Mist Wallet",
        [walletUtil.WALLETS.PARITY] : "Parity Wallet",
        [walletUtil.WALLETS.INFURA] : "Infura Wallet",
        [walletUtil.WALLETS.LOCAL_HOST] : "Local Wallet",
    }),
    getCurrentProvider : () => {
        // if(!window.ethereum && !window.web3) return;
        // const web3 = window.ethereum || window.web3;
        // if(!web3) return "wallet";
        // else if(web3?.isMetaMask)
        //     return walletUtil.WALLETS.METAMASK;
        // else if(web3?.isTrust)
        //     return walletUtil.WALLETS.TRUST_WALLET;
        // else if(web3?.isGoWallet)
        //     return walletUtil.WALLETS.GO_WALLET;
        // else if(web3?.isAlphaWallet)
        //     return walletUtil.WALLETS.ALPHA_WALLET;
        // else if(web3?.isStatus)
        //     return walletUtil.WALLETS.STATUS;
        // else if(web3?.isToshi)
        //     return walletUtil.WALLETS.COINBASE;
        // else if(typeof window.__CIPHER__ !== "undefined")
        //     return walletUtil.WALLETS.CIPHER;
        // else if(web3.currentProvider?.currentProvider?.constructor?.name === "EthereumProvider")
        //     return walletUtil.WALLETS.PARITY;
        // else if(web3.currentProvider?.currentProvider?.host?.indexOf("infura") !== -1)
        //     return walletUtil.WALLETS.INFURA;
        // else if(web3.currentProvider?.currentProvider?.host?.indexOf("localhost") !== -1)
        //     return walletUtil.WALLETS.LOCAL_HOST;
    },
    getBalance : async () : Promise<number> => {
        const {address, web3} = store.getState().walletReducer;
        const balance =  web3 && await web3.eth.getBalance(address);
        return balance / 10 ** 18;
    },
    shortAddress : (address : string) : string => {
        if(!address) return "...";
        return String(address).substr(0, 4) +
            "..." +
        String(address).substr(address.length - 4, 4)
    },
    shortenLink : (parsed : string,chars:number=14) : string => {
        if(!parsed) return "...";
        return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`
    },
    getContractType : (storageTokenContract : string | null) :  keyof BuyRGPTypes => {
        try{
            if(!storageTokenContract){
                storageTokenContract = store.getState().utilReducer?.storageTokenContract as string;
            }
            return localStorage.getItem(storageTokenContract) as keyof BuyRGPTypes || "usdt_rgp";
        }catch(error){
            console.error(error);
            return "usdt_rgp"
        }
    },
    switchNetwork : async (ethereum : any, chainId : string, network : any) => {
        try{
            await ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId }],
            });
        } catch (switchError : any) {
            const { message } = switchError || {};
            
                //This error code indicates that the chain has not been added to MetaMask.
            if (switchError.code === 4902) {
                try {
                    await ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [network],
                    });
                } catch (addError) {
                    // handle "add" error
                    console.error(addError);
                }
            }
        }
    },
    watchToken : async (
        ethereum : any,
        tokenAddress : string,
        imageUrl : string,
        tokenType : string = "ERC20",
        symbol : string
    ) => {
        ethereum.request({
            method: 'wallet_watchAsset',
            params: {
                type: tokenType,
                options: {
                    address: tokenAddress,
                    symbol,
                    decimals: 18,
                    image: imageUrl,
                },
            },
        })
        .then((success : any) => {
            if (success) {
                console.log(success)
            } else {
                throw new Error('Something went wrong.')
            }
        })
        .catch(console.error)
    }
}
export default walletUtil;
export { walletUtil }
