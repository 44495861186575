import { makeStyles } from "@material-ui/core";
import { AppStyleProps, LaunchPadStyleProps} from '../../../utils';
import { ButtonBase } from '@material-ui/core';

const styles = makeStyles((theme : LaunchPadStyleProps)=>({
    root: {
        color: theme.colors.textDefault,
        textAlign: "center",
        position: "relative",
        transformStyle: "preserve-3d",
        padding: "5rem",
        boxSizing: "border-box",
        [theme.breakpoints.down('md')]: {
            paddingRight: "2.5rem",
            paddingLeft: "2.5rem",
        },
        [theme.breakpoints.down('sm')]: {
            paddingRight: "1rem",
            paddingLeft: "1rem",
        }
    },
    journeyHeading: {
        fontSize: "40px",
        fontStyle: "normal",
        fontWeight: 600,
        color: theme.colors.text,
        marginBottom: "1rem",
        textShadow: "0px 1px 10px rgba(255, 255, 255, 0.25)",
        [theme.breakpoints.down('lg')]: {
            fontSize: "40px",
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "32px",
        }
    },
    journeyText: {
        fontSize: "16px",
        fontFamily: "DM Sans",
        fontStyle: "normal",
        fontWeight: "normal",
        color: theme.colors.textDefault2,
        lineHeight: "24px",
        opacity: .8,
        //marginBottom: "2.5rem",
        maxWidth: "56.9%",
        margin: "0 auto 2.5rem",
        [theme.breakpoints.down('xl')]: {
            fontSize: "16px",
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "16px",
            maxWidth: "100%",
        }


    },
    journeyBtn: {
        backgroundImage: " linear-gradient(90deg, #2691C1 0%, #045CA6 42.71%, #055DA6 60.42%, #238CBE 100%)",
        boxShadow: "0px 1px 5px rgba(51, 51, 153, 0.1)",
        color: theme.colors.textDefault,
        fontSize: "18px",
        width: "196px",
        height: "56px",
        fonWeight: "700",
        padding: "1.5rem 5rem",
        borderRadius: "6px",
        outline: "none",
        [theme.breakpoints.down('xl')]: {
            fontSize: "18px",
            padding: ".7rem 1.5rem",
            borderRadius: ".5rem",
        }
    }
}))

export default function LaunchPadDefiJourney() {
    const classes = styles();

   

    return (
        <section className = {classes.root}>
            <h1 className = {classes.journeyHeading}>
                Start your defi journey
            </h1>
            <p className = {classes.journeyText}>
                Build your defi portfolio with DApps that guarantee you fast transaction times, low transaction fees and the best user experience.
            </p>
            <ButtonBase 
                
                className = {classes.journeyBtn}>
                    Launch DApp
            </ButtonBase>
        </section>
    )
}