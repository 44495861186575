import React, { ReactElement } from 'react';
import { DappVersion } from './common';
import {BuyRGPAppV1} from './v1'
export default function BuyRGPApp ({dappVersion} :  {dappVersion : DappVersion}) {
    let App : ReactElement;
    switch(dappVersion) {
        case "v1": App = <BuyRGPAppV1/> ; break;
        default : App = <div>Invalid Version</div>
    }
    return App;
}
export { BuyRGPApp }