import { CustomNotifierAction, CustomNotifierState } from "./types";

export const customNotifierConstants = {
    SET_CUSTOM_NOTIFIER : "SET_CUSTOM_NOTIFIER"
}
export const customNotifierReducer = (
    state={} as Partial<CustomNotifierState>,
    action : CustomNotifierAction
) : Partial<CustomNotifierState> => {
    const {type, payload} = action;
    switch(type){
        case customNotifierConstants.SET_CUSTOM_NOTIFIER : 
            return {...state, ...payload};
        default : return {...state};
    }
}