import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { AppStyleProps } from '../../../../utils';
import classNames from 'classnames';

const useStyles = makeStyles(({colors, breakpoints} : AppStyleProps) => ({
  root: {
    maxWidth: 260,
    backgroundColor: colors.modalBG,
    border: `1px solid ${colors.borderOutline}`,
    color: colors.text,
  },
  text: {
    color: colors.textSecondary,
  },
  icon: {
    color: colors.text,
  },
  hover: {
    '&:hover': {
      background: colors.disabledBG,
      color: colors.text
   },
  }
}));


export default function Menu(props: {anchorEl: HTMLElement | null, handleClose(): void, network: { id: number, name: string, icon: any | null, link: string }[]}) {
  const classes = useStyles(); 

  const open = Boolean(props.anchorEl);
  const id = open ? 'menu-popover' : undefined;
    
  return (
    <div>
      <Popover
        id={id}
        open={open}
        anchorEl={props.anchorEl}
        onClose={props.handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List component="nav" aria-label="secondary mailbox folders" className={classes.root}>
        {
            props.network.map(
              (value) =>
                <ListItem component="a" target="_blank" href={value.link} key={value.id} className={classNames(classes.text, classes.hover)} onClick={props.handleClose}>
                   { value.icon && <ListItemIcon className={classes.icon}> {value.icon} </ListItemIcon> }
                    <ListItemText primary={value.name} />
                </ListItem>
            )
        }
        </List>
      </Popover>
    </div>
  );
}
