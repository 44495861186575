import { useState } from 'react';
import { makeStyles, useTheme, ButtonBase  } from '@material-ui/core';
import { AppStyleProps } from '../../../../utils';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { ExpandMore, ExpandLess, OpenInNew } from "@material-ui/icons";
import { customPopoverActions, CustomPopoverDispatch } from '../../../../includes';
import { useDispatch } from 'react-redux';


const style = makeStyles(({colors, breakpoints} : AppStyleProps) => ({
  root : {
    //backgroundColor: "#000C15",
    paddingLeft: "4rem",
    paddingRight: "4rem",
    height: "5rem",
    position:"fixed",
    [breakpoints.down('sm')]: {
      paddingLeft: "0.4rem",
      paddingRight: "0.4rem",
    }
    
  },
  toolbar: {
    backgroundColor: "#000C15",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 0,
  },
  navlinks: {
    marginLeft: "32px",
    display:"flex",
  },
  linkLabel: {
    cursor: "pointer",
    '&:hover': {
      color: "#9BD0FD"
    },
  },
  headerBtn: {
    backgroundImage: "linear-gradient(90deg, #2691C1 0%, #045CA6 42.71%, #055DA6 60.42%, #238CBE 100%)",
    color: "#fff",
    fontSize: "16px",
    fonWeight: "700",
    padding: ".8rem 2rem",
    height: "48px",
    borderRadius: "6px",
    marginTop: "16px",
    marginBottom: "16px",
  },
  navLink: {
    cursor: "pointer",
    color: "#fff",
    textDecoration: "none",
    fontSize:"16px",
    display:"flex",
    justifyContent:"space-between",
    margin: "0 1.5rem",
    '&:hover': {
      color: "#9BD0FD"
    },
    "& > img":{
      marginTop:"4px",
      marginLeft:"2px"
    },
  },
  subList: {
    color:"white",
    background:"#041522",
    borderRadius:"6px",
  },
  listItem : {
    padding : "20px 30px",
    color : "white",
    "&:hover" : {
      background : "white",
      color : "black",
    }
  },
  primaryText : {
    textDecoration:"none",
    marginBottom : 10,
    fontSize:"16px",
  },
  secondaryText : {
    color:"#A7B3BE",
    fontSize: 14
  }
}))


const menuData = [
  {
    name : "Company",
    icon : ExpandMore,
    altIcon : ExpandLess,
    type : "menu",
    menuList : [
      { 
        header : "About us",
        subheader : "Learn more about rigelProtocol",
        url : "https://rigelprotocol.com/about.html",
      },{
        header : "Career",
        subheader : "See available positions",
        url : "https://rigelprotocol.com/career.html",
      }, {
        header : "Press resources",
        subheader : "Press and media",
        url : "https://rigelprotocol.com/press.html"
      }
    ]
  },{
    name : "Dapps",
    icon : ExpandMore,
    altIcon : ExpandLess,
    type : "menu",
    menuList : [
      {
        header : "SmartSwap",
        subheader : "Swap tokens directly",
        url : "https://smartswap.rigelprotocol.com"
      },{
        header : "Gift Dapp",
        subheader : "Gift token in a fun way",
        url : "https://gift.rigelprotocol.com"
      },{
        header : "SmartBid",
        subheader : "Bid on tokens",
        url : "https://smartswap.rigelprotocol.com/#/smartbid",
      },{
        header : "AutoPeriod",
        subheader : "Auto invests in any crypto of your choice",
        url : "https://smartswap.rigelprotocol.com/#/auto-period"
      },{
        header : "Farms",
        subheader : "Stake Liquidity Pair Tokens from any pool.",
        url : "https://smartswap.rigelprotocol.com/#/farming-v2"
      },{
        header : "Launchpad",
        subheader : "Join projects hosted on rigelProtocol",
        url : "/"
      }

    ]
  },{
    name : "Events",
    type : "link",
    link : "https://rigelprotocol.com/press"
  },{
    name : "Blogs",
    icon : OpenInNew,
    type : "link",
    link : "https://medium.com/rigelprotocol"
  }
]
export default function DesktopView (props: any) {
  const classes = style(),
  theme = useTheme(),
  popDispatch = useDispatch<CustomPopoverDispatch>();

  return (
        <Toolbar className = {classes.toolbar}>
               <img src="/header/darklogo.svg" alt="dark logo"/>
               <div className={classes.navlinks}>
                {menuData.map((item, index) => {
                  const {name, icon : MenuIcon, altIcon, type, link} = item,
                  _id = `menu_${name}`;
                  return <div id={_id} className={classes.navLink} 
                      onClick={({target}) => {
                        if(type === "link") return window.open(link)
                        const anchorEl = target as Element;
                        customPopoverActions.payload({
                          
                          popoverId : _id,
                          anchorEl : anchorEl as Element,
                          options : item.menuList,
                          simpleListItem : ({item}) => <div>
                            <div className={classes.primaryText}>{item.header}</div>
                            <div className={classes.secondaryText}>
                              {item.subheader}
                            </div>
                          </div>,
                          simpleItemClick : (item) => {
                            if(item.url !== "/")
                              window.open(item.url)
                            customPopoverActions.close(popDispatch)
                          },
                          classes : { 
                            paper : classes.subList,
                            listItem : classes.listItem
                          }
                        })(popDispatch)
                  }}>
                      <label className={classes.linkLabel}>{name}</label>
                      {MenuIcon && <MenuIcon />}
                    </div>
                })}
              </div>

              <ButtonBase 
                id="big_button"
                className = {classes.headerBtn}
                onClick={({target})=> {
                        const item = menuData[1];
                        const anchorEl = target as Element;
                        customPopoverActions.payload({
                          popoverId : "big_button",
                          anchorEl : anchorEl as Element,
                          options : item.menuList,
                          simpleListItem : ({item}) => <div>
                            <div className={classes.primaryText}>{item.header}</div>
                            <div className={classes.secondaryText}>
                              {item.subheader}
                            </div>
                          </div>,
                          simpleItemClick : (item) => {
                            window.open(item.url)
                            customPopoverActions.close(popDispatch)
                          },
                          classes : { 
                            paper : classes.subList,
                            listItem : classes.listItem
                          }
                        })(popDispatch)
                }}
                >
                  Launch DApp <ExpandMore/>
              </ButtonBase>
            </Toolbar>
  )
}

export { DesktopView };