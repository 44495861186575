export type LaunchPadTheme = {
    pageBackground : string,
    primary : string,
    borderOutline : string,
    success : string,
    danger : string,
    text : string,
    textSecondary : string,
    textDefault :string,
    textHighlight : string,
    modalBG : string,
    modalOutline : string,
    maxButtonBG : string,
    headerDefault: string,
    textGrey:string,
    subHeadingDefault: string,
    textDefault2: string,
    FeaturedBackgroud: string
}
const colors = {
    page : "#000C15",
    highlight : "#4CAFFF",
    outline : "#324D68",
    primary : "#213345",
    success : "#75F083",
    danger : "#FF3358",
    modalBG : "#15202B",
    modalOutline : "#DEE5ED",
    textDefault : "#FFFFFF",
    textDefault2: "#F3F4F7",
    headerDefault: "##F1F5F8",
    light : "#F1F5F8",
    light1 : "#7599BD",
    light2 : "#EAF6FF",
    textGrey: "#D5D5F6",
    subHeadingDefault: "#A7B3BE",
    FeaturedBackgroud: "#011C32"
}
export const launchPadTheme : LaunchPadTheme= {
    pageBackground : colors.page,

    primary : colors.primary, 

    borderOutline : colors.outline,
    success : colors.success,
    danger : colors.danger,

    text : colors.light,
    textSecondary : colors.light1,
    textHighlight : colors.highlight,

    modalBG : colors.modalBG,
    modalOutline : colors.modalOutline,
    textDefault: colors.textDefault,
    maxButtonBG : colors.light2,
    headerDefault:colors.headerDefault,
    textGrey: colors.textGrey,
    subHeadingDefault:colors.subHeadingDefault,
    textDefault2: colors.textDefault2,
    FeaturedBackgroud: colors.FeaturedBackgroud
}