import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles, Snackbar as CoreSnackbar} from '@material-ui/core'
import { Alert as MuiAlert} from "@material-ui/lab";
import { snackbarActions } from './actions';
import { CustomSnackbarState } from '.';
const style = makeStyles(theme => ({
    root : {
    }
}))
function Alert(props : any) : JSX.Element{
    return <MuiAlert elevation={6} variant="filled" {...props}/>
}
function CustomSnackbar() {
    const 
    dispatch = useDispatch(),
    {message, snackbarProps, alertProps} = useSelector((state : any & {snackbarReducer : CustomSnackbarState })=>state.snackbarReducer),
    onClose = () => snackbarActions.close(dispatch),
    classes = style();
    return (
        <div className={classes.root}>
            <CoreSnackbar anchorOrigin={{
                vertical : "top",
                horizontal : "right"
            }} {...snackbarProps} onClose={onClose}>
                <Alert {...alertProps}>{message}</Alert>
            </CoreSnackbar>
        </div>
    )
}
CustomSnackbar.propTypes = {
    message : PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number,]),
    snackbarProps : PropTypes.shape({
        open : PropTypes.bool,
        autoHideDuration : PropTypes.number,
        onClose : PropTypes.func,
    }),
    alertProps : PropTypes.shape({
        onClose : PropTypes.func,
        severity : PropTypes.oneOf(["warning", "success", "info", "error"]),
    })
}
export default CustomSnackbar;
export {CustomSnackbar};