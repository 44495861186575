import {Grid,GridSize} from '@material-ui/core'
import classNames from 'classnames';
import { AppStyleProps} from '../../utils'
import { makeStyles } from '@material-ui/styles';


const style = makeStyles(({colors, isDark} : AppStyleProps) => ({
    root : {
        fontSize : "1rem",
        width: "100%",
    },
    step: {
        color : colors.blue,
        margin: 0,
        paddingRight: 0,
    },
    stepNotDone: {
        color:  isDark ? colors.textSecondary : colors.gray,
    },
    stepDescription: {
        display: "block",
        marginBottom: "1rem",
    },
    stepBar: {
        display: "block",
        width: "100%",
        border: `4px solid`,
        backgroundColor:  colors.textHighlight
    },
    stepBarNotDone: {
        backgroundColor:  isDark ? colors.textSecondary : colors.gray,
    }
}))

type propTypes = {
    steps: String[],
    position: number
}

export default function CustomStepbar({steps, position}: propTypes) {
    const classes = style();

    let size: GridSize;
        if(steps.length === 2) size = 6
        else if(steps.length === 3) size = 4
        else if(steps.length === 4) size = 3
    return (
        <div className = {classes.root}>
            <Grid container spacing={2} className = {classes.root}>
                {steps.map((step, index) => {
                    return <Grid item xs={size} sm={size} md={size} lg={size} xl={size} className = {classNames(classes.step, {[classes.stepNotDone]: index >= position})}>
                        <span className = {classes.stepDescription}>
                            {`${index + 1}. ${step}`}
                        </span>
                        <span className = {classNames(classes.stepBar, {[classes.stepBarNotDone]: index >= position})}></span>
                    </Grid>
                })}
            </Grid>
                    
        </div>
    );
}

export {CustomStepbar};