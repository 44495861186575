import React, { MouseEventHandler, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import {
  AppStyleProps
} from '../../../../utils';
import { WalletTypes } from '../../../../utils';
import { Box, CircularProgress, LinearProgress } from '@material-ui/core';

type WalletObject = Partial<{
  id: number,
  title: string,
  icon: string,
  name: keyof WalletTypes
}>
const MetamaskIcon = "/header/metamask.png",
  TrustWalletIcon = "/header/trust_wallet.png",
  SafePalIcon = "/header/safepal.png",
  WalletConnectIcon = "/header/wallet_connect.png",

  wallet: WalletObject[] = [
    { id: 1, title: "MetaMask", icon: MetamaskIcon, name: "metamask" },
    { id: 2, title: "Trust Wallet", icon: TrustWalletIcon, name: "metamask" },
    { id: 3, title: "Safe Pal", icon: SafePalIcon, name: "metamask" },
    { id: 4, title: "Wallet Connect", icon: WalletConnectIcon, name: "walletConnect" },
  ]

const useStyles = makeStyles(({ colors, breakpoints, isDark }: AppStyleProps) => ({
  box: {
    display: "flex",
    justifyContent: `center`,
    alignItems: `center`,
    padding: `4px 6px`,
    borderRadius: `6px`,
    maxHeight: "40px",
    background: isDark ? colors.primary : colors.lightBlue,
    border: `1px solid ${colors.borderOutline}`,
  },
  connectionBtn: {
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    padding: `8px`,
    maxHeight: "40px",
    background: colors.textHighlight,
    color: colors.text,
    cursor: "pointer",
    borderRadius: `6px`,
  },
  transparent: {
    background: colors.pageBackground,
    border: "0px",
  },

  midiumlogo: {
    height: 20,
    width: 20,
  },
  cursor: {
    cursor: "pointer",
  },
  smallSpace: {
    margin: 4,
  },
  walletBalance: {
    display: "flex",
    background: colors.primary,
    marginLeft: "8px",
    borderRadius: `6px`,
    cursor: "pointer",
    border: `1px solid ${colors.borderOutline}`,
  },
  modalHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "1.5rem"
  },
  modalBody: {
    display: "flex",
    flexDirection: "column"
  },
  backBtn: {
    display: "flex",
    cursor: "pointer",
    justifyContent: "center",
    alignItems: "center",
    width: "1.2rem",
    height: "1.2rem",
    borderRadius: `6px`,
    border: `1px solid ${colors.text}`,
  },
  chooseNet: {
    fontWeight: 400,
    fontSize: "1rem",
    color: colors.text,
    lineHeight: "1.5rem",
    marginLeft: "0.5rem"
  },
  innerText: {
    fontWeight: 400,
    fontSize: "1rem",
    color: colors.text,
    lineHeight: "1.5rem",
    marginLeft: "0.5rem"
  },
  heading: {
    fontWeight: 400,
    fontSize: "1.25rem",
    color: colors.text,
    lineHeight: "1.75rem",
    marginBottom: "1rem",
  },

  selectBorder: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    background: "transparent",
    cursor: "pointer",
    border: `1px solid ${colors.borderOutline}`,
    '&:hover': {
      border: `1px solid ${colors.textHighlight}`,
    },
    boxSizing: "border-box",
    borderRadius: ".375rem",
    height: "4rem",
    padding: "1rem",
    marginBottom: "1rem",
  },
  selectBorderDisabled: {
    width: "100%",
    //display: "flex",
    alignItems: "center",
    background: "transparent",
    cursor: "pointer",
    border: `1px solid ${colors.borderOutline}`,
    '&:hover': {
      border: `1px solid ${colors.textHighlight}`,
    },
    boxSizing: "border-box",
    borderRadius: ".375rem",
    minHeight: "auto",
    padding: "1rem",
    marginBottom: "1rem",
    pointerEvents : 'none', 
    opacity:' 0.7'
  },

innerBox : {
  display : "flex",
  alignItems : "center",
},

  iconBack: {
    width: 10,
    color: colors.text,
    alignSelf: "center",
  }
}));


export function WalletList({ onSelect, onLoad }: Partial<{ onSelect: Function, onLoad: boolean }>) {

  const classes = useStyles();
  const [selectedItem, setSelectItem] = useState<WalletObject>()

  return (
    <div style={{ minWidth: 386 }}>
      <div className={classes.modalBody}>
        <div className={classes.heading}> {onLoad ? 'processing...' : 'Choose a Wallet'}</div>
        {
          wallet.map((item, index) =>
            <div  key={index} onClick={
              () => {
                onSelect && onSelect(item.name) as MouseEventHandler
                setSelectItem(item)
              }} 
              className={onLoad ? classes.selectBorderDisabled: classes.selectBorder}>
              <div className={classes.innerBox}>
              <img src={item.icon} className={classNames(classes.midiumlogo)} alt={item.title} />
              <div className={classNames(classes.innerText)}>{item.title}</div>
             
             </div>
              {onLoad && selectedItem?.id === item.id && 
               <LinearProgress style={{width : "100%", marginTop : 10}}/>
              }
            </div>
          )}
      </div>
    </div>
  );

}

export default WalletList




