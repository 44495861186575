import {useState} from 'react';
import { makeStyles, Drawer, IconButton, List, ListItem, ListItemText } from "@material-ui/core";
import { Menu,CloseRounded, OpenInNew, ExpandLess, ExpandMore } from '@material-ui/icons';
import { AppStyleProps } from '../../../../utils';
import Collapse from '@material-ui/core/Collapse';
import Toolbar from "@material-ui/core/Toolbar";

const style = makeStyles(({colors, breakpoints} : AppStyleProps) => ({
    root : {
        "& .MuiPaper-root": {
            backgroundColor: "#07071f",
            width: "70%",
        },
    },
    navLink: {
        color: "#fff",
        textDecoration: "none",
        cursor:"pointer",
        fontSize:"14px",
        display:"flex",
        justifyContent:"space-between",
    },
    toolbar: {
        backgroundColor: "#000C15",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 0,
        margin: 0,
    },
    iconBtn: {
        color: "#fff",
    },
    nested: {
        paddingLeft:"30px",
        color: "white",
        textDecoration: "none"
    },
    closeButton: {
          display:"flex",
          justifyContent:"right",
        '& > *' : {
            color: '#fff',
            cursor:"pointer"
        }
      }
    
  }))

  export default function DrawerComponent(props: any) {
    const classes = style(),
    [state, setState] = useState<any>({
        openDrawer: false,
        openNavList1: true,
        openNavList2: true,
    });

    return (
        <Toolbar className = {classes.toolbar}>
            <img src="/header/darklogo.svg" alt="dark logo"/>
            <IconButton onClick={() => setState({...state, openDrawer: true})} className = {classes.iconBtn}>
                <Menu />
            </IconButton>
            <Drawer
                className = {classes.root}
                open={state.openDrawer}
                onClose={() => setState({...state, OpenDrawer: false})}
            >
                
                <List>
                    <ListItem className={classes.closeButton}>
                        <CloseRounded  onClick={() => setState({...state, openDrawer: false})}/>
                    </ListItem>
                    <ListItem button onClick={() => setState({ ...state, openNavList1: !state.openNavList1})} className = {classes.navLink}>
                            <ListItemText primary="Company" />
                            {state.openNavList1 ? <ExpandLess /> : <ExpandMore /> }
                    </ListItem>
                        <Collapse in={state.openNavList1} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem component={"a"} href="https://rigelprotocol.com/about.html" target="_blank" className={classes.nested}>
                                    <ListItemText primary="About us" /> 
                                </ListItem>
                                <ListItem component={"a"} href="https://rigelprotocol.com/career.html" target="_blank" className={classes.nested}>
                                   <ListItemText primary="Careers" /> 
                                </ListItem>
                                <ListItem component={"a"} href="https://rigelprotocol.com/press.html" target="_blank" className={classes.nested}>
                                    <ListItemText primary="Press Resources" />
                                </ListItem>
                            </List>
                        </Collapse>

                    
                    <ListItem button onClick={() => setState({...state, openNavList2: !state.openNavList2})} className = {classes.navLink}>
                            <ListItemText primary="DApps" />
                            {state.openNavList2 ? <ExpandLess /> : <ExpandMore /> }
                    </ListItem>
                        <Collapse in={state.openNavList2} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem component={"a"} href="https://rigelprotocol.com/about.html" target="_blank" className={classes.nested}>
                                    <ListItemText primary="About us" /> 
                                </ListItem>
                                <ListItem component={"a"} href="https://rigelprotocol.com/career.html" target="_blank" className={classes.nested}>
                                   <ListItemText primary="Careers" /> 
                                </ListItem>
                                <ListItem component={"a"} href="https://rigelprotocol.com/press" target="_blank" className={classes.nested}>
                                    <ListItemText primary="Press Resources" />
                                </ListItem>
                                <ListItem component={"a"} href="https://gift.rigelprotocol.com/" target="_blank" className={classes.nested}>
                                    <ListItemText primary="Gift Dapp" />
                                </ListItem>
                                <ListItem component={"a"} href="#" target="_blank" className={classes.nested}>
                                    <ListItemText primary="Leverage Exchange" />
                                </ListItem>
                                <ListItem component={"a"} href="#" target="_blank" className={classes.nested}>
                                    <ListItemText primary="SmartBid" />
                                </ListItem>
                                <ListItem component={"a"} href="#" target="_blank" className={classes.nested}>
                                    <ListItemText primary="LaunchPad" />
                                </ListItem>
                            </List>
                        </Collapse>
                    
                        <ListItem component={"a"} href="https://rigelprotocol.com/events" target="_blank" className={classes.navLink}>
                            <ListItemText primary="Events" />
                        </ListItem>

                        <ListItem component={"a"} href="https://medium.com/rigelprotocol" target="_blank" className={classes.navLink}>
                            <ListItemText primary="Blog" /> <OpenInNew/> 
                        </ListItem>
                </List>
            </Drawer>
        </Toolbar>
    )
  }
  export { DrawerComponent };