import React, { MouseEvent, MouseEventHandler, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ButtonUnstyled, {
  buttonUnstyledClasses,
  ButtonUnstyledProps,
} from '@mui/base/ButtonUnstyled';
import RouteDark from './../../../../../../../../assets/routesquare-dark.svg';
import RouteLight from './../../../../../../../../assets/routesquare-light.svg';
import MetamaskLogo from './../../../../../../../../assets/metamask.svg';
import classNames from 'classnames';
import {
  AppStyleProps,
  walletUtil,
  ReduxStoreState, WalletState,
  NETWORKS, AllowedNetworks, WalletAddresses, UtilState,
  utilAction, STORAGE_VARIABLE, AppThemeValues, APP_THEME_TYPES
} from '../../../../../utils';
import {ExplorerDataType, getExplorerLink} from '../../../../../utils/commonFunctions';
import { CustomDialog} from '../../../../../includes';

const useStyles = makeStyles(({colors, breakpoints, isDark} : AppStyleProps) => ({
  box: {
    display: "flex",
    justifyContent: `center`,
    alignItems: `center`,
    padding: `4px 6px`,
    borderRadius: `6px`,
    maxHeight: "40px",
    background: isDark ? colors.primary : colors.lightBlue,
    border: `1px solid ${colors.borderOutline}`,
  },
  transparent: {
    background: colors.pageBackground,
    border: "0px",
  },

  midiumlogo: {
    height: 20,
    width: 20,
  },
  cursor: {
    cursor: "pointer",
  },
  smallSpace: {
    margin: 4,
  },
  walletBalance: {
    display: "flex",
    background: colors.primary,
    marginLeft: "8px",
    borderRadius: `6px`,
    cursor: "pointer",
    border: `1px solid ${colors.borderOutline}`,
  },
  modalHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "1.5rem"
  },
  modalBody: {
    display: "flex",
    flexDirection: "column",
  },
  dashBox: {
    padding: '26px',
    border: '1px dashed',
    borderColor: isDark ? '#4A739B' : '#DEE6ED',
    borderRadius: '6px',
    fontSize: '16px',
    margineTop: '6px',
  },
  topStack:{
    border: '1px solid',
    borderColor: isDark ? '#324D68' : '#DEE6ED',
    borderRadius: '6px',
    padding: '4px 0',
    justifyContent: 'space-between',
    marginBottom: '12px',
  },
  btnOne: {
    background: 'transparent',
    border: '1px solid',
    borderColor: isDark ? '#4CAFFF' : '#319EF6',
    boxSizing: 'border-box',
    boxShadow: '0px 1px 7px rgba(41, 45, 50, 0.08)',
    borderRadius: '6px',
    padding: '16px 0',
    color: isDark ? '#4CAFFF' : '#319EF6',
    width: '100%',
    whiteSpace: "nowrap",
    textTransform: 'capitalize',
    marginRight: '14px',
    '&:hover': {
      background: isDark ? '#319EF6' : '#4CAFFF',
      color: '#fff'
    }
  },
  btnTwo: {
    background: 'transparent',
    border: '1px solid',
    borderColor: isDark ? '#7599BD' : '#666666',
    boxSizing: 'border-box',
    boxShadow: '0px 1px 7px rgba(41, 45, 50, 0.08)',
    borderRadius: '6px',
    padding: '16px 0',
    color: isDark ? '#7599BD' : '#999999',
    width: '100%',
    textTransform: 'capitalize',
    whiteSpace: "nowrap",
    '&:hover': {
      background: isDark ? '#999999' : '#7599BD',
      color: '#fff'
    }
  },
  btnStack:{
    justifyContent: 'space-between',
    marginBottom: '18px',
  },
  heading: {
    fontWeight: 400,
    fontSize: "1.25rem",
    color: isDark ? '#F1F5F8' : '#333333',
    lineHeight: "1.75rem",
    marginBottom: "1rem",
  },
  selectBorder:{
    width: "100%",
    display: "flex",
    alignItems: "center",
    background: "transparent",
    cursor: "pointer",
    border: `1px solid ${colors.borderOutline}`,
    '&:hover': {
        border: `1px solid ${colors.textHighlight}`,
    },
    boxSizing: "border-box",
    borderRadius: ".375rem",
    height: "4rem",
    padding: "1rem",
    marginBottom: "1rem",
  },
  bottomText: {
    textAlign: 'center',
    color: isDark ? '#DCE6EF' : '#666666',
  }
}));

function WalletSwitchModal({
  displayWallet,
  setDisplayWallet,
  openWallet,
}: any) {
  const classes = useStyles();
  const themeType = localStorage.getItem(STORAGE_VARIABLE.appTheme) as keyof AppThemeValues;
  const isDark = themeType === APP_THEME_TYPES.dark;
  const walletReducer = useSelector((state : ReduxStoreState) => state.walletReducer);
  const { address, web3, networkId } = walletReducer;
  const [tooltipTitle, setTooltipTitle] = useState("Copy");
  const copyToClipBoard = async (text: any) => {
    try {
      await navigator.clipboard.writeText(text);
      setTooltipTitle("Copied");
      setTimeout(() => {setTooltipTitle("Copy")}, 3000);
    } catch (err) {
      console.log(err);
    }
  };

  const disconnect = ()=>{
    setDisplayWallet(false)
    walletUtil.disconnectUser()
  }
  return (
    <>
    <CustomDialog
      open={displayWallet}
      close={() => setDisplayWallet(false)}
    >
      <div>
        <div className={classes.modalHeader}>
          <div className={classes.heading}>Wallet</div>
        </div>
        <div className={classes.modalBody}>
          <Box
            width="90%"
            margin="0 auto"
            fontSize="14px"
          >
            <Stack
              direction="row"
              className={classes.topStack}
            >
              <Button
                style={{color: isDark ? '#F1F5F8' : '#333333'}}
              >
              <img src={MetamaskLogo}/>
                {walletUtil.shortAddress(String(address).toUpperCase())}
              </Button>
              <Tooltip title={tooltipTitle} style={{backgroundColor: "gray.300", color:"black"}}>
                <IconButton aria-label="Copy address">
                  <ContentCopyIcon style={{color: isDark ? '#DCE6EF' : '#666666'}} onClick={() =>  copyToClipBoard(address)}/>
                </IconButton>
              </Tooltip>
            </Stack>
            <Box style={{marginTop:"4px", fontSize:"16px", color: isDark ? '#666666' : '#DCE6EF'}}>
              <Link target="_blank" href={getExplorerLink(networkId as number, address, ExplorerDataType.ADDRESS)}>
                <Box display="flex">
                  <img style={{height:"24px", width:"24px", marginRight:"4px"}} src={isDark ? RouteDark : RouteLight} />
                  <div style={{color: isDark ? '#DCE5EF' : '#666666', marginBottom: '10px', marginTop: '4px'}}>
                    View on
                    {
                      networkId === 3 || networkId === 4 || networkId === 5 || networkId === 6
                        ? (" Etherscan")
                        : networkId === 56 || networkId === 97
                          ? (" Bscscan")
                          : networkId === 137 || networkId === 80001
                            ? (" Polygonscan")
                            : (" Explorer")
                    }
                  </div>
                </Box>
              </Link>
            </Box>
            <Box>
              <Stack
                direction="row"
                className={classes.btnStack}
              >
                <ButtonUnstyled className={classNames(classes.btnOne, classes.cursor)} onClick={openWallet as MouseEventHandler}>
                  Switch Wallet
                </ButtonUnstyled>
                <ButtonUnstyled className={classNames(classes.btnTwo, classes.cursor)} onClick={disconnect}>
                  Disconnect Wallet
                </ButtonUnstyled>
              </Stack>
            </Box>
            <Box className={classes.dashBox}>
              <div className={classes.bottomText}>Your recent transactions will appear here</div>
            </Box>
          </Box>
        </div>
      </div>
    </CustomDialog>
    </>
  );
}
export default WalletSwitchModal
