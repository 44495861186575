import { CircularProgress, makeStyles } from "@material-ui/core";
import { Close, DoneRounded } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { AppStyleProps, ReduxStoreState } from "../../utils";
import { CustomDialog } from "../CustomDialog";
import { CustomNotifierDispatch, CustomNotifierType, CustomNotifierTypeKeys } from "./types";
import classNames from 'classnames';
import { customNotifierActions } from ".";

const style = makeStyles(({colors} : AppStyleProps) => ({
    root : {
        display : "flex",
        flexDirection : "column",
        alignItems : "center",
        justifyContent : "center",
        minWidth : 250,
        maxWidth : 350
    },
    icon : {
        height : 90,
        width : 90,
    },
    bordersuccess : {
        border : `solid 5px ${colors.success}`,
    },
    bordererror : {
        border : `solid 5px ${colors.danger}`,
    },
    success : {
        color : colors.success,
        width : "90px !important",
        height : "90px !important"
    },
    error : {
        width : "90px !important",
        height : "90px !important",
        color : colors.danger,
    },
    processing : {
        width : "100px !important",
        height : "100px !important",
        border:"2px",
        color:"#319EF6"
    },
    container : {
        // border : `solid 5px ${colors.success}`,
        borderRadius : 50,
        display : "flex",
        width : "fit-content",
        margin : 20,
    },
    header : {
        fontSize : 20,
        margin : 20,
        color : colors.text,
    },
    subheader : {
        color : "#008DFF"
    },
    text: {
        color:colors.light,
        fontWeight:600,
        margin:"6px 0"
    },
    footer : {
        width : "100%",
    },
}))
type CustomNotifierBorderClass = { bordersuccess : string, bordererror : string }
const imageIcons = ({type, classes} : {
    type : CustomNotifierTypeKeys, 
    classes : CustomNotifierType
}) => {
    switch(type){
        case "error" : return <Close className={classNames(classes.default, classes.error)}/>;
        case "success" : return <DoneRounded className={classNames(classes.default, classes.success)}/>;
        case "processing" : return <CircularProgress className={classNames(classes.default, classes.processing)} thickness={1.3} />;
        default : return null;
    }
}
const CustomNotifierImage = (
    {type, classes} : 
    {
        type : CustomNotifierTypeKeys,
        classes : {
            container : string,
            icon : CustomNotifierType & CustomNotifierBorderClass,
        }
    }) : null | JSX.Element => {
    const borderClass = "border"+type as keyof CustomNotifierBorderClass;
    return <div className={classNames(classes.container, classes.icon[borderClass])}>
        {imageIcons({type, classes : classes.icon})}
    </div>
}

export const CustomNotifier = () => {
    const classes = style(),
    dispatch = useDispatch<CustomNotifierDispatch>(),
    {
        display, header,text, subheader, type, footer
    } = useSelector((state : ReduxStoreState)=>state.customNotifierReducer),
    closeDialog = () => {
        customNotifierActions.payload({
            display : false,
            header : undefined,
            text : undefined,
            subheader : undefined,
            footer : undefined,
        })(dispatch);
    };
    if(!display) return null;
    return <CustomDialog
        open={true}
        close={closeDialog}
    >
        <div className={classes.root}>
            <CustomNotifierImage 
                type={type} 
                classes={{icon : {
                    default : classes.icon,
                    success : classes.success,
                    error : classes.error,
                    processing : classes.processing,
                    bordererror : classes.bordererror,
                    bordersuccess : classes.bordersuccess
                }, container : classes.container}}/>
            <div className={classes.header}>{header}</div>
            <div className={classes.text}>{text}</div>
            <div className={classes.subheader}>{subheader}</div>
            <div className={classes.footer}>{footer}</div>
        </div>
    </CustomDialog>
}
export {CustomNotifier as default};