import { makeStyles } from "@material-ui/styles";
import { AppStyleProps, LaunchPadStyleProps } from "../../../utils";
import { Grid } from "@material-ui/core";
import LaunchPadInvestorItem from "./LaunchPadInvestorItem/LaunchPadInvestorItem";

const style = makeStyles((theme: LaunchPadStyleProps) => ({
  root: {
    padding: "5rem",
    marginBottom: "0rem",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      padding: "1rem",
    },
  },
  heading: {
    fontfamily: "Clash Display",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "48px",
    color: theme.colors.textDefault,
    marginBottom: "1rem",
    //[theme.breakpoints.down('xl')]: {
    //    fontSize: "48px"
    //},
    [theme.breakpoints.down("sm")]: {
      fontSize: "40px",
    },
  },
  subheading: {
    fontFamily: "DM Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    marginBottom: "5rem",
    fontSize: "18px",
    lineHeight: "28px",
    color: theme.colors.subHeadingDefault,
  },
  dataContainer: {
    display: "flex",
    justifyContent: "justify",
    //marginTop: "6rem",
    [theme.breakpoints.down(1690)]: {
      flexWrap: "wrap",
      //marginTop: "3rem"
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
}));

export default function LaunchPadInvestors() {
  const classes = style();

  const data = [
    {
      dataContent:
        "All projects carrying out fund raising would be fully vetted.",
      dataHead: "Fully Vetted",
      dataPicture: "INVEST1",
    },
    {
      dataContent:
        "After fundraising, the Rigelprotocol would ensure that funds raised are well utilized and managed.",
      dataHead: "Funds well managed",
      dataPicture: "INVEST2",
    },
    {
      dataContent:
        "Ensuring any projects which has carried out fund raising present valuable monthly reports on their product development and activities.",
      dataHead: "Monthly reports",
      dataPicture: "INVEST3",
    },
    {
      dataContent:
        "Early Rigelprotocol Launchpad Investors would enjoy some future features(voting on selection of projects to raise funds, share in voting fees of the Rigelprotocol Launchpad Protocol) which would be available on other versions of our Dapps.",
      dataHead: "Voting",
      dataPicture: "INVEST4",
    },
    {
      dataContent: "Bring innovative ideas Defi/Crypto to live",
      dataHead: "Innovative Ideas",
      dataPicture: "INVEST5",
    },
    {
      dataContent:
        "The Rigelprotocol Launchpad is backed by top Advisors and influential Platforms.",
      dataHead: "Top Advisors",
      dataPicture: "INVEST6",
    },
  ];

  return (
    <section className={classes.root}>
      <h2 className={classes.heading}>Investors’ Story</h2>
      <p className={classes.subheading}>
        Why should investors make investments through the Rigelprotocol
        launchpad?
      </p>
      <div className={classes.dataContainer}>
        <Grid container spacing={4}>
          {data &&
            data.map((item, index) => {
              const { dataHead, dataContent, dataPicture } = item;
              return (
                <Grid key={index} item xs={12} sm={6} md={4} lg={4} xl={4}>
                  <LaunchPadInvestorItem
                    key={index}
                    dataHead={dataHead}
                    dataContent={dataContent}
                    dataPicture={dataPicture}
                  />
                </Grid>
              );
            })}
        </Grid>
      </div>
    </section>
  );
}
