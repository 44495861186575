import { ThemeProvider, useTheme } from '@material-ui/core';
import { appTheme, APP_THEME_TYPES, routes, STORAGE_VARIABLE, utilAction, walletTypes, AppThemeValues } from './utils';
import Layout from './components/Layout';
import { useSelector, useDispatch } from 'react-redux';
import Web3Provider from './components/structures/Web3Provider/Web3Provider';
import { useEffect, useState } from 'react';
import { WalletTypes, ReduxStoreState } from './utils';
import $ from 'jquery';
export type AppState = {
    usedTheme : string | null,
    usedWallet : keyof WalletTypes,
    isDark: boolean
}
//did a correction for git
function BuyRGPAppV1() {
    const theme = useTheme(),
    [state, _setState] = useState<AppState>({} as AppState),
    { usedTheme, usedWallet, isDark} = state,
    setState = (_state : Partial<AppState>) => _setState(state=>({...state, ..._state})),
    dispatch = useDispatch(),
    refreshCount: number = useSelector((state: ReduxStoreState) => state.utilReducer.refreshCount),
    themeType = localStorage.getItem(STORAGE_VARIABLE.appTheme) as keyof AppThemeValues,
    selectedTheme = appTheme[themeType] || appTheme.dark;
    useEffect(() => {
        const usedTheme = localStorage.getItem(STORAGE_VARIABLE.appTheme),
        usedWallet = localStorage.getItem(STORAGE_VARIABLE.walletType) as keyof WalletTypes;
        setState({usedTheme, usedWallet});
        //set utils for local storage 
        utilAction.payload({
            storageNetworkID : STORAGE_VARIABLE.networkId,
            storageNetworkType : STORAGE_VARIABLE.networkType,
            storageNetworkWallet : STORAGE_VARIABLE.walletType,
            storageAppTheme : STORAGE_VARIABLE.appTheme,
            storageTokenContract : STORAGE_VARIABLE.swapContract
        })(dispatch);
        // eslint-disable-next-line
    }, [JSON.stringify(dispatch)]);
    useEffect(() => {
        $("html").css("background", selectedTheme.pageBackground)
        $("html").css("color", selectedTheme.text);
        
        const usedTheme = localStorage.getItem(STORAGE_VARIABLE.appTheme)
        const isDarkTheme = usedTheme ? usedTheme === APP_THEME_TYPES.dark : true;
        setState({isDark: isDarkTheme});
        // eslint-disable-next-line
    }, [JSON.stringify(selectedTheme), usedTheme, refreshCount]);

    return <ThemeProvider theme={{...theme, isDark, colors : selectedTheme}}>
        <Web3Provider
            initialWallet={usedWallet || walletTypes.metamask}
        >
            <Layout
                routes={routes}
            />
        </Web3Provider>
    </ThemeProvider>
}

export default BuyRGPAppV1;
export { BuyRGPAppV1 };
