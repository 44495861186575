import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar} from '@material-ui/core';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import WbSunnyOutlinedIcon from '@material-ui/icons/WbSunnyOutlined';
import Brightness2 from '@material-ui/icons/Brightness2';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import WalletSwitchModal from '../Connection/WalletSwitchModal/WalletSwitchModal';
import { WalletList } from '../WalletList';

import { CustomDialog } from "../../../../includes";
import DisConnected from "../Connection/DisConnected";
import classNames from 'classnames';
import {  AppStyleProps } from '../../../../utils';
import Menu from "../dropdown/Menu";
import SocialMedia from './../SocialMedia';

const useStyles = makeStyles(({colors, breakpoints} : AppStyleProps) => ({
    text: {
      color: colors.text,
      fontFamily: `Cera Pro`,
      fontSize: `18px`,
      fontStyle: `normal`,
      lineHeight: `24px`,
      letterSpacing: `0em`,
      textAlign: `center`,
    },

    bottomNav: {
      display: "none",
      [breakpoints.down("sm")]: {
        display: "flex",
        justifyContent: `space-between`,
        alignItems: `center`,
        background: colors.pageBackground,
        padding: `8px 12px 8px 12px`,
        borderTop: `1px solid ${colors.borderOutline}`
      },
    },
    mobileBox: {
      display: "none",
      [breakpoints.down("sm")] : {
        display : "flex",
        justifyContent: `center`,
        alignItems: `center`,
        padding: `8px`,
        marginLeft: "8px",
        borderRadius: `6px`,
        boxSizing: `border-box`,
        maxHeight: "40px",
        border: `1px solid ${colors.borderOutline}`,
      },
    },

    icon: {
      color: colors.text
    }

  }));

export function BottomNav(props: any) {
    const classes = useStyles(),
    {connected, bottomConnectedComp: Connected, el, close, items, open,openWallet,displayWallet,setDisplayWallet,onWalletSelect,showWalletDialog,closeDialog,openDialog} = props;

    return (
        <AppBar position="fixed" style={{top: "auto", bottom: 0}}>
                <BottomNavigation
                        className={classes.bottomNav}
                >
                  <WalletSwitchModal
                    displayWallet={displayWallet}
                    setDisplayWallet={setDisplayWallet}
                    openWallet={openWallet}
                  />
                    <CustomDialog
                      open={openDialog}
                      close={closeDialog}
                    >
                      <WalletList
                        onSelect={onWalletSelect}
                      />
                    </CustomDialog>

                  {connected ? (
                    <div className={classes.mobileBox}>
                        <Connected />

                    </div>
                  ) : (
                    <div>
                      <DisConnected showWalletDialog={showWalletDialog}/>{" "}

                    </div>
                  )}
            <div style={{display: "flex"}}>
              <div className={classNames(classes.mobileBox)}>
                  {props.isDark ? <WbSunnyOutlinedIcon onClick = {props.switchTheme} className = {classes.icon} /> : <Brightness2 className = {classes.icon} onClick = {props.switchTheme}/> }
              </div>
              <SocialMedia />
              <Menu anchorEl={el} handleClose={close} network={items} />
            </div>
            </BottomNavigation>
        </AppBar>
    );

}

export default BottomNav;
