import React from 'react';
import { history } from './helpers';
import { BuyRGPApp, LunchPadApp } from './Applications';
import { buyRGPRoutePrefix } from './Applications/BuyRGP/v1';
import { DappVersion } from './Applications/BuyRGP/common';
import ReactGA from "react-ga4";

export default function App(){

    ReactGA.initialize("G-JTWTMZ9QYH");
    history.listen(path => {
        ReactGA.send({ hitType: "page_view", page: path.location.pathname });
    });

    const { pathname } = history.location, 
    // eslint-disable-next-line
    match = new RegExp(`^\/(${buyRGPRoutePrefix}\$|${buyRGPRoutePrefix}\/)`);
    let appIndex = pathname.indexOf("/v")
    const appVersion = pathname.substring(++appIndex, appIndex + 2)
    if(match.test(pathname))
        return <BuyRGPApp dappVersion={appVersion as DappVersion}/>
    else return <LunchPadApp/>;
}
export { App };