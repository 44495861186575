import axios from 'axios';

export function GoogleSheetApi () {

    const defaultOptions = {
        baseURL: 'https://sheet.best/api/sheets/',
        withCredentials: true,
        headers: {
            "X-Api-Key": "<Rigel API Key coming soon>",
            "Content-Type": "application/json",
        },
    };

    return {
        getRows: (url: string, options = {}) => axios.get(url, { ...defaultOptions, ...options }),
        addRows: (url: string, data: object, options = {}) => axios.post(url, data, { ...defaultOptions, ...options }),
        updateRow: (url: string, data: object, options = {}) => axios.put(url, data, { ...defaultOptions, ...options }),
        removeRow: (url: string, options = {}) => axios.delete(url, { ...defaultOptions, ...options }),
    };
}
