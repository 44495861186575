import React from 'react';
import { AppRoutes } from '.';
import { buyRGPRouteNames } from "./routeNames"

const BuyToken = React.lazy(() => import("../components/BuyToken/BuyToken"));
const PurchasedToken = React.lazy(() => import("../components/PurchasedToken/PurchasedToken"));
const Stake = React.lazy(() => import("../components/Stake/Stake"));
const NFTs = React.lazy(() => import("../components/NFTs/NFTs"))
const NFT = React.lazy(() => import('../components/NFTs/NFT/ViewNFT'))
const MINTNFT = React.lazy(() => import('../components/NFTs/MintNft'))
const PROJECTHOME = React.lazy(() => import('../components/Projects/Projects'))
const INVESTMENTVIEW = React.lazy(() => import('../components/Projects/InvestedProjectView/InvestedProjectView'))
const MINEPAD = React.lazy(() => import("../components/MinePad/MinePadHomePage"))
const MINEINFORMATION = React.lazy(() => import("../components/MinePad/MineInformation"))
// const AddLiquidity = React.lazy(() => import("../components/AddLiquidity/AddLiquidity"));

export const routes: AppRoutes[] = [
    { path: buyRGPRouteNames().stake, exact: true, component: Stake },
    { path: buyRGPRouteNames().swap, exact: true, component: BuyToken },
    { path: buyRGPRouteNames().purchased, exact: true, component: PurchasedToken },
    { path: buyRGPRouteNames().nfts, exact: true, component: NFTs },
    { path: buyRGPRouteNames().nft, exact: true, component: NFT },
    { path: buyRGPRouteNames().mintNft, exact: true, component: MINTNFT },
    { path: buyRGPRouteNames().projects, exact: true, component: PROJECTHOME },
    { path: buyRGPRouteNames().investment, exact: true, component: INVESTMENTVIEW },
    { path: buyRGPRouteNames().minePad, exact: true, component: MINEPAD },
    // { path: buyRGPRouteNames().minePadReferral, exact: true, component: MINEPAD },
    { path: buyRGPRouteNames().minePadInformation, exact: true, component: MINEINFORMATION },
    // { path : buyRGPRouteNames().liquidity, exact : true, component : AddLiquidity},
]
/**
 * 404 page must be the last index in this array and it is without a path
 */
export const headerlessRoutes = [

]