import {makeStyles} from '@material-ui/styles';
import { AppStyleProps, Nullible, BuyRGPTypes} from '../../utils';

const style = makeStyles(({colors} : AppStyleProps) => ({
    root : {
        width : 20,
        height : 20,
        display: "inline-block",
        margin : "0px 5px",
        verticalAlign: "middle",
    },
}))
type InputProps = Nullible<Partial<{
    tokenSymbol : keyof BuyRGPTypes
}>>
export default function TokenIcon({tokenSymbol} : InputProps) {
    const classes = style();
    return (
        <img className={classes.root} src = {`/assets/token_icons/${String(tokenSymbol).replace("Token", "")}.png`} alt = "token icon" />
    );
}
export { TokenIcon }
