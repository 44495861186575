export type selectedProjectDataTypes = {
    projectID: string,
    sheetName: string
}
// const LaunchPadSelectedProject = "LaunchPadSelectedProject";
// export const storeSelectedProject = (data: selectedProjectDataTypes): void => localStorage.setItem(LaunchPadSelectedProject, JSON.stringify(data));
// export const getSelectedProject = (): selectedProjectDataTypes => {
//     const stringifiedData = localStorage.getItem(LaunchPadSelectedProject);
//     return JSON.parse(stringifiedData as string);
// }
// export const removeSelectedProject = (): void => localStorage.removeItem(LaunchPadSelectedProject);

export function ParseFloat(str : string | number, val : number) {
    const value = str?.toString();
    if(!value?.includes('.')) return value;
    return  value.slice(0, (value.indexOf(".")) + val + 1);
   }
 