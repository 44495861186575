import Web3 from "web3";
import { AppThemeValues, NetworkTypes, StorageVariables, WalletTypes, AllowedNetworks} from "../../../../utils/types";

export const DAPP_THEMES = {
    DARK : "dark", LIGHT : "light"
}
export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';
export const DATE_TIME = {
    SECONDS_IN_AN_HOUR : 3600
}
export const walletTypes : WalletTypes = {
    metamask : "metamask",
    binance : "binance",
    trustwallet:"metamask",
    walletConnect : "walletConnect",
    safePal : "metamask"
}
export const networkTypes : NetworkTypes = {
    binance : "binance",
    ethereum : "ethereum",
}
export const STORAGE_VARIABLE : StorageVariables = {
    networkId : "buyrgpNetworkId",
    networkType : "buyrgpNetworkType",
    walletType : "buyrgpNetworkWallet",
    appTheme : "buyrgpAppTheme",
    swapContract : "buyrgpSelectedContract",
}
export const PROCESS_DISPLAY_STAGES = {
    INCOMPLETE : 1,
    PROCESSING : 2,
    COMPLETE : 3,
}
export const APP_THEME_TYPES : AppThemeValues = {
    dark : "dark",
    light : "light",
}
export const NETWORKS : {[K in keyof AllowedNetworks] : any} = {
    bsc : {
        chainId: Web3.utils.toHex(56),
        chainName: 'Binance Smart Chain',
        nativeCurrency: {
            name: 'Binance',
            symbol: 'BNB',
            decimals: 18
        },
        rpcUrls: ["https://bsc-dataseed.binance.org/"],
        blockExplorerUrls: ['https://bscscan.com']
    },
    bsc_test : {
        chainId: Web3.utils.toHex(97),
        chainName: 'Binance Smart Chain Test Network',
        nativeCurrency: {
            name: 'Binance',
            symbol: 'BNB',
            decimals: 18
        },
        rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545'],
        blockExplorerUrls: ['https://testnet.bscscan.com']
    },
    polygon : {
        chainId: Web3.utils.toHex(137),
        chainName: 'Polygon Mainnet',
        nativeCurrency: {
            name: 'Matic',
            symbol: 'MATIC',
            decimals: 18
        },
        rpcUrls: ['https://polygon-rpc.com/'],
        blockExplorerUrls: ['https://polygonscan.com']
    },
    polygon_mumbai : {
        chainId: Web3.utils.toHex(80001),
        chainName: 'Polygon Mumbai',
        nativeCurrency: {
            name: 'Matic',
            symbol: 'MATIC',
            decimals: 18
        },
        rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],
        blockExplorerUrls: ['https://mumbai.polygonscan.com']
    },
}

export enum SupportedChainId {
  ROPSTEN = 3,
  RINKEBY = 4,
  GOERLI = 5,
  KOVAN = 6,
  POLYGON = 137,
  BINANCE = 56,
  BINANCETEST = 97,
  POLYGONTEST = 80001,
}
