import { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Divider, ButtonBase } from '@material-ui/core';
import { ErrorOutline, Settings } from '@material-ui/icons';
import { AppStyleProps, ReduxStoreState, tokenContractActions, tokenAmount, launchPadContract  } from '../../../utils'
import StakeInputModal from './StakeInputModal/StakeInputModal';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { StakeInputModalProps } from './StakeInputModal';
import rgpSpecialPool from '../../../utils/web3/rgpSpecialPool';
import { launchPadRouteNames } from '../../../../../LaunchPad/utils';
import { CustomDialog } from '../../../includes';

const style=makeStyles(({colors, isDark} : AppStyleProps) => ({
    root: {
        width: "100%",
        boxSizing: "border-box",
        marginTop: "1.5rem"
    },
    box: {
        border: `1px solid ${colors.outline}`,
        color: colors.text,
        borderRadius: ".4rem",
        boxSizing: "border-box"
    },
    headSection: {
         display: "flex",
         flexDirection: "column",
         justifyContent: "center",
         padding: "0rem 1rem 1rem 1rem"
    },
    boxHeading: {
        //margin: "0 0 .8rem ",
        marginBottom: "0.5rem",
        lineHeight: "1.5rem",
        fontSize: "1rem",
        //fontWeight: 500,
    },
    subHeading: {
        margin: 0,
        fontSize: "0.875rem",
        lineHeight: "1.375rem",
    },
    dividerStyles: {
        background: colors.primary,
    },
    stakebody: {
        padding: "1rem",
        display: "flex",
        flexDirection: "column"
    },
    stakedBalance: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "1rem",
    },
    key: {
        //fontWeight: 400,
        fontSize: "0.875rem",
        lineHeight: "1.375rem",
    },
    value: {
        fontSize: "0.875rem",
        lineHeight: "1.375rem",
    },
    info: {
        position: "relative",
        borderRadius: ".5rem",
        padding: ".8rem .8rem .8rem 3rem",
        boxSizing: "border-box",
        marginBottom: "1rem",
    },
    infoPositive: {
        backgroundColor: colors.primary,
        color: colors.text,
    },
    infoNegative: {
        backgroundColor: isDark ? colors.dangerBG : colors.yellow,
        color: isDark ? colors.danger : colors.yellow2,
    },
    infoIcon: {
        position: "absolute",
        top: "1rem",
        left: ".9rem",
    },
    infoText: {        
        margin: 0,
        fontSize: "0.875rem",
        lineHeight: "1.375rem",
    },
    proceedBtn: {
        width: "100%",
        padding: "1rem",
        fontSize: "1rem",
        lineHeight: "1.5rem",
        borderRadius: ".4rem",
        border: 0,
        boxSizing: "border-box",
        margin: ".8rem 0",
    },
    proceedBtnDisabled: {
        backgroundColor: isDark ? colors.disabledBG : colors.gray2,
        color: isDark ? colors.disabledText : colors.gray,
        cursor: "not-allowed"
    },
    proceedBtnEnabled: {
        backgroundColor: isDark ? colors.textHighlight : colors.blue,
        color: isDark ? colors.text : colors.white,
    },
    stakeBtn: {
        width: "100%",
        padding: "1rem",
        backgroundColor: "transparent",
        color: isDark ? colors.textHighlight : colors.blue,
        fontSize: "1rem",
        lineHeight: "1.5rem",
        borderRadius: ".4rem",
        border: `1px solid ${isDark ? colors.textHighlight : colors.blue}`,
        boxSizing: "border-box",
        margin: 0,
        cursor: "pointer",
    }
}))
type StakeBoxDialogTypes = {
    stakeInput : string
}
type StakeBoxProps=Partial<{
    hasStakedRGP : boolean | null,
    modalProps : Omit<StakeInputModalProps, "stakeRGP">,
    stakeRGP : (amount : number, onApproval: ()=>void, callback : ()=>void) => void
    changeStep: any
}>
type StakeBoxState=Partial<{
    openDialog : boolean,
    dialogType : keyof StakeBoxDialogTypes | null,
    balance : number,
    lockedFund : number,
    symbol : string,
    minStakeAmount : number,
    isWhiteList:boolean
}>
export default function StakeBox({ hasStakedRGP, changeStep, stakeRGP } : StakeBoxProps) {
    const classes=style(),
        [state, _setState]=useState<StakeBoxState>({
            openDialog : false,
            dialogType : null,
            balance : 0,
            lockedFund : 0,
            symbol : "",
            minStakeAmount : 0,
            isWhiteList: false
        }),
        {
            openDialog, dialogType, balance, 
            lockedFund, symbol, minStakeAmount
        } = state,
        setState=(_state : StakeBoxState) => _setState(state=>({...state, ..._state})),
        closeDialog = () => {
            setState({openDialog : false, dialogType : null})
            //this is just a work-around
            getBlockBalances();
        },
        showStakeSettings=() => {
            
        },
        { contracts, address } = useSelector((state : ReduxStoreState) => state.walletReducer),
        { rgp, specialPool } = contracts || {},
        getBlockBalances = useCallback(async() => {
            if(!address || !rgp) return;
            const [balance, symbol, userInfo, minStakeAmount /* isWhiteList */] = await Promise.all([
                tokenContractActions(rgp).myBalance(),
                tokenContractActions(rgp).symbol(),
                rgpSpecialPool().userInfo(),
                rgpSpecialPool().getMinimumStakeAmount(),
                // launchPadContract(selectedPad).isWhitelist()
            ]);
            setState({
                balance, symbol, 
                lockedFund : Number(
                    userInfo?._amountStaked ? 
                    rgpSpecialPool().reduceNumber(userInfo._amountStaked) : 0
                ),
                minStakeAmount, /* isWhiteList */})
        }, [address]);
    useEffect(() => {
        getBlockBalances();
    }, [address]);
    return (
        <div className={classes.root}>
            <div className={classes.box}>
                <div className={classes.headSection}>
                    <h3 className={classes.boxHeading}>RGP Staking</h3>
                    <span className={classes.subHeading}>RGP Staking Position</span>
                </div>
                <Divider className={classes.dividerStyles}/>
                <div className={classes.stakebody}>
                    <div className={classes.stakedBalance}>
                        <span className={classes.key}>RGP Staking Balance</span>
                        <span className={classes.value}>{tokenAmount(lockedFund as number)} {symbol || "RGP"}</span>
                    </div>
                    <div className={classNames(classes.info, hasStakedRGP ? classes.infoPositive : classes.infoNegative)}>
                        <ErrorOutline className={classes.infoIcon} />
                        {
                        hasStakedRGP ? 
                        <p className={classes.infoText}>
                            You have staked RGP so you can proceed to the Launchpad,
                            you can click on the <b>“Stake RGP”</b> button below to stake more RGP.
                        </p> :
                        <p className={classes.infoText}>
                            You need to have staked RGP for you to proceed to the Launchpad platform.
                            Click on the <b>“Stake RGP”</b> button below to stake some RGP.
                        </p>
                        }
                    </div>

                    <ButtonBase 
                        className={classNames(
                            classes.proceedBtn, 
                            hasStakedRGP ? classes.proceedBtnEnabled : classes.proceedBtnDisabled
                        )}
                        {...(hasStakedRGP ? {onClick: () => changeStep("whitelist")} : {})}
                    >
                            Proceed to Whitelist
                    </ButtonBase>

                    <ButtonBase 
                        className={classes.stakeBtn} 
                        onClick={()=> address && setState({
                            openDialog : true, 
                            dialogType : "stakeInput"
                        })}
                        disabled={!address}
                    >
                        {!address ? "Connect to Wallet" : "Stake RGP"}
                    </ButtonBase>

                    {dialogType && <CustomDialog 
                        open={openDialog} 
                        close={closeDialog}
                    >
                        {dialogType === "stakeInput" && <StakeInputModal 
                            stakeRGP={(amount, onApproval) => stakeRGP && stakeRGP(amount, onApproval, closeDialog)}
                            balance={balance} 
                            symbol={symbol}
                            minStakeAmount={minStakeAmount}
                        />}
                    </CustomDialog>}
                </div>
            </div>
        </div>
    )
}