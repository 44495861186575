import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppStyleProps } from '../../../../utils';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';

const useStyles = makeStyles(({colors, breakpoints} : AppStyleProps) => ({
  connectionBtn: {
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    padding: `8px`,
    maxHeight: "40px",
    background: colors.textHighlight,
    color: colors.text,
    cursor: "pointer",
    borderRadius: `6px`,
  },
}));

type DisConnectedProps = {
  connect(event: React.MouseEvent<HTMLElement>): void
}
type DisconnectState = Partial<{
  openDialog : boolean,
}>

export function DisConnected({showWalletDialog} : Partial<{showWalletDialog : any}>) {
  const classes = useStyles(),
  [state, _setState] = useState<DisconnectState>({ openDialog: false}),
  { openDialog } = state,
  setState = (_state : DisconnectState) => _setState(state=>({...state, ..._state}));

  return <div className={classes.connectionBtn} onClick={showWalletDialog}>
            <div> Connect Wallet </div>
            <AccountBalanceWalletOutlinedIcon style={{marginLeft: 8}}/>
      </div>

}

export default DisConnected
