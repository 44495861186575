import { makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { TokenIcon } from '../index';
import { getBuyRGPUtils, AppStyleProps, BuyRGPTypes, Nullible } from '../../Applications/BuyRGP/common';

const style = makeStyles(({colors, breakpoints} : AppStyleProps) => ({
    root : {
        
    },
    tokenName : {

    }
}))
export type CustomTokenBoxProps = Partial<{
    erc20 : any,
}>
export type CustomTokenBoxState = Nullible<Partial<{
    symbol : keyof BuyRGPTypes
}>>
export default function CustomTokenBox ({erc20} : CustomTokenBoxProps) {
    const { tokenContractActions } = getBuyRGPUtils("v1");
    const classes = style(),
        [state, _setState] = useState<CustomTokenBoxState>({
            symbol : null
        }),
        {symbol} = state,
        setState = (_state : CustomTokenBoxState) => _setState(state=>({...state, ..._state}));
    useEffect(() => {
        if(!erc20) return;
        (async () => {
            setState({symbol : await tokenContractActions(erc20).symbol() as keyof BuyRGPTypes});
        })()
    }, [erc20])
    return (
        <div className={classes.root}>
            <TokenIcon tokenSymbol={symbol}/>
            <span className={classes.tokenName}>{symbol ? String(symbol).toUpperCase() : ""}</span>
        </div>
    )
}
export { CustomTokenBox }